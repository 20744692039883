import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TdctAuthService } from '../home/tdct-auth/tdct-auth.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root',
})

export class AnalyticsGuard implements CanActivate {
  constructor(
    private authService: TdctAuthService,
    private router: Router,
    private afa: AngularFireAuth
  ) {}

  async canActivate(): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.afa.authState.subscribe(async (state) => {
        if (state) {
          const role = await this.authService.getUserRole();
          console.log('role', role);
          if (role === 'admin' || role === 'promoter') {
            resolve(true);
          } else {
            this.router.navigate(['/home']);
            resolve(false);
          }
        } else {
          this.router.navigate(['/home']);
          resolve(false);
        }
      });
    });
  }
}
