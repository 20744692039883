import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { ImpThemeService } from '../../imp-theme/imp-theme.service';
import { TdctAskService } from '../tdct-ask.service';


@Component({
  selector: 'app-tdct-ask-controller',
  templateUrl: './tdct-ask-controller.component.html',
  styleUrls: ['./tdct-ask-controller.component.scss'],
})
export class TdctAskControllerComponent implements OnInit {

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService,
    public theme: ImpThemeService,
    public ask: TdctAskService
  ) { }

  ngOnInit() { }

}
