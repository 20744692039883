import { Injectable } from '@angular/core';
import { Table } from './imp-table.model';
import { DefaultTable, MockTables } from './imp-table.mocks';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import * as moment from 'moment';
import { AngularFirestoreDocument, AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { first } from 'rxjs/operators';
import { ImpOptionService } from '../imp-option/imp-option.service';
import { ImpEventService } from '../imp-event/imp-event.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class ImpTableService {

  $: Table = DefaultTable;
  s$: Table[] = MockTables;
  all$: Table[] = [];
  sHidden$: Table[] = [];
  search: string = '';
  count: number = 0;
  total: number = 0;
  viewing: boolean = false;
  loading: boolean = false;
  noneFound: boolean = false;
  editing: boolean = false;
  managing: boolean = false;
  sharing: boolean = false;
  doc: AngularFirestoreDocument<Table>;
  col: AngularFirestoreCollection<Table>;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public option: ImpOptionService,
    public event: ImpEventService,
    public afs: AngularFirestore
  ) { }

  async get(id: string): Promise<Table> {
    const tablePath = 'tables/' + id;
    this.doc = this.afs.doc<Table>(tablePath);
    return await this.doc.valueChanges().pipe(first()).toPromise();
  }

  set(table: Table): void {
    this.$ = table;
    this.viewing = true;
    this.$ = this.auth.momentCreatedExactly(this.$);
    this.$ = this.auth.momentLastUpdatedFromNow(this.$);
  }

  async load(): Promise<Table[]> {
    this.loading = true;
    this.count = 0;
    this.total = 0;
    this.s$ = [];
    this.all$ = [];
    this.loading = true;
    await this.filter();
    this.s$ = await this.col.valueChanges().pipe(first()).toPromise();
    this.s$ = this.s$.map(table => this.auth.momentCreatedFromNow(table));
    this.s$ = this.s$.map(table => this.auth.momentLastUpdatedFromNow(table));
    this.set(this.s$[0]);
    this.slowCount();
    this.loading = false;
    return this.s$;
  }

  async filter() {
    if (this.auth.viewingUserTables) {
      this.col = await this.afs.collection<Table>('tables', ref => ref
        .where('userId', '==', this.user.$.id)
        .orderBy('unixLastUpdateTimestamp', 'desc'));
    } else {
      this.col = await this.afs.collection<Table>('tables', ref => ref.orderBy('unixLastUpdateTimestamp', 'desc'));
    }
  }

  slowCount() {
    this.all$ = this.s$;
    this.total = this.s$.length;
    if (this.s$.length === 1) {
      this.count = 1;
    } else {
      this.s$ = this.all$.splice(0, 3);
      const interval = setInterval(() => {
        this.count++;
        if (this.count >= this.total) {
          clearInterval(interval);
          this.count = this.total;
        }
      }, 100);
    }
  }

  async getTablesByUserId(userId: string): Promise<Table[]> {
    this.loading = true;
    this.noneFound = false;
    this.s$ = [];

    const userTablesCollection = this.afs.collection<Table>('tables', ref =>
      ref.where('userId', '==', userId)
        .orderBy('unixLastUpdateTimestamp', 'desc')
    );

    this.s$ = await userTablesCollection.valueChanges().pipe(first()).toPromise();

    if (this.s$.length === 0) {
      this.noneFound = true;

      this.loading = false;
      return this.s$;
  }
}

  showMore() {
    let loadinTables: Table[] = [];
    if (this.all$.length === 1) {
      loadinTables = this.all$.splice(0, 1);
    } else if (this.all$.length === 2) {
      loadinTables = this.all$.splice(0, 2);
    } else { loadinTables = this.all$.splice(0, 3); }
    this.s$.push(...loadinTables);
  }

  sanitize(): void {
    this.s$ = [];
  }

  clear(): void {
    this.editing = false;
  }

  toggleView(): void {
    this.clear();
    this.viewing = !this.viewing;
    if (this.viewing) {
      this.loading = true;
      this.set(this.$);
      this.loading = false;
    } else {
      this.loading = false;
    }
  }

  toggleEdit(): void {
    this.editing = !this.editing;
  }


  async create(source: any) {
    this.$.id = this.afs.createId();
    this.$.optionId = this.option.$.id;
    this.$.stripeCardToken = source.id;
    this.$.name = `${this.option.$.name}`;
    this.$.description = `1 Table at ${this.event.$.name}`;
    this.$.image = this.option.$.image;
    this.$.link = `https://imp-events.com/table/${this.$.id}`;
    this.$.route = `table/${this.$.id}`;
    this.$.price = this.option.$.price;
    this.$.temporary = true;
    // decrement the number of tables available locally
    if (this.option.$.tablesAvailable !== undefined) {
      this.option.$.tablesAvailable -= 1;
    }
    this.stampUser();
    this.stampPromoter();
    this.stampTime();
    this.stampEventOption();
    await this.createTableDoc();
  }

  async createFromCheckout(additionalBottleCount: number) {
    console.log('[imp-table.service].createfromCheckout session: ');
    this.$.id = this.afs.createId();
    this.$.price = this.option.$.price;
    this.$.name = `${this.option.$.name}`;
    this.$.optionId = this.option.$.id;
    this.$.description = `1 Table at ${this.event.$.name}`;
    this.$.image = this.option.$.image;
    this.$.bottlesIncluded = this.option.$.bottlesIncluded;
    this.$.bottlesPurchased = additionalBottleCount;
    // decrement tables from local event table option variable
    if (this.option.$.tablesAvailable !== undefined) {
      this.option.$.tablesAvailable -= 1;
    }
    this.$.link = `https://imp-events.com/table/${this.$.id}`;
    this.$.route = `table/${this.$.id}`;
    this.stampUser();
    this.stampPromoter();
    this.stampTime();
    this.stampEventOption();
    console.log('creating table');
    console.log('table', JSON.stringify(this.$));
    return JSON.stringify(this.$);
  }

  async createTableDoc() {
    return await this.afs.doc(`tables/${this.$.id}`).set(this.$);
  }

  stampTime() {
    this.$.displayTimestamp = moment().format('YYYY-MM-DD');
    this.$.displayLastUpdateTimestamp = this.$.displayTimestamp;
    this.$.unixTimestamp = moment().unix();
    this.$.unixLastUpdateTimestamp = this.$.unixTimestamp;
  }

  stampUser() {
    this.$.userId = this.user.$.id;
    this.$.userEmail = this.user.$.email;
    this.$.userName = this.user.$.name;
    this.$.userPhoto = this.user.$.photo;
  }

  stampPromoter() {
    this.$.promoterId = this.option.$.promoterId;
    this.$.promoterEmail = this.option.$.promoterEmail;
    this.$.promoterName = this.option.$.promoterName;
    this.$.promoterPhoto = this.option.$.promoterPhoto;
    this.$.promoterBio = this.option.$.promoterBio;
  }

  stampEventOption() {
    this.$.eventId = this.event.$.id;
    this.$.eventName = this.event.$.name;
    this.$.eventImage = this.event.$.image;
    this.$.eventDescription = this.event.$.description;
    this.$.displayStartTimestamp = this.event.$.displayStartTimestamp;
    this.$.displayEndTimestamp = this.event.$.displayEndTimestamp;
    this.$.unixStartTimestamp = this.event.$.unixStartTimestamp;
    this.$.unixEndTimestamp = this.event.$.unixEndTimestamp;
    this.$.street = this.event.$.street;
    this.$.city = this.event.$.city;
    this.$.state = this.event.$.state;
    this.$.zipcode = this.event.$.zipcode;
    this.$.country = this.event.$.country;
  }
}
