import { Device } from './tdct-device.model';
export const BrowserDevice: Device = {
    id: '1',
    name: 'Browser Device',
    description: 'Chrome Web Browser',
    token: '1',
    serial: '1',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    platforms: ['browser'],
    tags: ['subscribed']
};
export const AppleDevice: Device = {
    id: '2',
    name: 'Apple Device',
    description: 'Apple iPhone',
    token: '2',
    serial: '2',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    platforms: ['apple'],
    tags: ['susbcribed']
};
export const GoogleDevice: Device = {
    id: '3',
    name: 'Google Device',
    description: 'Google Android Nexus',
    token: '3',
    serial: '3',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    platforms: ['google'],
    tags: ['subscribed']
};
export const MockDevices: Device[] = [BrowserDevice, AppleDevice, GoogleDevice];
export const DefaultDevice: Device = {
    id: '',
    name: '',
    description: '',
    token: '',
    serial: '',
    userId: '',
    userName: '',
    userEmail: '',
    userPhoto: '',
    displayTimestamp: '',
    displayLastUpdateTimestamp: '',
    unixLastUpdateTimestamp: 0,
    unixTimestamp: 0,
    platforms: [],
    tags: []
};
