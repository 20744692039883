import { Component, OnInit, ViewChild, ElementRef,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpEventService } from '../imp-event.service';
import { ImpTicketService } from '../../imp-ticket/imp-ticket.service';
import { ImpOptionService } from '../../imp-option/imp-option.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { DefaultTicket } from '../../imp-ticket/imp-ticket.mocks';
import { ImpTableService } from '../../imp-table/imp-table.service';
import { DefaultTable } from '../../imp-table/imp-table.mocks';
import { ImpTableOptionBottleService } from '../../imp-table/imp-table-option-bottle/imp-table-option-bottle.service';
import { Option } from '../../imp-option/imp-option.model';
import { Bottle } from '../../imp-bottle/imp-bottle.model';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { ImpStripeCheckoutComponent } from '../../imp-stripe-checkout/imp-stripe-checkout.component';

const STRIPE_PUBLISHABLE_KEY: string = environment.stripe.stripePublishableKey;


@Component({
  selector: 'app-imp-event-stripe-elements-form',
  templateUrl: './imp-event-stripe-elements-form.component.html',
  styleUrls: ['./imp-event-stripe-elements-form.component.scss'],
})
export class ImpEventStripeElementsFormComponent implements OnInit {

  @ViewChild('cardElement', { static: true }) cardElement: ElementRef;
  @ViewChild('stripecheckout', { static: true }) stripeCheckout: ElementRef;

  stripe;
  card;
  cardErrors;

  confirmation;
  totalTicketPrice = 0;
  totalTablePrice = 0;
  processingFee = 0.05;
  serviceFee = 0.0360;
  statusQuoFee = 1.20;     // $1.20

  combinedFee = 0;
  currentCharges = 0;
  additionalBottleCount = 0;

  checkoutSurvey: string;

  constructor(
      public auth: TdctAuthService,
      public user: TdctUserService,
      public promoter: TdctUserPromoterService,
      public event: ImpEventService,
      public ticket: ImpTicketService,
      public table: ImpTableService,
      public option: ImpOptionService,
      public tableOptionBottle: ImpTableOptionBottleService,
      public admin: TdctAdminService,
      public afs: AngularFirestore,
      public aff: AngularFireFunctions,
      public router: Router,
  ) { }

  ngOnInit() {
    console.log('Stripe Form Initialized');
    // TODO:  determine if stripe should even be called/stripe input should be required if ticket/table price is 0
    // this.stripe = Stripe(STRIPE_PUBLISHABLE_KEY);
    // const elements = this.stripe.elements();

    // this.card = elements.create('card');
    // this.card.mount(this.cardElement.nativeElement);

    // this.card.addEventListener('change', ({ error }) => {
    //   this.cardErrors = error && error.message;
    // });
    if (this.auth.viewingEventTicketOptions) {
      this.ticket.$ = DefaultTicket;
      this.ticket.$.amountPurchased = 1;
      this.ticket.$.amountPaid = 0;
      console.log('option: ', this.option.$.price);
      this.updateTicketPrice();

    }
    if (this.auth.viewingEventTableOptions) {
      this.table.$.bottlesIncluded = this.option.$.bottlesIncluded;
      this.table.$ = DefaultTable;
      this.table.$.amountPaid = 0;
      this.tableOptionBottle.$.bottlesChosen = 0;
      this.updateTablePrice();
    }
  }

  incrementBottleChoice(bottleOption: Option) {
    // if the table includes bottles, those bottles are free and aren't charged
    ++bottleOption.bottlesChosen;
    // table price only starts getting updated when bottlesIncluded falls at or below 0
    if (this.option.$.bottlesIncluded < bottleOption.bottlesChosen) {
      ++this.additionalBottleCount;
      this.updateTablePrice();
    }
    // else if (this.table.$.bottlesIncluded <= 0 && this.option.$.bottlesIncluded < bottleOption.bottlesChosen){
    //   ++this.additionalBottleCount;
    //   // this.option.$.price = this.option.$.price + bottleOption.price;
    //   this.updateTablePrice();
    // }
    else { --this.table.$.bottlesIncluded; }
  }

  decrementBottleChoice(bottleOption: Option) {
    --bottleOption.bottlesChosen;
    if (this.option.$.bottlesIncluded < bottleOption.bottlesChosen) {
      --this.additionalBottleCount;
      this.updateTablePrice();
    } else if (this.option.$.bottlesIncluded >= bottleOption.bottlesChosen){
      this.updateTablePrice();
    } else { ++this.table.$.bottlesIncluded; }
  }

  updateTablePrice(): void {
    console.log('totalTablePrice: ' + this.totalTablePrice);
    console.log('option.$.price: ' + this.option.$.price);
    if (this.tableOptionBottle.$.bottlesChosen > this.option.$.bottlesIncluded){
      this.totalTablePrice = this.option.$.price +  ((this.tableOptionBottle.$.bottlesChosen - this.option.$.bottlesIncluded) * this.tableOptionBottle.$.price);
    }else {
      this.totalTablePrice = this.option.$.price;
    }
    console.log('new totalTablePrice: ' + this.totalTablePrice);

    this.calculateCombinedFee(this.totalTablePrice);
    this.table.$.amountPaid = this.totalTablePrice + this.combinedFee;
  }

  // V1.9.2
    calculateCombinedFee(totalPrice: number): void {
        const processingFeeAmount = totalPrice * this.processingFee;
        this.combinedFee = Number((processingFeeAmount + this.serviceFee + this.statusQuoFee).toFixed(2));
    }


  incrementTicketAmount(): void {
    ++this.ticket.$.amountPurchased;
    this.updateTicketPrice();
  }

  decrementTicketAmount(): void {
    --this.ticket.$.amountPurchased;
    this.updateTicketPrice();
  }

  updateTicketPrice(): void {
    this.totalTicketPrice = (this.option.$.price === 0) ? 0 : (this.option.$.price * this.ticket.$.amountPurchased);
    this.calculateCombinedFee(this.totalTicketPrice);
    this.ticket.$.amountPaid = this.totalTicketPrice  + this.combinedFee;
  }


  async handleForm(e) {
    e.preventDefault();

    const { source, error } = await this.stripe.createSource(this.card);

    if (error) {
      console.error(error);
      const cardErrors = error.message;
      if (error.code === 'card_declined') {
        const displayError = {
          code: 'Card Declined',
          message: error.message
        };
        this.auth.presentErrorAlert(displayError);
      }
    } else {
      this.auth.loading = true;
      if (this.auth.viewingEventTicketOptions) {
        ++this.user.$.ticketCount;
        if (this.checkoutSurvey !== undefined) {this.ticket.$.survey = this.checkoutSurvey; }
        await this.ticket.create(source);
        await this.logTicketTableEvent(this.ticket.$, 'ticket');
        if (!this.auth.userCustomer) { await this.promoterVisitorToCustomer(); }
      }
      if (this.auth.viewingEventTableOptions) {
        ++this.user.$.tableCount;
        if (this.checkoutSurvey !== undefined) {this.table.$.survey = this.checkoutSurvey; }
        this.table.$.bottlesPurchased = this.option.$.bottlesIncluded + this.additionalBottleCount;
        if (!this.auth.userCustomer) { await this.promoterVisitorToCustomer(); }
        await this.table.create(source);
        await this.createTableBottles();
        await this.logTicketTableEvent(this.table.$, 'table');
      }
      this.auth.viewingEventStripeElementsForm = false;
      this.router.navigateByUrl(this.ticket.$.route);
      this.auth.loading = false;
    }
  }

  async handleCheckout() {
      this.auth.loading = true;
      if (this.auth.viewingEventTicketOptions) {
        ++this.user.$.ticketCount;
        if (this.checkoutSurvey !== undefined) {this.ticket.$.survey = this.checkoutSurvey; }
        await this.ticket.createFromCheckout();
        // await this.logTicketTableEvent(this.ticket.$, 'ticket');
        if (!this.auth.userCustomer) { await this.promoterVisitorToCustomer(); }
      }
      // if (this.auth.viewingEventTableOptions) {
      //   ++this.user.$.tableCount;
      //   if (this.checkoutSurvey !== undefined) {this.table.$.survey = this.checkoutSurvey; }
      //   this.table.$.bottlesPurchased = this.option.$.bottlesIncluded + this.additionalBottleCount;
      //   if (!this.auth.userCustomer) { await this.promoterVisitorToCustomer(); }
      //   await this.table.create(source);
      //   await this.createTableBottles();
      //   await this.logTicketTableEvent(this.table.$, 'table');
      // }
      this.auth.loading = false;

  }
  async logTicketTableEvent(eventData: any, eventType: string) {
    console.log('Amplitude Test');
    console.log('eventData', eventData);
    const startTime = performance.now();
    let eventResult = {};
    if (eventType === 'ticket') {
      console.log('event type is ticket');
      eventResult = {
        ...{eventId: `${eventData.eventId}`},
        ...{eventType: `${eventType}`},
        ...{userId: `${eventData.userId}`},
        ...{amountPaid: `${eventData.amountPaid}`},
        ...{city: `${eventData.city}`},
        ...{purchaserZip: ['', undefined].includes(eventData.zipcode) ? undefined : `${eventData.zipcode.toString().padStart(5, '0')}`},
        ...{ticketId: `${eventData.id}`},
        ...{ticketName: `${eventData.name}`},
        ...{ticketOption: `${eventData.optionId}`},
        ...{ticketPrice: `${eventData.price}`},
        ...{ticketsPurchased: `${eventData.amountPurchased}`},
        ...{door: `${eventData.door}`},
        ...{gender: `${eventData.gender}`},
        ...{birthDate: `${eventData.birthDate}`},
      };
    } else if (eventType === 'table') {
      console.log('event type is table');
      eventResult = {
        ...{eventId: `${eventData.eventId}`},
        ...{userId: `${eventData.userId}`},
        ...{eventType: `${eventType}`},
        ...{amountPaid: `${eventData.amountPaid}`},
        ...{city: `${eventData.city}`},
        ...{purchaserZip: ['', undefined].includes(eventData.zipcode) ? undefined : `${eventData.zipcode.toString().padStart(5, '0')}`},
        ...{tableId: `${eventData.id}`},
        ...{tableName: `${eventData.name}`},
        ...{tableOption: `${eventData.optionId}`},
        ...{tablePrice: `${eventData.price}`},
        ...{bottlesPurchased: `${eventData.bottlesPurchased}`}
      };
    }
    // const eventResult = {
    //   ...eventData,
    //   ...{eventType: `${eventType}`}
    // };
    console.log('eventData', eventResult);
    const remoteFunction: any = this.aff.httpsCallable('logToAmplitude');
    const result: any = await remoteFunction(eventResult).toPromise();
    const endTime = performance.now();
    const runTime: number = endTime - startTime;
    console.log(`Result: '${JSON.stringify(result)}' produced in ${runTime}ms`);
  }

  async promoterVisitorToCustomer() {
    this.auth.userCustomer = true;
    this.user.$.roles.push('customer');
    this.afs.doc(`users/${this.user.$.id}`).update(this.user.$);
  }

  async createTableBottles(): Promise<Bottle[]> {
    const tableBottles: Bottle[] = this.tableOptionBottle.s$.map((tableOptionBottle) => this.stampBottle(tableOptionBottle));
    const tableBottleCreationPromises = [];
    tableBottles.map((tableBottle) => { tableBottleCreationPromises.push(this.createBottle(tableBottle)); });
    return Promise.all(tableBottleCreationPromises);
  }

  stampBottle(bottleOption: Option): Bottle {
    const bottle: Bottle = {
      id: '',
      link: '',
      route: '',
      amountPurchased: bottleOption.bottlesChosen,
      name: bottleOption.name,
      image: bottleOption.image,
      description: bottleOption.description,
      userId: this.user.$.id,
      userName: this.user.$.name,
      userEmail: this.user.$.email,
      userPhoto: this.user.$.photo,
      promoterId: this.table.$.promoterId,
      promoterEmail: this.table.$.promoterEmail,
      promoterName: this.table.$.promoterName,
      promoterPhoto: this.table.$.promoterPhoto,
      promoterBio: this.table.$.promoterBio,
      eventId: this.table.$.eventId,
      eventName: this.table.$.eventName,
      eventDescription: this.table.$.eventDescription,
      eventImage: this.table.$.eventImage,
      tableId: this.table.$.id,
      tableName: this.table.$.name,
      tableImage: this.table.$.image,
      tableDescription: this.table.$.description,
      displayTimestamp: moment().format('YYYY-MM-DD'),
      displayLastUpdateTimestamp: moment().format('YYYY-MM-DD'),
      unixTimestamp: moment().unix(),
      unixLastUpdateTimestamp: moment().unix(),
      price: bottleOption.price,
      tags: []
    };
    return bottle;
  }

  async createBottle(bottle: Bottle) {
    if (bottle.amountPurchased > 0) {
      bottle.id = await this.afs.createId();
      bottle.route = `bottle/${bottle.id}`;
      bottle.link = `https://imp-events.com/bottle/${bottle.route}`;
      await this.afs.doc(`bottles/${bottle.id}`).set(bottle);
      return bottle;
    }
  }

  updateCheckoutSurvey(surveyResult?) {
    if (surveyResult) {
      console.log(surveyResult);
      this.checkoutSurvey = surveyResult.value;
      console.log(this.checkoutSurvey);
    }
  }
}
