import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { ImpThemeService } from '../../imp-theme/imp-theme.service';
import { ImpEventService } from '../imp-event.service';
import { ImpEventDetailComponent } from '../imp-event-detail/imp-event-detail.component';
import { ActivatedRoute } from '@angular/router';
import { City } from '../../imp-city/imp-city.model';
import { DefaultCity } from '../../imp-city/imp-city.mocks';
import { ImpCityService } from '../../imp-city/imp-city.service';
import moment from 'moment';

@Component({
    selector: 'app-imp-event-controller',
    templateUrl: './imp-event-controller.component.html',
    styleUrls: ['./imp-event-controller.component.scss'],
})
export class ImpEventControllerComponent implements OnInit {
    searchTerm: string = '';
    filteredEvents: any[] = [];
    name: string | null = null;
    @Input() city$: City = DefaultCity;
    cityName: string | null = null;
    state: string | null = null;

    private cityCache: { [cityId: string]: City } = {}; // Cache to avoid redundant city API calls

    constructor(
        public auth: TdctAuthService,
        public user: TdctUserService,
        public admin: TdctAdminService,
        public theme: ImpThemeService,
        public event: ImpEventService,
        public modalController: ModalController,
        private route: ActivatedRoute,
        public city: ImpCityService,
        private cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.route.queryParams.subscribe(async (params) => {
            console.log('Route Query Params:', params);
            
            const cityName = params.cityName || null;
            const cityId = params.cityId || null;
            console.log('City Name from route:', cityName);
            console.log('City ID from route:', cityId);
            
            // Log current timestamp for debugging
            const currentTime = moment();
            const currentUnixTime = currentTime.unix();
            console.log('Current Time:', currentTime.format());
            console.log('Current Unix Timestamp:', currentUnixTime);
            
            if (cityId) {
                // If city ID is directly provided, use it
                this.filteredEvents = await this.loadEventsByCityId(cityId);
                console.log('Events loaded by city ID:', this.filteredEvents.length);
                
                // Log event timestamps for debugging
                this.filteredEvents.forEach(event => {
                    console.log('Event Details:', {
                        id: event.id,
                        name: event.name,
                        onlineEndTimestamp: event.onlineEndTimestamp,
                        isInFuture: moment.unix(event.onlineEndTimestamp).isAfter(currentTime)
                    });
                });
                
                await this.getCityName();
            } else if (cityName) {
                try {
                    // Directly get the city ID by name
                    const city = await this.city.getCityByName(cityName);
                    
                    if (city) {
                        console.log('City found:', city);
                        this.filteredEvents = await this.loadEventsByCityId(city.id);
                        console.log('Events loaded by city ID:', this.filteredEvents.length);
                        
                        // Log event timestamps for debugging
                        this.filteredEvents.forEach(event => {
                            console.log('Event Details:', {
                                id: event.id,
                                name: event.name,
                                onlineEndTimestamp: event.onlineEndTimestamp,
                                isInFuture: moment.unix(event.onlineEndTimestamp).isAfter(currentTime)
                            });
                        });
                        
                        await this.getCityName();
                    } else {
                        this.filteredEvents = await this.loadAllEvents();
                        console.log('No city found, loaded all events:', this.filteredEvents.length);
                        
                        await this.getCityName();
                    }
                } catch (error) {
                    console.error('Error fetching city data:', error);
                    this.filteredEvents = await this.loadAllEvents();
                }
            } else {
                this.filteredEvents = await this.loadAllEvents();
                console.log('No city name or ID provided, loaded all events:', this.filteredEvents.length);
                
                await this.getCityName();
            }
        });
    }

    // Retrieve the city ID based on the city name
    async getCityIdByName(cityName: string): Promise<string | null> {
        try {
            const city = await this.city.getCityByName(cityName);
            return city ? city.id : null;
        } catch (error) {
            console.error('Error fetching city by name:', error);
            return null;
        }
    }

    // Load events by city ID without redundant filtering
    async loadEventsByCityId(cityId: string): Promise<any[]> {
        try {
            console.log('Loading events for city ID:', cityId);
            
            this.event.showOldEvents = false; // Only load upcoming events
            await this.event.loadCity(cityId); // Load events filtered by city ID

            console.log('Events in event.s$ after loadCity:', this.event.s$);
            console.log('Events after loadCity:', this.event.s$.length);
            console.log('Events details:', JSON.stringify(this.event.s$, null, 2));

            // Use the events loaded by loadCity method directly
            const matchingEvents = this.event.s$;
            console.log('Events matching city ID:', matchingEvents.length);
            console.log('Matching Events Details:', JSON.stringify(matchingEvents, null, 2));

            // If no events found, log additional context
            if (matchingEvents.length === 0) {
                console.warn('No events found for city ID:', cityId);
            }
            
            return matchingEvents; // Return events matching the city ID
        } catch (error) {
            console.error('Error loading events by city ID:', error);
            return [];
        }
    }

    // Load all events with proper filtering
    async loadAllEvents(): Promise<any[]> {
        try {
            this.event.showOldEvents = false; // Ensure only upcoming events are shown
            await this.event.loadCity(); // Load all events
            return this.event.s$;
        } catch (error) {
            console.error('Error loading all events:', error);
            return [];
        }
    }

    // Fetch city names for the events and cache them
    async getCityName() {
        if (this.filteredEvents.length > 0) {
            for (const event of this.filteredEvents) {
                if (event.city && !this.cityCache[event.city]) {
                    try {
                        const city = await this.city.get(event.city);
                        if (city) {
                            event.cityName = city.name;
                            event.state = city.state;
                            this.cityCache[event.city] = city; // Cache the city details
                        }
                    } catch (error) {
                        console.error('Error fetching city name:', error);
                    }
                } else if (event.city && this.cityCache[event.city]) {
                    // Use cached city data if available
                    event.cityName = this.cityCache[event.city].name;
                    event.state = this.cityCache[event.city].state;
                }
            }
        } else {
            console.warn('No events found to get city names for.');
        }
    }

    // Filter events based on user input
    async filterEvents(event: any) {
        const searchTerm = event.target.value.toLowerCase();
        if (searchTerm && searchTerm.trim() !== '') {
            this.filteredEvents = this.event.s$.filter(evt => {
                return (
                    evt.name.toLowerCase().includes(searchTerm) ||
                    evt.description.toLowerCase().includes(searchTerm)
                );
            });
        } else {
            // If no search term, reload events for the current city
            if (this.name) {
                const city = await this.city.getCityByName(this.name);
                if (city) {
                    this.filteredEvents = await this.loadEventsByCityId(city.id);
                } else {
                    this.filteredEvents = await this.loadAllEvents();
                }
            } else {
                this.filteredEvents = await this.loadAllEvents();
            }
        }
    }

    // Open event detail modal
    async openEventDetailsModal(event$) {
        // Set the event in the service first
        this.event.set(event$);
        
        const modal = await this.modalController.create({
            component: ImpEventDetailComponent,
            componentProps: { event$ },
            cssClass: 'full-width-modal' // Add CSS class for full width
        });
        return await modal.present();
    }

    // Open demo link
    openEventDemoLink() {
        window.open('https://imp-app.com#demo', '_blank');
    }

    // Format date for display
    formatDate(date: string): string {
        const inputFormat = 'dddd, MMMM D, YYYY [at] h:mm A';
        const parsedDate = moment(date, inputFormat);
        return parsedDate.format('MMM D, YYYY, h:mm a');
    }
}
