import { Theme } from './imp-theme.model';
export const LightTheme: Theme = {
    primary: '#b20000',
    secondary: '#ec891d',
    tertiary: '#00ffff',
    light: '#ffffff',
    medium: '#989aa2',
    dark: '#222428',
    success: '#10dc60',
    warning: '#ffce00',
    danger: '#f04141',
    default: false,
    segmentBackgroundColor: '#ffffff',
    contentBg: '#f5f5f5',
    contentColor: '#b20000',
    textColor: '#000000'
};
export const DarkTheme: Theme = {
    primary: '#b20000',
    secondary: '#ec891d',
    tertiary: '#00ffff',
    light: '#000000',
    medium: '#989aa2',
    dark: '#e8e9e9',
    success: '#10dc60',
    warning: '#ffce00',
    danger: '#f04141',
    default: true,
    segmentBackgroundColor: '#222428',
    contentBg: '#000000',
    contentColor: '#ffffff',
    textColor: '#ffffff'
};
export const MockThemes: Theme[] = [LightTheme, DarkTheme];
