import { Injectable } from '@angular/core';
import { Task } from './tdct-task.model';
import { DefaultTask, MockTasks } from './tdct-task.mocks';

@Injectable({
  providedIn: 'root'
})
export class TdctTaskService {

  $: Task = DefaultTask;
  s$: Task[] = MockTasks;
  count: number = 0;

  constructor() { }
}
