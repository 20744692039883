import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { ImpOptionService } from '../../imp-option/imp-option.service';
import { ImpEventService } from '../../imp-event/imp-event.service';
import { AlertController } from '@ionic/angular';



@Component({
  selector: 'app-imp-bottle-option-option',
  templateUrl: './imp-bottle-option-option.component.html',
  styleUrls: ['./imp-bottle-option-option.component.scss'],
})
export class ImpBottleOptionOptionComponent implements OnInit {

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public event: ImpEventService,
    public option: ImpOptionService,
    public admin: TdctAdminService,
    public alert: AlertController
  ) { }

  ngOnInit() { }

  editOption(): void {
    this.auth.viewingBottleOptionEditorForm = !this.auth.viewingBottleOptionEditorForm;
    if (this.auth.viewingBottleOptionEditorForm) {
      this.admin.dismissOptionControl();
      this.auth.viewingEventBottleOptionPopover = false;
    }
  }

  async deleteOption() {
    this.option.loading = true;
    const eventBottlePath = `events/${this.option.$.eventId}/options/${this.option.$.id}`;
    await this.event.afs.doc(eventBottlePath).delete();
    --this.event.$.bottleOptionCount;
    this.admin.dismissOptionControl();
    const alert = await this.alert.create({
      header: 'Bottle Option Deleted',
      message: 'This bottle option has been deleted.',
      buttons: ['OK']
    });
    await alert.present();
    this.auth.viewingEventBottleOptionPopover = false;
    this.option.load();
  }

}
