import versionConfig from './version';
import firebaseConfig from './firebase';
import stripeConfig from './stripe';
import scannerConfig from './scanner';
import driversLicenseConfig from './drivers-license';
import durationsConfig from './durations';
import contentfulConfig from './contentful';
import mediaConfig from './media';
import { environment as eventbriteEnvironment } from './eventbrite';  // Import the Eventbrite environment

// Default environment will be set to DEV environment
export const environment = {
    production: false,
    name: '(DEV)',
    version: versionConfig.dev,
    stripe: stripeConfig.dev,
    firebase: firebaseConfig.dev,
    scanner: scannerConfig,
    driversLicense: driversLicenseConfig,
    durations: durationsConfig,
    contentful: contentfulConfig.preview,
    media: mediaConfig,
    eventBriteToken: eventbriteEnvironment.eventbriteToken,
};
