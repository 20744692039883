import { Injectable } from '@angular/core';
import { City } from './imp-city.model';
import { DefaultCity, MockCities } from './imp-city.mocks';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import * as moment from 'moment';
import {
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  AngularFirestore,
} from '@angular/fire/firestore';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Event } from '../imp-event/imp-event.model';

@Injectable({
  providedIn: 'root'
})
export class ImpCityService {

  $: City = DefaultCity;
  s$: City[] = MockCities;
  all$: City[] = [];
  sHidden$: City[] = [];
  cityCount: number = 0;
  search: string = '';
  count: number = 0;
  total: number = 0;
  viewing: boolean = false;
  loading: boolean = false;
  noneFound: boolean = false;
  editing: boolean = false;
  managing: boolean = false;
  sharing: boolean = false;
  doc: AngularFirestoreDocument<City>;
  col: AngularFirestoreCollection<City>;
  eventCol: AngularFirestoreCollection<Event>;
  theMoment: number;

  private readonly LOCAL_STORAGE_KEY = 'selectedCity';

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public afs: AngularFirestore,
    public router: Router,
  ) { }

  async create() {
    try {
      this.loading = true;
      this.$.id = this.afs.createId();
      this.stampTime();
      this.stampUser();
      await this.afs.doc('cities/' + this.$.id).set(this.$);
      const cityCreationPromises = [];
      cityCreationPromises.push(this.get(this.$.id));
      await Promise.all(cityCreationPromises);
      this.loading = false;
    } catch (error) {
    }
  }

  stampTime(): void {
    this.$.displayTimestamp = moment().format('YYYY-MM-DD');
    this.$.displayLastUpdateTimestamp = this.$.displayTimestamp;
    this.$.unixTimestamp = moment().unix();
    this.$.unixLastUpdateTimestamp = this.$.unixTimestamp;
  }

  stampUser(): void {
    this.$.userId = this.user.$.id;
    this.$.userName = this.user.$.name;
    this.$.userEmail = this.user.$.email;
    this.$.userPhoto = this.user.$.photo;
  }

  async get(id: string): Promise<City> {
    const cityPath = 'cities/' + id;
    this.doc = this.afs.doc<City>(cityPath);
    return await this.doc.valueChanges().pipe(first()).toPromise();
  }

  set(city: City): void {
    this.$ = city;
    this.$ = this.auth.momentLastUpdatedFromNow(this.$);
    this.viewing = true;
  }

  route(city: City) {
    this.set(city);
    console.log('[imp-city.service].route');
    this.router.navigateByUrl(`events/${city.id}`);
    this.auth.viewingCityRouted = true;
    this.auth.viewingCityList = false;
    this.toggleEventView();
  }

  async getCityById(id: string): Promise<City | null> {
    const cityPath = `cities/${id}`;
    this.doc = this.afs.doc<City>(cityPath);
    return await this.doc.valueChanges().pipe(first()).toPromise();
}

  saveSelectedCity(city: City): void {
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(city));
  }

  getSelectedCity(): City | null {
    const cityData = localStorage.getItem(this.LOCAL_STORAGE_KEY);

    if (cityData) {
      return JSON.parse(cityData);
    }
    return null;
  }

  async load(): Promise<City[]> {
    this.loading = true;
    this.count = 0;
    this.total = 0;
    this.s$ = [];
    this.all$ = [];
    this.loading = true;
    await this.filter();
    this.s$ = await this.col.valueChanges().pipe(first()).toPromise();
    this.cityCount = this.s$.length;

    const savedCity = this.getSelectedCity();
    if (savedCity && this.s$.some((city) => city.id === savedCity.id)) {
      this.set(savedCity);
      this.s$ = this.s$.map((city) => this.auth.momentCreatedExactly(city));
      this.s$ = this.s$.map((city) => this.auth.momentLastUpdatedFromNow(city));
      this.slowCount();
    } else if (this.s$.length > 0) {
      await this.setDefaultCity();
    }
    this.loading = false;
    return this.s$;
  }
  async setDefaultCity(): Promise<void> {
    const defaultCity = this.s$?.[0];
    if (defaultCity) {
      this.saveSelectedCity(defaultCity);
      this.set(defaultCity);
    }
  }
  async loadEvents(cityId: string): Promise<Event[]> {
    try {
      const eventsCol = this.afs.collection<Event>('events', (ref) =>
        ref.where('cityId', '==', cityId)
      );
      const events = await eventsCol.valueChanges().pipe(first()).toPromise();

      return (events as Event[]).sort(
        (a, b) => (a.displayTimestamp as any) - (b.displayTimestamp as any)
      );
    } catch (error) {
      console.error('Error loading events:', error);
      return [];
    }
  }

  async filter() {
    if (this.auth.viewingCityList) {
      console.log('viewingCityList');
      this.col = await this.afs.collection<City>('cities', (ref) =>
        ref
          .where('tags', 'array-contains', 'featured')
          .orderBy('unixLastUpdateTimestamp', 'desc')
      );
    } else if (this.auth.viewingUserPromoterCreatorForm) {
      console.log('viewingUserPromoterCreatorForm');
      this.col = await this.afs.collection<City>('cities', (ref) =>
        ref.orderBy('name', 'asc')
      );
    } else {
      console.log('Special City View');
      this.col = await this.afs.collection<City>('cities', (ref) =>
        ref.orderBy('unixLastUpdateTimestamp', 'desc')
      );
    }
  }

  async getCityByName(name: string): Promise<City | null> {
    const formattedName = name.replace(/-/g, ' ');
    const cityCollection = this.afs.collection<City>('cities', (ref) =>
      ref.where('name', '==', formattedName)
    );
    const citySnapshot = await cityCollection.get().toPromise();
    if (!citySnapshot.empty) {
      return citySnapshot.docs[0].data();
    }
    return null;
  }

  async getCityIdByName(cityName: string): Promise<string | null > {
    const formattedName = cityName.replace(/-/g, ' ');
    const cityCollection = this.afs.collection<City>('cities', (ref) =>
      ref.where('name', '==', formattedName)
    );
    const cityDoc = await cityCollection.get().toPromise();
    if (!cityDoc.empty) {
      const cityData = cityDoc.docs[0].data() as City;
      return cityData.id;
    }
    return null;
  }

  async fetchCity(identifier: string): Promise<City | null> {
    let city = await this.get(identifier);
    if (city) {
      return city;
    }

    city = await this.getCityByName(identifier);
    return city;
  }

  slowCount() {
    this.all$ = this.s$;
    this.total = this.s$.length;
    if (this.s$.length === 1) {
      this.count = 1;
    } else {
      this.s$ = this.all$.splice(0, 6);
      const interval = setInterval(() => {
        this.count++;
        if (this.count >= this.total) {
          clearInterval(interval);
          this.count = this.total;
        }
      }, 50);
    }
  }

  showMore() {
    let loadingCities: City[] = [];
    if (this.all$.length === 1) {
      loadingCities = this.all$.splice(0, 1);
    } else if (this.all$.length === 2) {
      loadingCities = this.all$.splice(0, 2);
    } else if (this.all$.length === 3) {
      loadingCities = this.all$.splice(0, 3);
    } else if (this.all$.length === 4) {
      loadingCities = this.all$.splice(0, 4);
    } else if (this.all$.length === 5) {
      loadingCities = this.all$.splice(0, 5);
    } else {
      loadingCities = this.all$.splice(0, 6);
    }
    this.s$.push(...loadingCities);
  }

  sanitize(): void {
    this.s$ = [];
  }

  clear(): void {
    this.editing = false;
  }

  toggleView(): void {
    this.clear();
    this.viewing = !this.viewing;
    if (this.viewing) {
      this.loading = true;
      this.set(this.$);
      this.loading = false;
    } else {
      this.loading = false;
    }
  }

  toggleEdit(): void {
    this.editing = !this.editing;
  }

    /**
   * Loads all cities from Firestore and stores them in all$ property.
   */
    async loadAllCities(): Promise<City[]> {
        try {
            this.loading = true;
            console.log('Loading all cities...');

            // Access the Firestore collection for all cities
            this.col = this.afs.collection<City>('cities', ref =>
                ref.orderBy('name', 'asc')
            );

            // Fetch all city documents as a list
            this.all$ = await this.col.valueChanges().pipe(first()).toPromise();
            this.cityCount = this.all$.length;

            console.log(`${this.cityCount} cities loaded successfully.`);
            this.loading = false;

            return this.all$;
        } catch (error) {
            console.error('Error loading all cities:', error);
            this.loading = false;
            return [];
        }
    }

  toggleEventView() {
    this.auth.viewingBottleOptionEditorForm = false;
    this.auth.viewingTableOptionEditorForm = false;
    this.auth.viewingTableBottleOptions = false;
    this.auth.viewingTableBottleOptionPopover = false;
    this.auth.viewingEventOptionBottleCreatorForm = false;
    this.auth.viewingTableOptionPopover = false;
    this.auth.viewingTicketOptionEditorForm = false;
    this.auth.viewingEventOptionPopover = false;
    this.auth.viewingEventOptionTicketForm = false;
    this.auth.viewingEventOptionTableForm = false;
    this.auth.viewingEventTicketOptions = false;
    this.auth.viewingEventTicketOptionPopover = false;
    this.auth.viewingEventTableOptions = false;
    this.auth.viewingEventTableOptionPopover = false;
  }

}
