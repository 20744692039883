import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { ImpOptionService } from '../../imp-option/imp-option.service';
import { ImpTableOptionBottleService } from '../imp-table-option-bottle/imp-table-option-bottle.service';


@Component({
  selector: 'app-imp-table-option-option',
  templateUrl: './imp-table-option-option.component.html',
  styleUrls: ['./imp-table-option-option.component.scss'],
})
export class ImpTableOptionOptionComponent implements OnInit {

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public option: ImpOptionService,
    public tableOptionBottle: ImpTableOptionBottleService,
    public admin: TdctAdminService
  ) { }

  ngOnInit() {
  }

  editOption(): void {
    this.auth.viewingTableOptionEditorForm = !this.auth.viewingTableOptionEditorForm;
    if (this.auth.viewingTableOptionEditorForm) {
      this.admin.dismissOptionControl();
      this.auth.viewingEventTableOptionPopover = false;
    }
  }

  toggleTabletBottleOptionForm(): void {
    this.auth.viewingEventOptionBottleCreatorForm = !this.auth.viewingEventOptionBottleCreatorForm;
    if (this.auth.viewingEventOptionBottleCreatorForm) {
      this.admin.dismissOptionControl();
      this.auth.viewingEventTableOptionPopover = false;
    }
  }
}
