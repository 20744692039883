import { Component, OnInit, Output, EventEmitter, ViewChild,  } from '@angular/core';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import { TdctAdminService } from './tdct-admin.service';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { TdctAskService } from '../tdct-ask/tdct-ask.service';
import { AngularFireFunctions } from '@angular/fire/functions';


@Component({
  selector: 'app-tdct-admin',
  templateUrl: './tdct-admin.component.html',
  styleUrls: ['./tdct-admin.component.scss'],
})
export class TdctAdminComponent implements OnInit {

  constructor(
    public user: TdctUserService,
    public ask: TdctAskService,
    public auth: TdctAuthService,
    public admin: TdctAdminService,
    public aff: AngularFireFunctions,
  ) { }

  ngOnInit(): void {

  }

  ngOnDestroy(): void {
    this.auth.viewingEngine = false;
    this.admin.stopEngine();
  }

  toggleEngineView(): void {
    this.auth.viewingEngine = !this.auth.viewingEngine;
    if (this.auth.viewingEngine) {
      this.admin.startEngine();
    }
    else { this.admin.stopEngine(); }
  }

  toggleBrandEngineView(): void {
    this.auth.viewingBrandEngine = !this.auth.viewingBrandEngine;
    if (this.auth.viewingBrandEngine) {
      this.admin.brandDigitalPoints = 0;
      this.admin.startBrandEngine();
    }
    else { this.admin.stopBrandEngine(); }
  }

  toggleCoreEngineView(): void {
    this.auth.viewingCoreEngine = !this.auth.viewingCoreEngine;
    if (this.auth.viewingCoreEngine) {
      this.admin.coreDigitalPoints = 0;
      this.admin.startCoreEngine();
    }
    else { this.admin.stopCoreEngine(); }
  }

  togglePromoterPanelView(): void {
    this.auth.viewingPromoterPanel = !this.auth.viewingPromoterPanel;
  }

  togglePromoterUserView(): void {
    this.auth.viewingPromoterUsers = !this.auth.viewingPromoterUsers;
  }

  togglePromoterAskView(): void {
    this.auth.viewingPromoterAsks = !this.auth.viewingPromoterAsks;
    if (this.auth.viewingPromoterAsks) {
      this.ask.load();
    }
  }

  async test() {
    await this.testEvents();
    await this.testPromoters();
    await this.testUsers();
    await this.testCounts();
  }

  async testEvents() {
      console.log('fixing Events!');
      const startTime = performance.now();
      const remoteFunction: any = this.aff.httpsCallable('fixEvents');
      const result: any = await remoteFunction().toPromise();
      const endTime = performance.now();
      const runTime: number = endTime - startTime;
      console.log(`Result: '${JSON.stringify(result)}' produced in ${runTime}ms`);
  }

  async testPromoters() {
      console.log('fixing Promoters!');
      const startTime = performance.now();
      const remoteFunction: any = this.aff.httpsCallable('fixPromoters');
      const result: any = await remoteFunction().toPromise();
      const endTime = performance.now();
      const runTime: number = endTime - startTime;
      console.log(`Result: '${JSON.stringify(result)}' produced in ${runTime}ms`);
  }

  async testUsers() {
      console.log('fixing Users!');
      const startTime = performance.now();
      const remoteFunction: any = this.aff.httpsCallable('fixUsers');
      const result: any = await remoteFunction().toPromise();
      const endTime = performance.now();
      const runTime: number = endTime - startTime;
      console.log(`Result: '${JSON.stringify(result)}' produced in ${runTime}ms`);
  }

  async testCounts() {
      console.log('fixing Counts!');
      const startTime = performance.now();
      const remoteFunction: any = this.aff.httpsCallable('fixCountsHttp');
      const result: any = await remoteFunction().toPromise();
      const endTime = performance.now();
      const runTime: number = endTime - startTime;
      console.log(`Result: '${JSON.stringify(result)}' produced in ${runTime}ms`);

  }
}
