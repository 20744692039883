import { Component, OnInit, Input,  } from '@angular/core';
import { Bottle } from './imp-bottle.model';
import { DefaultBottle } from './imp-bottle.mocks';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { ImpEventService } from '../imp-event/imp-event.service';
import { TdctUserPromoterService } from '../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpBottleService } from './imp-bottle.service';
import { TdctAdminService } from '../tdct-admin/tdct-admin.service';
import { PopoverController } from '@ionic/angular';
import { ImpOptionService } from '../imp-option/imp-option.service';


@Component({
  selector: 'app-imp-bottle',
  templateUrl: './imp-bottle.component.html',
  styleUrls: ['./imp-bottle.component.scss'],
})
export class ImpBottleComponent implements OnInit {

  @Input() bottle$: Bottle = DefaultBottle;
  eventRoute: string = '';

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public promoter: TdctUserPromoterService,
    public event: ImpEventService,
    public bottle: ImpBottleService,
    public option: ImpOptionService,
    public admin: TdctAdminService,
    public popover: PopoverController
  ) { }

  ngOnInit() { }

  ngOnChanges() {
    this.eventRoute = `/event/${this.bottle$.eventId}`;
  }

}
