import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { AlertController } from '@ionic/angular';
import { ImpCityService } from '../imp-city.service';
import { DefaultCity } from '../imp-city.mocks';


@Component({
  selector: 'app-imp-city-option',
  templateUrl: './imp-city-option.component.html',
  styleUrls: ['./imp-city-option.component.scss'],
})
export class ImpCityOptionComponent implements OnInit {

  cityFeatured: boolean = false;

  constructor(
    public city: ImpCityService,
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService,
    public alert: AlertController
  ) { }

  ngOnInit() {
    if (this.city.$.tags.includes('featured')) { this.cityFeatured = true; }
    else { this.cityFeatured = false; }
  }

  editCity(): void {
    this.city.editing = true;
    this.admin.dismissOptionControl();
    this.auth.viewingCityOptionPopover = false;
  }

  async deleteCity() {
    this.auth.loading = true;
    this.city.loading = true;
    await this.city.afs.doc('cities/' + this.city.$.id).delete();
    --this.admin.digitalPoints;
    --this.admin.brandDigitalPoints;
    --this.admin.$.cityCount;
    --this.admin.user$.cityCount;
    --this.admin.user$.cityTotal;
    --this.user.$.cityTotal;
    --this.user.$.cityCount;
    await this.city.load();
    this.city.$ = DefaultCity;
    this.city.viewing = false;
    this.auth.viewingCityOptionPopover = false;
    this.auth.loading = false;
    this.admin.dismissOptionControl();
    const alert = await this.alert.create({
      header: 'City Deleted',
      message: 'This city has been deleted.',
      buttons: ['OK']
    });
    await alert.present();
  }

  async toggleCityFeature() {
    this.city.loading = true;
    this.cityFeatured = !this.cityFeatured;
    if (this.cityFeatured) {
      this.city.afs.doc(`cities/${this.city.$.id}`).update({ tags: ['featured'] });
    } else { this.city.afs.doc(`cities/${this.city.$.id}`).update({ tags: [] }); }
    this.city.loading = false;
  }
}
