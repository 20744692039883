export default {
    production : {
        appVersion: '2.0.6 (1)',
        production: true,
    },
    dev: {
        appVersion: '2.0.6 (1)',
        production: false,
    },
    uat: {
        appVersion: '2.0.6 (1)',
        production: false,
    }
};
