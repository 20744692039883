import { Injectable, forwardRef, Inject } from '@angular/core';
import { Auth } from './tdct-auth.model';
import { DefaultAuth } from './tdct-auth.mocks';
import * as moment from 'moment';
import { User } from '../tdct-user/tdct-user.model';
import { AlertController } from '@ionic/angular';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { ImpThemeService } from '../imp-theme/imp-theme.service';
import { timer } from 'rxjs';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Promoter } from '../../../../functions/src/tdct-user-promoter/tdct-user-promoter.model';
import { first } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/firestore';
import { IMPExternalAnalyticsManagerService } from '../../services/impexternal-analytics-manager.service';

@Injectable({
  providedIn: 'root'
})
export class TdctAuthService {
    getCurrentUser() {
        throw new Error('Method not implemented.');
    }

  $: Auth = DefaultAuth;
  segment: {
    selected: string;
  } = { selected: 'home' };
  optionControl: any;
  eventControl: any;


  errorMessage: string = '';
  successMessage: string = '';
  // start tdct-auth states
  loading: boolean = false;
  viewing: boolean = false;
  viewingLoginForm: boolean = false;
  viewingSignupForm: boolean = false;
  viewingResetForm: boolean = false;
  // start tdct-auth states

  // start tdct-user states
  viewingUser: boolean = false;
  viewingUserEmailForm: boolean = false;
  viewingUserPasswordForm: boolean = false;
  viewingUserCreatorForm: boolean = false;
  viewingUserPromoterEventCreatorForm: boolean = false;
  editingEvent: boolean = false;
  viewingUserPromoterCreatorForm: boolean = false;
  viewingUserTickets: boolean = false;
  viewingUserTables: boolean = false;
  viewingUserOptionPopover: boolean = false;
  viewingUserProfile: boolean = false;
  viewingUserAccount: boolean = false;
  viewingUserManager: boolean = false;
  viewingUserPromoter: boolean = false;
  viewingUserPromoterRouted: boolean = false;
  viewingPromoterUsers: boolean = false;
  viewingUserPromoterOptionPopover: boolean = false;
  viewingPromoterAnalytics: boolean = false;
  userState: boolean = false;
  userAdmin: boolean = false;
  userSubscriber: boolean = false;
  userPromoter: boolean = false;
  userCustomer: boolean = false;
  userOwner: boolean = false;

  // end tdct-user states

  // start tdct-upload states
  imageUploaded: boolean = false;
  uploadingUserImage: boolean = false;
  uploadingUserPromoterImage: boolean = false;
  uploadingCityImage: boolean = false;
  uploadingEventImage: boolean = false;
  uploadingTableOptionImage: boolean = false;
  uploadingBottleOptionImage: boolean = false;
  // end tdct-upload state

  // start tdct-ask states
  viewingPromoterAsks: boolean = false;
  viewingAskOptionPopover: boolean = false;
  // end tdct-ask staets

  // start tdct-admin states
  viewingEngine: boolean = false;
  viewingBrandEngine: boolean = false;
  viewingCoreEngine: boolean = false;
  viewingController: boolean = false;
  viewingPromoterPanel: boolean = false;
  viewingManagedUserPromoterProfiles: boolean = false;

  // end tdct-admin states

  // start imp-theme states
  viewingAbout: boolean = false;
  // end imp-theme states

  // start imp-event states
  viewingPromoterEvents: boolean = false;
  viewingEventsFeatured: boolean = false;
  viewingEventRouted: boolean = false;
  viewingEventCreatorForm: boolean = false;
  viewingEventOptionPopover: boolean = false;
  viewingEventOptionTicketForm: boolean = false;
  viewingEventOptionTableForm: boolean = false;
  viewingEventTicketOptions: boolean = false;
  viewingEventTicketOptionPopover: boolean = false;
  viewingEventTableOptions: boolean = false;
  viewingEventTableOptionPopover: boolean = false;
  viewingEventBottleOptions: boolean = false;
  viewingEventBottleOptionPopover: boolean = false;
  viewingEventStripeElementsForm: boolean = false;
  viewingEventAnalytics: boolean = false;
  // end imp-event states

  // start imp-city states
  viewingCityList: boolean = false;
  viewingCityRouted: boolean = false;
  viewingCityCreatorForm: boolean = false;
  viewingCityOptionPopover: boolean = false;
  viewingCityPromoters: boolean = false;
  viewingCityEvents: boolean = false;
  // end imp-city states

  // start imp-ticket states
  viewingTicketOptionEditorForm: boolean = false;
  viewingTicketRouted: boolean = false;
  // end imp-ticket states

  // start imp-table states
  viewingTableBottles: boolean = false;
  viewingTableRouted: boolean = false;
  viewingTableOptionEditorForm: boolean = false;
  viewingTableBottleOptions: boolean = false;
  viewingTableBottleOptionPopover: boolean = false;
  viewingEventOptionBottleCreatorForm: boolean = false;
  viewingTableOptionPopover: boolean = false;
  // end imp-table states

  // start imp-bottle states
  viewingBottleOptionEditorForm: boolean = false;
  viewingBottlesIncluded: boolean = false;
  // end imp-bottle states

  // start imp-venue states
  viewingVenueRouted: boolean = false;
  viewingVenueCreatorForm: boolean = false;
  viewingVenueOptionPopover: boolean = false;
  viewingVenueEditorForm: boolean = false;
  uploadingVenueImage: boolean = false;
  // end imp-venue states

  // start order states
  viewingCheckoutReturnPage: boolean = false;
  viewingCheckoutReturnPageRouted: boolean = false;
  viewingEventDetailsRoute: boolean = false;


  constructor(
    public user: TdctUserService,
    public theme: ImpThemeService,
    public alert: AlertController,
    public afa: AngularFireAuth,
    public browser: InAppBrowser,
    public router: Router,
    public afs: AngularFirestore,
    private analytics: IMPExternalAnalyticsManagerService
  ) {
    this.sync();
    this.getUserRole();
  }

  async sync() {
    await this.afa.authState.subscribe((state) => {
      this.loading = true;
      this.theme.loading = false;
      timer(500).subscribe(() => {
        if (state) {
          this.startSession();
          this.analytics.logEvent(this.analytics.AUTH_SESSION_STARTED);
        } else {
          this.endSession();
          this.analytics.logEvent(this.analytics.AUTH_SESSION_ENDED);
        }
      });
    });
  }

  async getPromoterArray() {
    // console.log('getting promoter array');
    const promoterCol = await this.afs.collection<Promoter>(`users/${this.user.$.id}/promoters`);
    // console.log(promoterCol);
    const promoterArray = await promoterCol.valueChanges().pipe(first()).toPromise();
    if (promoterArray.length > 0) {
      // console.log(promoterArray[0].id);
      this.user.$.userPromoterId = promoterArray[0].id;
      // console.log('this.user.$.userpromoterId: ' + this.user.$.userPromoterId);
    }
    return promoterArray;
  }

  async getUserRole() {
    try {
      const currentUser = await this.afa.currentUser;
      if (currentUser) {
        const user = await this.user.get(currentUser.uid);
        const roles = user?.roles || [];
        if (roles.includes('admin')) {
          return 'admin';
        } else if (roles.includes('promoter')) {
          return 'promoter';
        } else if (roles.includes('subscriber') || roles.includes('customer')) {
          return 'user';
        } else {
          return 'user';
        }
      } else {
        return 'user';
      }
    } catch (error) {
      console.error('Error fetching user role:', error);
      return 'user';
    }
  }

  async startSession() {
    try {
      const firebaseUser = await this.afa.currentUser;
      if (!firebaseUser) {
        throw new Error('No Firebase user found');
      }

      const user = await this.user.get(firebaseUser.uid);
      if (user) {
        this.viewingLoginForm = false;
        this.signIn(user);
        this.loading = false;
        await this.analytics.logEvent(this.analytics.GA_LOGIN, {
          method: 'session_restore',
          roles: (user.roles || []).join(',')
        });
      }
      await this.getPromoterArray();
    } catch (error) {
      console.error('Session start error:', error);
      await this.analytics.logEvent(this.analytics.AUTH_LOGIN_ERROR, {
        error_code: error.code,
        error_message: error.message
      });
    }
  }

  async register() {
    try {
      this.loading = true; // Set loading to true when starting the signup process
      await this.analytics.logEvent(this.analytics.GA_SIGN_UP);
      
      this.user.$.photo = 'https://firebasestorage.googleapis.com/v0/b/tdct-production.appspot.com/o/default-profile.png?alt=media&token=556ff22b-26bf-465d-b50b-73457f2c073d';
      await this.updateAuthProfile();
      
      const currentUser = await this.afa.currentUser;
      if (!currentUser) {
        throw new Error('No Firebase user found');
      }

      const auth: Auth = {
        uid: currentUser.uid,
        displayName: currentUser.displayName || '',
        email: currentUser.email || '',
        photoURL: currentUser.photoURL || '',
        password: '',
        isPremium: false
      };
      
      const user = await this.user.create(auth);
      if (!user) {
        throw new Error('Failed to create user');
      }
      
      this.viewingSignupForm = false;
      this.signIn(user);
      this.loading = false; // Set loading to false after the process is complete

      await this.analytics.logEvent(this.analytics.GA_SIGN_UP, {
        has_profile_photo: !!user.photo,
        roles: (user.roles || []).join(',')
      });
    } catch (error) {
      console.error('Registration error:', error);
      await this.analytics.logEvent(this.analytics.AUTH_LOGIN_ERROR, {
        error_code: error.code,
        error_message: error.message
      });
    }
  }

  async updateAuthProfile() {
    const currentUser = await this.afa.currentUser;
    return await currentUser.updateProfile({
      displayName: this.user.$.name,
      photoURL: this.user.$.photo
    });
  }


  async endSession() {
    this.loading = true;
    try {
      await this.analytics.logEvent(this.analytics.AUTH_LOGOUT);
      await this.afa.signOut();
      this.signOut();
    } catch (error) {
      console.error('Logout error:', error);
    }
    this.loading = false;
  }


async presentSuccessAlert(message: string) {
  const alert = await this.alert.create({
    header: 'Success',
    message,
    buttons: ['OK'],
    cssClass: 'success-alert'  // Add custom class for success alert
  });
  await alert.present();
  this.loading = false;
}

async presentErrorAlert(error) {
  const alert = await this.alert.create({
    header: error.code || 'Error',
    message: error.message,
    buttons: ['OK'],
    cssClass: 'error-alert'  // Add custom class for error alert
  });
  await alert.present();
  this.loading = false;

  // Log error to analytics
  await this.analytics.logEvent(this.analytics.AUTH_LOGIN_ERROR, {
    error_code: error.code,
    error_message: error.message
  });
}

  async blockUser() {
    const alert = await this.alert.create({
      header: 'User Blocked',
      message: 'Please contact support at info@imp-events.com to discuss your account',
      buttons: ['OK']
    });
    await alert.present();
    this.signOut();
  }

  async signOut() {
    await this.afa.signOut();
    this.$.uid = '';
    this.$.email = '';
    this.$.displayName = '';
    this.$.photoURL = '';
    this.clearRoles();
    this.toggleProfile();
    this.viewingLoginForm = true;
  }

  clearRoles(): void {
    this.userState = false;
    this.userSubscriber = false;
    this.userAdmin = false;
    this.userPromoter = false;
    this.userCustomer = false;
    this.userOwner = false;
  }

  async signIn(user: User): Promise<void> {
    if (user.roles.includes('blocked')) {
      this.blockUser();
    } else {
      this.$.uid = user.id;
      this.$.email = user.email;
      this.$.displayName = user.name;
      this.$.photoURL = user.photo;
      this.$.password = '';
      this.stampRoles(user);
      this.user.set(user);
      this.viewingUser = true;

      // Log successful sign in
      await this.analytics.logEvent(this.analytics.GA_LOGIN, {
        method: 'explicit_login',
        roles: (user.roles || []).join(',')
      });
    }
  }

  stampRoles(user: User): void {
    this.userState = true;
    user.roles.includes('subscriber') ? this.userSubscriber = true : this.userSubscriber = false;
    user.roles.includes('admin') ? this.userAdmin = true : this.userAdmin = false;
    user.roles.includes('promoter') ? this.userPromoter = true : this.userPromoter = false;
    user.roles.includes('customer') ? this.userCustomer = true : this.userCustomer = false;
  }

  momentCreatedExactly(digit: any): any {
    digit.displayTimestamp = moment.unix(digit.unixTimestamp).format('dddd[,] MMMM Do[,] YYYY [at] h:mm a');
    return digit;
  }

  momentCreatedFromNow(digit: any): any {
    digit.displayTimestamp = moment.unix(digit.unixTimestamp).fromNow();
    return digit;
  }

  momentLastUpdatedExactly(digit: any): any {
    digit.displayLastUpdateTimestamp = moment.unix(digit.unixLastUpdateTimestamp).format('dddd[,] MMMM Do[,] YYYY [at] h:mm a');
    return digit;
  }

  momentLastUpdatedFromNow(digit: any): any {
    digit.displayLastUpdateTimestamp = moment.unix(digit.unixLastUpdateTimestamp).fromNow();
    return digit;
  }

  toggleProfile() {
    this.segment.selected = 'profile';
    this.viewingUserProfile = true;
    this.viewingUserAccount = false;
    this.viewingUserManager = false;
    this.viewingUser = true;
  }

  toggleAccount() {
    this.segment.selected = 'account';
    this.viewingUserProfile = false;
    this.viewingUserAccount = true;
    this.viewingUserManager = false;
    this.viewingUserPromoter = false;
    this.viewingUser = true;
  }

  toggleManager(): void {
    this.segment.selected = 'manager';
    this.viewingUserProfile = false;
    this.viewingUserAccount = false;
    this.viewingUserManager = true;
  }

  toggleView(): void {
    this.viewing = !this.viewing;
    if (this.viewing) {
      this.toggleLoginForm();
    } else {
      this.toggleProfile();
    }
  }

  toggleLoginForm(): void {
    this.viewingLoginForm = true;
    this.viewingSignupForm = false;
    this.viewingResetForm = false;
  }

  toggleSignupForm(): void {
    this.viewingSignupForm = true;
    this.viewingLoginForm = false;
    this.viewingResetForm = false;
  }

  toggleResetForm(): void {
    this.viewingResetForm = true;
    this.viewingLoginForm = false;
    this.viewingSignupForm = false;
  }

  clearBrandDigitStates(): void {
    this.viewingUserTables = false;
    this.viewingPromoterPanel = false;
    this.viewingUserPromoterCreatorForm = false;
    this.viewingPromoterAnalytics = false;
    this.viewingEventAnalytics = false;
    this.viewingUserPromoterRouted = false;
    this.viewingBottleOptionEditorForm = false;
    this.viewingTicketRouted = false;
    this.viewingTableRouted = false;
    this.viewingTableOptionEditorForm = false;
    this.viewingTableBottleOptions = false;
    this.viewingEventBottleOptionPopover = false;
    this.viewingTableBottleOptionPopover = false;
    this.viewingEventOptionBottleCreatorForm = false;
    this.viewingTableOptionPopover = false;
    this.viewingTicketOptionEditorForm = false;
    this.viewingCityList = false;
    this.viewingCityRouted = false;
    this.viewingCityCreatorForm = false;
    this.viewingCityOptionPopover = false;
    this.viewingCityPromoters = false;
    this.viewingCityEvents = false;
    this.viewingPromoterEvents = false;
    this.viewingEventsFeatured = false;
    this.viewingEventRouted = false;
    this.viewingEventCreatorForm = false;
    this.viewingEventOptionPopover = false;
    this.viewingEventOptionTicketForm = false;
    this.viewingEventOptionTableForm = false;
    this.viewingEventTicketOptions = false;
    this.viewingEventTicketOptionPopover = false;
    this.viewingEventTableOptions = false;
    this.viewingEventTableOptionPopover = false;
    this.viewingVenueRouted = false;
    this.viewingVenueCreatorForm = false;
    this.viewingVenueOptionPopover = false;
    this.viewingVenueEditorForm = false;
    this.viewingCheckoutReturnPageRouted = false;
    this.viewingCheckoutReturnPage = false;
    this.viewingEventStripeElementsForm = false;
    this.viewingEventDetailsRoute = false;
  }

  open(link: string): void {
    console.log(`Opening link: ${link}`);
    this.browser.create(link, '_system');
  }

  isAdmin(): boolean {
    // Check if the current user has admin role
    return this.user?.$.roles?.includes('admin') || false;
  }
}
