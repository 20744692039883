import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { ImpCityService } from '../imp-city.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { DefaultCity } from '../imp-city.mocks';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { TdctUploadService } from '../../tdct-upload/tdct-upload.service';
import { City } from '../imp-city.model';


@Component({
  selector: 'app-imp-city-creator-form',
  templateUrl: './imp-city-creator-form.component.html',
  styleUrls: ['./imp-city-creator-form.component.scss'],
})
export class ImpCityCreatorFormComponent implements OnInit {

  cityForm: City = DefaultCity;
  submitted: boolean = false;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public upload: TdctUploadService,
    public city: ImpCityService,
    public promoter: TdctUserPromoterService,
    public admin: TdctAdminService
  ) { }

  ngOnInit() {
    this.city.$ = DefaultCity;
    this.auth.uploadingCityImage = true;
  }

  ngOnDestroy(): void {
    this.auth.uploadingCityImage = false;
    this.auth.imageUploaded = false;
  }

  toggleCityImageChange(): void {
    this.auth.uploadingCityImage = !this.auth.uploadingCityImage;
  }

  async submit(cityCreatorForm) {
    this.city.loading = true;
    this.submitted = true;
    if (cityCreatorForm.valid) {
      try {
        this.auth.loading = false;
        this.city.$.name = cityCreatorForm.name;
        this.city.$.description = cityCreatorForm.description;
        await this.createCity();
        this.auth.viewingCityCreatorForm = false;
        this.auth.loading = false;
      } catch (error) {
        error = {
          code: 'Error',
          message: 'Error creating city'
        };
        this.auth.presentErrorAlert(error);
      }
    }
  }

  async createCity() {
    this.auth.loading = true;
    await this.city.create();
    ++this.user.$.cityCount;
    ++this.city.count;
    if (this.auth.userAdmin) { ++this.user.$.cityTotal; }
    this.auth.loading = false;
    this.city.load();
  }
}
