/*
=========================================================================================================
app.component.ts
----------------
Declares the appComponent class and houses its respective functions.
=========================================================================================================
*/
import { Component, OnInit, ViewChild, Injector } from '@angular/core';
import { Platform, MenuController, AlertController } from '@ionic/angular';
import { Router, NavigationEnd } from '@angular/router';
import { TdctUserService } from './home/tdct-user/tdct-user.service';
import { environment } from '../environments/environment';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ImpThemeService } from './home/imp-theme/imp-theme.service';
import { DarkTheme } from './home/imp-theme/imp-theme.mocks';
import { TdctAuthService } from './home/tdct-auth/tdct-auth.service';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Deeplinks } from '@ionic-native/deeplinks/ngx';
import { ModalService } from './home/imp-modal/imp-modal.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFirestore } from '@angular/fire/firestore';
import { User } from './home/tdct-user/tdct-user.model';
import { NgZone } from '@angular/core';
import { InAppPurchaseService } from './services/in-app-purchase.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    public isLoggedIn: boolean = false;
    envName = environment.name;
    public menuItems: any[] = [];
    userRole: string = '';
    public APP_VERSION: string = environment.version.appVersion;
    public testUser: User = {
        id: 'test-id',
        uid: 'test-id',
        name: 'Test User',
        email: 'test@example.com',
        photo: '',
        displayTimestamp: '',
        displayLastUpdateTimestamp: '',
        unixLastUpdateTimestamp: 0,
        unixTimestamp: 0,
        roles: ['promoter'],
        isPremium: false,
        dob: ''
    };

    constructor(
        public auth: TdctAuthService,
        public theme: ImpThemeService,
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private router: Router,
        private zone: NgZone,
        private deeplinks: Deeplinks,
        private modalService: ModalService,
        private iab: InAppBrowser,
        public afa: AngularFireAuth,
        private menuCtrl: MenuController,
        public user: TdctUserService,
        private aff: AngularFireFunctions,
        private afs: AngularFirestore,
        private injector: Injector
    ) {
        this.initializeApp();
        this.setupMenuItems();
        this.fetchUser();
    }

    ngOnInit() {
        // Subscribe to router events to close the menu on route change
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Close the menu after navigation ends
                this.menuCtrl.close();
            }
        });
    }

    toggleTheme() {
        this.theme.toggle();
    }

    async fetchUser() {
        await this.afa.authState.subscribe(async (state) => {
            if (state) {
                try {
                    // User is logged in
                    this.isLoggedIn = true;

                    // Ensure user is fully loaded before setting up menu items
                    const userDoc = await this.afs.doc(`users/${state.uid}`).get().toPromise();
                    const userData = userDoc.data() as User | undefined;

                    if (userData) {
                        // Manually set premium status from Firestore or localStorage
                        const isLocalStoragePremium = localStorage.getItem('testPremiumEnabled') === 'true';
                        const firestorePremium = userData.isPremium ?? false;

                        // Set user data
                        this.user.$ = {
                            ...userData,
                            isPremium: isLocalStoragePremium || firestorePremium
                        };

                        // Refresh menu items
                        this.setupMenuItems();
                    }
                } catch (error) {
                    console.error('Error fetching user:', error);
                    this.isLoggedIn = false;
                }
            } else {
                // User is not logged in
                this.isLoggedIn = false;
                this.menuItems = [
                    { title: 'Featured', icon: 'star-outline', route: '/home' },
                    { title: 'Cities', icon: 'map-outline', route: '/events' },
                ];
            }
        });
    }

    async setupMenuItems() {
        console.group('Setting up Menu Items');
        let isPromoter = this.user.$.roles?.includes('promoter');
        let isPremium = this.user.$.isPremium;
        let isAdmin = this.user.$.roles?.includes('admin');
        try {
            // Default menu items for all users
            const defaultMenuItems = [
                { title: 'Featured', icon: 'star-outline', route: '/home' },
                { title: 'Cities', icon: 'map-outline', route: '/events' },
            ];

            // Reset menu items
            this.menuItems = [...defaultMenuItems];

            // Only add additional items if user is logged in
            if (this.isLoggedIn) {
                // Add logged-in user specific menu items
                this.menuItems.push(
                    { title: 'Orders', icon: 'receipt-outline', route: '/orders' },
                    { title: 'Settings', icon: 'settings-outline', route: '/account' }
                );

                // Check if user is a promoter
                if (isPromoter) {
                    this.menuItems.push({ 
                        title: 'Create Event', 
                        icon: 'add-circle-outline', 
                        route: '/promoter/create/event' 
                    });

                    // Add My Events for promoters
                    this.menuItems.push({ 
                        title: 'My Events', 
                        icon: 'calendar-outline', 
                        route: '/user/profile/promoter/events' 
                    });
                }

                // Check if user is an admin
                if (this.user.$.roles?.includes('admin')) {
                    this.menuItems.push(
                        { 
                            title: 'Admin Dashboard', 
                            icon: 'shield-outline', 
                            route: '/admin/dashboard' 
                        },
                        { 
                            title: 'Manage Users', 
                            icon: 'people-outline', 
                            route: '/admin/manage/users' 
                        },
                        { 
                            title: 'Manage Users (v2)', 
                            icon: 'people-outline', 
                            route: '/v2/admin/users' 
                        },
                        { 
                            title: 'Manage Events', 
                            icon: 'calendar-outline', 
                            route: '/admin/manage/events' 
                        }
                    );
                }

                // Check for premium features
                if (isPromoter && isPremium) {
                    console.log('✅ Adding Analytics Menu Item');
                    this.menuItems.push({ 
                        title: 'Analytics', 
                        icon: 'analytics-outline', 
                        route: '/analytics' 
                    });
                }

                //Add upgrade option only for logged-in, non-premium users, who are also promoters
                if (isPromoter && !isPremium && !isAdmin) {
                    this.menuItems.push({ 
                        title: 'Upgrade', 
                        icon: 'trending-up', 
                        action: () => this.initiatePremiumMembership(),
                    });
                }
            }

            console.log('Final Menu Items:', this.menuItems);
            console.groupEnd();
        } catch (error) {
            console.error('Error setting up menu items:', error);
            // Fallback to default menu items if something goes wrong
            this.menuItems = [
                { title: 'Featured', icon: 'star-outline', route: '/home' },
                { title: 'Cities', icon: 'map-outline', route: '/events' },
            ];
        }
    }

    async togglePremiumStatus() {
        // Toggle the premium status in the user service
        (this.user.$ as User).isPremium = !(this.user.$ as User).isPremium;
        console.log('Premium status:', (this.user.$ as User).isPremium);

        // Manage localStorage for test premium status
        if ((this.user.$ as User).isPremium) {
            localStorage.setItem('testPremiumEnabled', 'true');
            console.log('Setting localStorage testPremiumEnabled to true');
        } else {
            localStorage.removeItem('testPremiumEnabled');
            console.log('Removing localStorage testPremiumEnabled');
        }

        try {
            // Call the test premium membership cloud function
            const callable = this.aff.httpsCallable('testPremiumMembership');
            const result = await callable({ userId: (this.user.$ as User).id }).toPromise();
            console.log('Premium membership test result:', result);

            // Update the menu items and force change detection
            this.zone.run(() => {
                this.setupMenuItems();
            });
        } catch (error) {
            console.error('Error toggling premium status:', error);
        }
    }

    async initiatePremiumMembership() {
        try {
            console.error('🚨 INITIATING PREMIUM MEMBERSHIP');
            console.error('🚨 Platform Platforms:', this.platform.platforms());
            console.error('🚨 Is iOS:', this.platform.is('ios'));

            // Check if it's an iOS platform
            const isIOS = this.platform.is('ios');

            if (isIOS) {
                // Use IAP for iOS
                console.error('🚨 Using IAP for iOS');
                const iapService = this.injector.get(InAppPurchaseService);
                const productId = environment.iap.subscriptionIds.premium;
                
                const success = await iapService.purchaseSubscription(productId);
                
                if (!success) {
                    console.error('🚨 IAP Purchase Failed');
                    // Optional: Show error to user
                    return;
                }
            } else {
                // Use Stripe for non-iOS platforms
                console.error('🚨 Using Stripe for non-iOS platform');
                const callable = this.aff.httpsCallable('initiatePremiumMembership');
                const result = await callable({}).toPromise();
                
                if (result?.url) {
                    window.location.href = result.url;
                } else {
                    console.error('No URL in response:', result);
                    // Optional: Add error handling
                }
            }
        } catch (error: any) {
            console.error('🚨 Error initiating premium membership:', error);
            // Optional: Add comprehensive error handling
        }
    }

    initializeApp() {
        // deeplink listener event
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            this.zone.run(() => {
                const domain = 'imp-events.com';
                const slug = event.url.split(domain).pop();
                if (slug) {
                    this.router.navigateByUrl(slug);
                }
            });
        });

        this.platform.ready().then(() => {
            this.theme.set(DarkTheme);
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }

    openModal(id: string) {
        this.modalService.open(id);
    }

    openUrl(url: string) {
        this.iab.create(url, '_system');
    }
}
