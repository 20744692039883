/*
=========================================================================================================
imp-city.component.ts
----------------
Declares the impCityComponent class and houses it's respective functions.
---------------------------------------------------------------------
File History:
08/16/19 -> 03/22/23: History not tracked on file. Availible in Github

03/23/23 -> Steven Kleinberg: Feature/Ticket:
                                Enable deep link functionality.
                              Changes to code:
                                added ActivatedRoute to imports.
                                added ActivatedRoute to Constructor.
                                added id variable to test and display deeplink route info.
                                added line in ngOnInit() to get id from route params.
                                added line #19 in corresponding html file "imp-city.component.html" to display test information.
=========================================================================================================
*/
import { Component, OnInit, Input, OnChanges, Output, EventEmitter,  } from '@angular/core';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { TdctAdminService } from '../tdct-admin/tdct-admin.service';
import { ImpCityService } from './imp-city.service';
import { DefaultCity } from './imp-city.mocks';
import { City } from './imp-city.model';
import { ImpCityOptionComponent } from './imp-city-option/imp-city-option.component';
import { PopoverController } from '@ionic/angular';
import { TdctUserPromoterService } from '../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpEventService } from '../imp-event/imp-event.service';
import * as moment from 'moment';
import {Event} from '../imp-event/imp-event.model';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';



@Component({
  selector: 'app-imp-city',
  templateUrl: './imp-city.component.html',
  styleUrls: ['./imp-city.component.scss'],
})
export class ImpCityComponent implements OnInit, OnChanges {
  // test variable to show id while developing deeplinks.
  id = null;
  @Input() city$: City = DefaultCity;

  eventCol: AngularFirestoreCollection<Event>;
  cityEventCount: number;
  public theMoment: number;
  @Output() showCities = new EventEmitter<void>();
  public userId: string;
  segmentModel: string = 'events';

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public promoter: TdctUserPromoterService,
    public city: ImpCityService,
    public event: ImpEventService,
    public admin: TdctAdminService,
    public popover: PopoverController,
    public afs: AngularFirestore,
    private route: ActivatedRoute,
    private http: HttpClient,
    public router: Router,
    public afa: AngularFireAuth,
  ) { }

 ngOnInit() {
  this.route.queryParams.subscribe(async params => {
    const cityName = params.cityName || null;
    if (cityName) {
      console.log('City Name from route:', cityName);

      try {
        const fetchedCity = await this.city.getCityByName(cityName);
        if (fetchedCity) {
          this.city$ = fetchedCity;
          console.log('Fetched City:', this.city$);
        } else {
          console.error('City not found');
        }
      } catch (error) {
        console.error('Error fetching city data:', error);
      }
    }
  });

  this.afa.authState.subscribe((user) => {
    if (user) {
      this.userId = user.uid;
    }
  });
  console.log('ngOnInit');
  console.log('====77====', this.city$);
}

  showAllCities() {
    this.showCities.emit();
  }

  async ngOnChanges(): Promise<any> {
    this.theMoment = moment().unix();
    await this.getCityEventCount();
    // console.log('count updated', this.cityEventCount);
  }

  toggleCityOption(action: string) {
    this.city.set(this.city$);
    this.auth.viewingCityOptionPopover = !this.auth.viewingCityOptionPopover;
    if (this.auth.viewingCityOptionPopover) {
      switch (action) {
        case 'manage': {
          this.city.managing = true;
          this.city.sharing = false;
        }
                       break;
        default: {
          this.city.managing = false;
          this.city.sharing = true;
        }
      }
      this.presentPopover();
    }
  }

  async presentPopover() {
    this.auth.optionControl = await this.popover.create({
      component: ImpCityOptionComponent,
      showBackdrop: false,
      animated: true
    });
    this.auth.optionControl.onDidDismiss().then(() => {
      this.auth.viewingCityOptionPopover = false;
    });
    return await this.auth.optionControl.present();
  }

  toggleCityPromoters() {
    this.auth.viewingCityPromoters = !this.auth.viewingCityPromoters;
    if (this.auth.viewingCityPromoters) {
      this.promoter.load();
    }
  }

  toggleCityEvents() {
    this.auth.viewingCityEvents = !this.auth.viewingCityEvents;
    if (this.auth.viewingCityEvents) {
      this.event.load(this.userId);
    }
  }

  async getCityEventCount() {
    this.eventCol = await this.afs.collection<Event>('events', ref => ref
        .where('cities', 'array-contains', this.city.$.id)
        .where('onlineEndTimestamp', '>', this.theMoment)
        .orderBy('onlineEndTimestamp', 'asc')
    );
    await this.eventCol.get().toPromise().then(querySnapshot => {
              console.log('querySnapshot.size', querySnapshot.size);
              this.cityEventCount = querySnapshot.size;
            }
        );
  }

  onSegmentChange(event: any) {
    this.segmentModel = event.detail.value;
  }

}
