import { Component, OnInit,  } from '@angular/core';
import { ImpEventService } from '../imp-event.service';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { Event } from '../imp-event.model';
import * as moment from 'moment';


@Component({
  selector: 'app-imp-event-editor-form',
  templateUrl: './imp-event-editor-form.component.html',
  styleUrls: ['./imp-event-editor-form.component.scss'],
})
export class ImpEventEditorFormComponent implements OnInit {

  submitted: boolean = false;
  settings: Array<string> = [];
  startTime: any;
  endTime: any;
  startTimeFocused: boolean = false;
  startTimestamped: boolean = false;
  endTimeFocused: boolean = false;
  endTimestamped: boolean = false;

  customPopoverOptions: any = {
    header: 'Please select event type',
    message: 'One option per event'
  };

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public event: ImpEventService
  ) { }

  ngOnInit(): void {
    this.initializeTime();
  }

  ngOnDestroy(): void {
    this.event.editing = false;
    this.auth.uploadingEventImage = false;
  }

  initializeTime(): void {
    this.startTime = moment.unix(this.event.$.unixStartTimestamp).toISOString();
    this.endTime = moment.unix(this.event.$.unixEndTimestamp).toISOString();
  }

  startTimeInFocus(event) {
    this.startTimeFocused = true;
  }

  startTimeChanged(event) {
    if (this.startTimeFocused) {
      this.startTimestamped = true;
      this.event.$.unixStartTimestamp = moment(event.detail.value).unix();
      this.event.$.unixEndTimestamp = this.event.$.unixStartTimestamp + 1800;
      this.endTime = moment.unix(this.event.$.unixEndTimestamp).toISOString();
    }
  }

  endTimeInFocus(event) {
    this.endTimeFocused = true;
  }

  endTimeChanged(event) {
    if (this.endTimeFocused) {
      this.endTimestamped = true;
      this.event.$.unixEndTimestamp = moment(event.detail.value).unix();
    }
  }

  toggleEventImageChange(): void {
    this.auth.uploadingEventImage = !this.auth.uploadingEventImage;
  }

  async submit(eventForm) {
    this.submitted = true;
    this.stamp(eventForm);
    await this.editEvent();
  }

  stamp(eventForm: Event) {
    this.event.$.name = eventForm.name || this.event.$.name;
    this.event.$.description = eventForm.description || this.event.$.description;
    this.event.$.description = this.event.$.description.replace(/(\r\n\t|\n|\r\t)/gm, '');
  }

  async editEvent() {
    try {
      this.updateTimestamp();
      await this.event.get(this.event.$.id);
      await this.event.doc.update({
        name: this.event.$.name,
        description: this.event.$.description,
        image: this.event.$.image,
        unixLastUpdateTimestamp: this.event.$.unixLastUpdateTimestamp,
        displayLastUpdateTimestamp: this.event.$.displayLastUpdateTimestamp,
        unixStartTimestamp: this.event.$.unixStartTimestamp,
        displayStartTimestamp: this.event.$.displayStartTimestamp,
        unixEndTimestamp: this.event.$.unixEndTimestamp,
        displayEndTimestamp: this.event.$.displayEndTimestamp
      });
      this.auth.loading = false;
      this.event.editing = false;
    } catch (error) {
      this.auth.presentErrorAlert(error);
    }
  }

  updateTimestamp(): void {
    this.event.$.displayLastUpdateTimestamp = moment().format('YYYY-MM-DD');
    this.event.$.unixLastUpdateTimestamp = moment().unix();
    this.event.$.displayStartTimestamp = moment.unix(this.event.$.unixStartTimestamp).format('dddd[,] MMMM D[,] YYYY [at] h:mm A');
    this.event.$.displayEndTimestamp = moment.unix(this.event.$.unixEndTimestamp).format('dddd[,] MMMM D[,] YYYY [at] h:mm A');
  }

  clearForm(eventForm: Event): void {
    eventForm.name = '';
    eventForm.description = '';
    this.submitted = false;
  }
}
