import { Component, OnInit, Input } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpEventService } from '../imp-event.service';
import { ImpTableService } from '../../imp-table/imp-table.service';
import { ImpOptionService } from '../../imp-option/imp-option.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';


@Component({
  selector: 'app-imp-event-table-option-list',
  templateUrl: './imp-event-table-option-list.component.html',
  styleUrls: ['./imp-event-table-option-list.component.scss'],
})
export class ImpEventTableOptionListComponent implements OnInit{
  @Input()loggedOutTableOptions: any[];
  @Input() isLoggedOut: boolean;
  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public promoter: TdctUserPromoterService,
    public event: ImpEventService,
    public table: ImpTableService,
    public option: ImpOptionService,
    public admin: TdctAdminService
  ) { }

  ngOnInit() {
    console.log(this.loggedOutTableOptions);
  }

}
