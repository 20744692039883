import { Task } from './tdct-task.model';
export const IdeaTask: Task = {
    id: '1',
    name: 'Idea Task',
    description: 'Strat Idea is a musical resume',
    receiverEmail: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    displayDueTimestamp: '2019-04-26',
    unixDueTimestamp: 1556325898,
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['idea'],
    complete: false
};
export const DemoTask: Task = {
    id: '2',
    name: 'Demo Task',
    description: 'Strat Demo is a instagram video list',
    receiverEmail: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    displayDueTimestamp: '2019-04-26',
    unixDueTimestamp: 1556325898,
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['demo'],
    complete: false
};
export const ProposalTask: Task = {
    id: '3',
    name: 'Proposal Task',
    description: 'Strat Proposal is funded by equity since my brother is broke',
    receiverEmail: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    displayDueTimestamp: '2019-04-26',
    unixDueTimestamp: 1556325898,
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['proposal'],
    complete: false
};
export const PackageTask: Task = {
    id: '4',
    name: 'Package Task',
    description: 'Strat will be packaged for the browser, apple, and google platforms',
    receiverEmail: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    displayDueTimestamp: '2019-04-26',
    unixDueTimestamp: 1556325898,
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['package'],
    complete: false
};
export const DocumentTask: Task = {
    id: '5',
    name: 'Document Task',
    description: 'Strat\'s documentation is on tdct.io',
    receiverEmail: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    displayDueTimestamp: '2019-04-26',
    unixDueTimestamp: 1556325898,
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['document'],
    complete: false
};
export const LaunchTask: Task = {
    id: '6',
    name: 'Launch Task',
    description: 'Strat\'s launch includes app store updates',
    receiverEmail: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    displayDueTimestamp: '2019-04-26',
    unixDueTimestamp: 1556325898,
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['launch'],
    complete: false
};
export const FeedbackTask: Task = {
    id: '7',
    name: 'Feedback Task',
    description: 'Strat\'s Feedbacks is that it needs to be more interactive',
    receiverEmail: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    displayDueTimestamp: '2019-04-26',
    unixDueTimestamp: 1556325898,
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['feedback'],
    complete: false
};
export const ResultTask: Task = {
    id: '8',
    name: 'Result Task',
    description: 'Strat\'s results will be measured in plays and conversions',
    receiverEmail: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    displayDueTimestamp: '2019-04-26',
    unixDueTimestamp: 1556325898,
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['result'],
    complete: false
};
export const BacklogTask: Task = {
    id: '9',
    name: 'Backlog Task',
    description: 'Strat\'s backlog of issue, information, feature, and problem requests drives releases',
    receiverEmail: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    displayDueTimestamp: '2019-04-26',
    unixDueTimestamp: 1556325898,
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['backlog'],
    complete: false
};
export const MockTasks: Task[] = [IdeaTask, DemoTask, ProposalTask, PackageTask, DocumentTask, LaunchTask, FeedbackTask, ResultTask, BacklogTask];
export const DefaultTask: Task = {
    id: '',
    name: '',
    description: '',
    receiverEmail: '',
    userId: '',
    userName: '',
    userEmail: '',
    userPhoto: '',
    displayTimestamp: '',
    displayLastUpdateTimestamp: '',
    displayDueTimestamp: '',
    unixDueTimestamp: 0,
    unixLastUpdateTimestamp: 0,
    unixTimestamp: 0,
    tags: [],
    complete: false
};
