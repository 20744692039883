import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { ImpCityService } from '../imp-city.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { ImpEventService } from '../../imp-event/imp-event.service';

@Component({
  selector: 'app-imp-city-list',
  templateUrl: './imp-city-list.component.html',
  styleUrls: ['./imp-city-list.component.scss'],
})
export class ImpCityListComponent implements OnInit {
  selectedTab: string = 'cities';
  cities: [];

  events: [];

  filteredEvents: Array<{ name: string, city: string }> = [];
  @Output() newSelectCity = new EventEmitter<void>();

  selectCity(city: { name: string }) {
    this.selectedTab = 'events';
    // this.filteredEvents = this.events.filter(event => event.city === city.name);
  }

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public city: ImpCityService,
    public admin: TdctAdminService,
    public event: ImpEventService
  ) { }

  ngOnInit() {
    this.city.load();
  }

  cityRouter(city: any) {
    this.newSelectCity.emit(city);
  }

  toggleEventView() {
    this.auth.viewingBottleOptionEditorForm = false;
    this.auth.viewingTableOptionEditorForm = false;
    this.auth.viewingTableBottleOptions = false;
    this.auth.viewingTableBottleOptionPopover = false;
    this.auth.viewingEventOptionBottleCreatorForm = false;
    this.auth.viewingTableOptionPopover = false;
    this.auth.viewingTicketOptionEditorForm = false;
    this.auth.viewingEventOptionPopover = false;
    this.auth.viewingEventOptionTicketForm = false;
    this.auth.viewingEventOptionTableForm = false;
    this.auth.viewingEventTicketOptions = false;
    this.auth.viewingEventTicketOptionPopover = false;
    this.auth.viewingEventTableOptions = false;
    this.auth.viewingEventTableOptionPopover = false;
    this.event.viewing = false;
  }


}
