import { Injectable } from '@angular/core';
import { Event } from './imp-event.model';
import { Table } from '../imp-table/imp-table.model';
import { Ticket } from '../imp-ticket/imp-ticket.model';
import { DefaultEvent, MockEvents } from './imp-event.mocks';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import * as moment from 'moment';
import { AngularFirestoreDocument, AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { first } from 'rxjs/operators';
import { TdctUserPromoterService } from '../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpCityService } from '../imp-city/imp-city.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from '../../../environments/environment';

const DURATION_EVENT_EXPIRATION: moment.Duration = moment.duration(0, 'seconds'); // Duration for event expiration
const DURATION_NON_FOLLOWER_WAIT: moment.Duration = moment.duration(environment.durations.durationNonFollowerWait); // Duration for non-follower wait time

@Injectable({
  providedIn: 'root'
})
export class ImpEventService {

  // Event properties
  $: Event = DefaultEvent;
  s$: Event[] = MockEvents;
  all$: Event[] = [];
  sHidden$: Event[] = [];
  search: string = '';
  count: number = 0;
  total: number = 0;
  viewing: boolean = false;
  loading: boolean = false;
  noneFound: boolean = false;
  editing: boolean = false;
  managing: boolean = false;
  sharing: boolean = false;
  doc: AngularFirestoreDocument<Event>;
  col: AngularFirestoreCollection<Event>;
  public theMoment: number;
  currentEvent: any; // Define the type based on your event structure
  currentEventCityName: string;
  currentEventCityState: string;
  showOldEvents: boolean = false; // Toggle to show old events

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    private cityService: ImpCityService,
    public promoter: TdctUserPromoterService,
    public afs: AngularFirestore,
    public aff: AngularFireFunctions,
  ) { }

  /**
   * Creates a new event or updates an existing event.
   */
async create() {
   try {
     this.loading = true;

     // If creating a new event, generate a new ID
     if (!this.auth.editingEvent) {
       // Add a check to prevent duplicate event creation within a short time frame
       const recentEvents = await this.afs.collection('events', ref => 
         ref.where('promoterId', '==', this.$.promoterId)
            .where('name', '==', this.$.name)
            .where('onlineSalesStartTimestamp', '==', this.$.onlineSalesStartTimestamp)
            .limit(1)
       ).get().toPromise();

       if (!recentEvents.empty) {
         console.warn('Potential duplicate event detected. Preventing creation.');
         throw new Error('Duplicate event detected');
       }

       this.$.id = this.afs.createId();
     }

     console.log('[imp-event.service].create: ' + this.$.id);
     console.log(this.$);

     // Stamp time and user details on the event
     this.stampTime();
     this.stampUser();

     // Convert ticket prices and other cost fields to numbers
     this.$.currentMaleTicketPrice = this.ensureValidNumber(this.$.currentMaleTicketPrice);
     this.$.currentFemaleTicketPrice = this.ensureValidNumber(this.$.currentFemaleTicketPrice);
     this.$.radioAds = this.ensureValidNumber(this.$.radioAds);
     this.$.TVAds = this.ensureValidNumber(this.$.TVAds);
     this.$.venueCosts = this.ensureValidNumber(this.$.venueCosts);
     this.$.hostCosts = this.ensureValidNumber(this.$.hostCosts);
     this.$.DJCosts = this.ensureValidNumber(this.$.DJCosts);
     this.$.otherExpenses = this.ensureValidNumber(this.$.otherExpenses);

     // Set the event's cities array equal to the current event's city id
     this.$.cities = [this.$.city];
    
    // Log detailed event creation information
    console.log('Event Creation Details:', {
      cityId: this.$.city,
      cityName: this.$.cityName,
      eventName: this.$.name,
      eventId: this.$.id
    });
    
    // Ensure city and city name are set correctly
    if (this.$.city) {
      this.$.cityName = this.$.city;
    }
    
    // If cityName is not set, try to get it from the city service
    if (!this.$.cityName && this.$.city) {
      try {
        const city = await this.cityService.getCityById(this.$.city);
        if (city) {
          console.log('City retrieved:', city);
          this.$.cityName = city.name;
        }
      } catch (error) {
        console.warn('Could not retrieve city name:', error);
      }
    }

    // Ensure consistent city information
    console.log('Final Event Details Before Save:', {
      cityId: this.$.city,
      cityName: this.$.cityName,
      cities: this.$.cities
    });

     // Save the event to Firestore
     await this.afs.doc('events/' + this.$.id).set(this.$);

     // Execute additional event creation tasks
     const eventCreationPromises = [];
     eventCreationPromises.push(this.get(this.$.id));
     // eventCreationPromises.push(this.logTicketTableEvent(this.$, 'event'));
     await Promise.all(eventCreationPromises);

     this.loading = false;
   } catch (error) {
     console.error('Error creating event:', error);
     throw error; // Re-throw to allow caller to handle the error
   }
}

/**
 * Ensures a valid number is returned, converting undefined or invalid numbers to 0.
 * @param value The value to check and convert.
 */
ensureValidNumber(value: any): number {
  return isNaN(Number(value)) ? 0 : Number(value);
}


  /**
   * Logs event data to Amplitude for analytics.
   * @param eventData The event data to log.
   * @param eventType The type of the event.

  async logTicketTableEvent(eventData: any, eventType: string) {
    console.log('Amplitude Test');
    const startTime = performance.now();

    const eventResult = {
      id: `${eventData.id}`,
      maleTicket: `${eventData.currentMaleTicketPrice}`,
      femaleTicket: `${eventData.currentFemaleTicketPrice}`,
      TVAds: `${eventData.currentMaleTicketPrice}`,
      radioAds: `${eventData.currentMaleTicketPrice}`,
      socialMediaAds: `${eventData.currentMaleTicketPrice}`,
      hostCosts: `${eventData.currentMaleTicketPrice}`,
      venueCosts: `${eventData.currentMaleTicketPrice}`,
      DJCosts: `${eventData.currentMaleTicketPrice}`,
      promoterId: `${eventData.promoterId}`,
      eventType: `${eventType}`
    };

    console.log('eventData', eventResult);

    const remoteFunction: any = this.aff.httpsCallable('logToAmplitude');
    const result: any = await remoteFunction(eventResult).toPromise();

    const endTime = performance.now();
    const runTime: number = endTime - startTime;
    console.log(`Result: '${JSON.stringify(result)}' produced in ${runTime}ms`);
  }   */

  /**
   * Stamps the current timestamp on the event.
   */
  stampTime(): void {
    this.$.unixTimestamp = moment().unix();
    this.$.unixLastUpdateTimestamp = this.$.unixTimestamp;
    this.$.displayTimestamp = moment().format('YYYY-MM-DD');
    this.$.displayLastUpdateTimestamp = this.$.displayTimestamp;
    this.$.displayStartTimestamp = moment.unix(this.$.unixStartTimestamp).format('dddd[,] MMMM D[,] YYYY [at] h:mm A');
    this.$.displayEndTimestamp = moment.unix(this.$.unixEndTimestamp).format('dddd[,] MMMM D[,] YYYY [at] h:mm A');
    this.$.onlineFollowerSalesStartTimestamp = moment().unix();
    this.$.onlineSalesStartTimestamp = moment().add(DURATION_NON_FOLLOWER_WAIT).unix();
    this.$.onlineSalesEndTimestamp = this.$.unixStartTimestamp;
    this.$.onlineEndTimestamp = this.$.unixEndTimestamp;
  }

  /**
   * Stamps the current user's information on the event.
   */
  stampUser(): void {
    this.$.userId = this.user.$.id;
    this.$.userName = this.user.$.name;
    this.$.userEmail = this.user.$.email;
    this.$.userPhoto = this.user.$.photo;
  }

  /**
   * Retrieves an event by ID from Firestore.
   * @param id The event ID.
   * @returns The event data as a Promise.
   */
  async get(id: string): Promise<Event> {
    const eventPath = 'events/' + id;
    this.doc = this.afs.doc<Event>(eventPath);
    return await this.doc.valueChanges().pipe(first()).toPromise();
  }

  /**
   * Sets the current event to be viewed or edited.
   * @param event The event to set as the current event.
   */
    set(event: Event): void {
        if (event) {
            this.$ = { ...event }; // Make a copy to avoid reference issues
            this.currentEvent = { ...event }; // Update currentEvent with a copy
            console.log('Setting current event:', {
                id: this.currentEvent.id,
                name: this.currentEvent.name
            });
        }
    }



/**
   * Loads events from Firestore based on the current filters.
   * @returns A list of events as a Promise.
   */
    async loadFeaturedEvents(): Promise<Event[]> {
        this.loading = true;
        this.s$ = [];

        try {
            const currentUnixTime = moment().unix(); // Get current timestamp

            let query;
                // Filter events by city ID and whether the event is old or upcoming
            query = this.afs.collection<Event>('events', ref => ref
                    .where('tags', 'array-contains', 'featured') // Check if the "tags" array contains the string "featured"
                    .where('onlineEndTimestamp', this.showOldEvents ? '<=' : '>', currentUnixTime)
                    .orderBy('onlineEndTimestamp', 'asc')); // Sort by end time

            // Fetch events from Firestore
            const querySnapshot = await query.get().toPromise();
            this.s$ = querySnapshot.docs.map(doc => doc.data() as Event);
            console.log('featured called', this.s$);
        } catch (error) {
            console.error('Error loading events:', error);
        }

        this.loading = false;
        return this.s$;
    }

  /**
   * Loads events from Firestore based on the current filters.
   * @returns A list of events as a Promise.
   */
    async loadCity(cityId?: string): Promise<Event[]> {
        this.loading = true;
        this.s$ = [];

        try {
            const currentUnixTime = moment().unix(); // Get current timestamp

            console.log(`Loading events with cityId: ${cityId}, showOldEvents: ${this.showOldEvents}`);

            let query;
            if (cityId) {
                // Filter events by city ID and whether the event is old or upcoming
                query = this.afs.collection<Event>('events', ref => ref
                    .where('cities', 'array-contains', cityId)
                    .where('onlineEndTimestamp', this.showOldEvents ? '<=' : '>', currentUnixTime)
                    .orderBy('onlineEndTimestamp', 'asc')); // Sort by end time
            } else {
                // If no cityId is provided, load all events
                query = this.afs.collection<Event>('events', ref => ref
                    .where('onlineEndTimestamp', this.showOldEvents ? '<=' : '>', currentUnixTime)
                    .orderBy('onlineEndTimestamp', 'asc'));
            }

            // Fetch events from Firestore
            const querySnapshot = await query.get().toPromise();
            this.s$ = querySnapshot.docs.map(doc => {
                const event = doc.data() as Event;
                event.id = doc.id; // Ensure event has its Firestore document ID
                return event;
            });

            // If we're editing an event, make sure currentEvent matches the event being edited
            if (this.auth.editingEvent && this.$.id) {
                this.currentEvent = this.s$.find(event => event.id === this.$.id) || null;
                if (!this.currentEvent) {
                    // If the event isn't in the current city's events, fetch it directly
                    this.currentEvent = await this.getEventById(this.$.id);
                }
                console.log('Current event being edited:', {
                    id: this.currentEvent?.id,
                    name: this.currentEvent?.name
                });
            }

            console.log(`Events found for city ${cityId}:`, this.s$.length);
            console.log('Event details:', this.s$.map(event => ({
                id: event.id,
                name: event.name,
                cities: event.cities,
                onlineEndTimestamp: event.onlineEndTimestamp
            })));

        } catch (error) {
            console.error('Error loading events:', error);
        }

        this.loading = false;
        return this.s$;
    }

 /**
  * Fetches the current event from Firestore based on the user's selection or default event.
  * Updates the `currentEvent` property with the most recent event data.
  * @returns A Promise that resolves with the current event data.
  */
 async fetchCurrentEvent(): Promise<Event | null> {
   try {
     this.loading = true;

     // Assuming there's logic to determine what the current event is (e.g., based on user's location, preferences)
     const eventId = this.currentEvent.id || this.$.id;  // Replace with your actual logic
     if (!eventId) {
       console.log('No event ID is set. Cannot fetch the current event.');
       this.loading = false;
       return null;
     }

     // Fetch the event by ID from Firestore
     const event = await this.getEventById(eventId); // Reuse the existing getEventById method
     if (event) {
       this.set(event);  // Update the currentEvent with the fetched data
       console.log('Current event fetched successfully:', event);
     } else {
       console.error('No event found for ID:', eventId);
     }

     this.loading = false;
     return event;
   } catch (error) {
     console.error('Error fetching current event:', error);
     this.loading = false;
     return null;
   }
 }


  async load(userId?: string): Promise<Event[]> {
    this.loading = true;
    this.theMoment = moment().unix();
    this.count = 0;
    this.total = 0;
    this.s$ = [];
    this.all$ = [];
    this.loading = true;

    await this.filter();

    if (!userId) {
        this.loading = false;
        return [];
    }

    try {
        const querySnapshot = await this.afs.collection('events', ref => ref.where('userId', '==', userId)).get().toPromise();
        this.s$ = querySnapshot.docs.map(doc => {
            const event = doc.data() as Event;
            event.id = doc.id; // Ensure event has its Firestore document ID
            return event;
        });

        if (this.s$.length > 0) {
            this.s$ = this.s$.map(event => this.auth.momentCreatedExactly(event));
            this.s$ = this.s$.map(event => this.auth.momentLastUpdatedFromNow(event));

            console.log('Events found:', this.s$);

            this.set(this.s$[0]);
            this.slowCount();
        } else {
            console.log('No events found for userId:', userId);
        }
    } catch (error) {
        console.error('Error loading events:', error);
    }

    this.loading = false;
    console.log('[imp-event.service].load - events loaded');
    return this.s$;
}

    async filter() {
        const currentUnixTime = moment().unix();  // Get the current Unix timestamp

        if (this.auth.viewingEventsFeatured) {
            this.col = await this.afs.collection<Event>('events', ref => ref
                .where('tags', 'array-contains', 'featured')
                .where('onlineEndTimestamp', this.showOldEvents ? '<=' : '>', currentUnixTime)  // Compare with current time
                .orderBy('onlineEndTimestamp', 'asc'));
        } else if (this.auth.viewingCityEvents) {
            this.col = await this.afs.collection<Event>('events', ref => ref
                .where('cities', 'array-contains', this.cityService.$.id)
                .where('onlineEndTimestamp', this.showOldEvents ? '<=' : '>', currentUnixTime)  // Compare with current time
                .orderBy('onlineEndTimestamp', 'asc'));
        } else if (this.auth.viewingPromoterEvents) {
            // Prioritize promoter ID from promoter service first
            const promoterId = this.promoter.$.id || this.user.$.userPromoterId;
            if (promoterId) {
                this.col = await this.afs.collection<Event>('events', ref => ref
                    .where('promoterId', '==', promoterId)
                    .where('onlineEndTimestamp', this.showOldEvents ? '<=' : '>', currentUnixTime)  // Compare with current time
                    .orderBy('onlineEndTimestamp', 'desc'));
            } else {
                console.warn('No promoter ID found for loading events');
                this.s$ = []; // Clear events if no promoter ID
            }
        } else {
            if (this.user.$.userPromoterId) {
                this.col = await this.afs.collection<Event>('events', ref => ref
                    .where('promoterId', '==', this.user.$.userPromoterId)
                    .where('onlineEndTimestamp', this.showOldEvents ? '<=' : '>', currentUnixTime)  // Compare with current time
                    .orderBy('onlineEndTimestamp', 'asc'));
            } else {
                this.col = await this.afs.collection<Event>('events', ref => ref
                    .where('onlineEndTimestamp', this.showOldEvents ? '<=' : '>', currentUnixTime)  // Compare with current time
                    .orderBy('onlineEndTimestamp', 'desc'));
            }
        }

        // Fetch the events based on the query
        const querySnapshot = await this.col.get().toPromise();
        this.s$ = querySnapshot.docs.map(doc => {
            const event = doc.data() as Event;
            event.id = doc.id; // Ensure event has its Firestore document ID
            return event;
        });

        let loadinEvents: Event[];
        if (this.all$.length === 0) {
            loadinEvents = await this.all$;
        } else {
            loadinEvents = this.all$.splice(0, 9);
        }
        this.s$ = [...this.s$, ...loadinEvents];
    }


  sanitize(): void {
  // Example of sanitizing the event data
  // Remove unwanted characters, trim whitespace, etc.
  if (this.$) {
  //  this.$.description = this.$.description?.trim() || '';
  //  this.$.title = this.$.title?.trim() || '';
    // Add more sanitization logic as needed
  }
}
async getEventById(id: string): Promise<Event | null> {
  const eventPath = `events/${id}`;
  this.doc = this.afs.doc<Event>(eventPath);
  return await this.doc.valueChanges().pipe(first()).toPromise();
}

  /**
   * Calculates the expiration timestamp for filtering old events.
   * @returns The UNIX timestamp of the event expiration.
   */
  since(): number {
    return moment().subtract(DURATION_EVENT_EXPIRATION).unix();
  }

  /**
   * Slowly counts the loaded events for display purposes.
   */
  slowCount(): void {
    if (this.count < this.s$.length) {
      setTimeout(() => {
        this.count++;
        this.slowCount();
      }, 30);
    }
  }
    /**
   * Loads tables associated with the current event ID.
   * @returns A list of tables as a Promise.
   */
  async loadEventTables(): Promise<any[]> {
    try {
      this.loading = true;
      const querySnapshot = await this.afs.collection('tables', ref => ref.where('eventId', '==', this.$.id)).get().toPromise();
      const tables = querySnapshot.docs.map(doc => doc.data());
      console.log('Tables found for event:', tables);
      this.loading = false;
      return tables;
    } catch (error) {
      console.error('Error loading tables:', error);
      this.loading = false;
      return [];
    }
  }

/**
 * Fetches the sold tickets for the current event and logs if "door" is true or false for each.
 * Orders the tickets by their last update timestamp in descending order.
 * @returns A Promise that resolves with the list of sold tickets.
 */
async loadEventTickets(): Promise<Ticket[]> {

  try {
    // Query the Firestore tickets collection based on the event ID and filters
    const ticketsQuery = this.afs.collection<Ticket>('tickets', ref =>
      ref.where('eventId', '==', this.$.id)  // Filter by event ID
         .orderBy('unixLastUpdateTimestamp', 'desc')  // Order by last update timestamp
    );

    // Execute the query and fetch the tickets
    const querySnapshot = await ticketsQuery.get().toPromise();

    // Map the result to an array of Ticket objects and log "door" status
    const soldTickets: Ticket[] = querySnapshot.docs.map(doc => {
      const ticket = doc.data() as Ticket;

      // Log the ticket ID and the "door" status
      console.log(`Ticket ID: ${ticket.id}, Door: ${ticket.door ? 'true' : 'false'}`);

      return ticket;
    });

    console.log('Sold tickets fetched successfully:', soldTickets);
    return soldTickets;
  } catch (error) {
    console.error('Error fetching sold tickets:', error);
    return [];
  }
}




  /**
   * Loads tickets associated with the current event ID.
   * @returns A list of tickets as a Promise.

  async loadEventTickets(): Promise<any[]> {
    try {
      this.loading = true;
      const querySnapshot = await this.afs.collection('tickets', ref => ref.where('eventId', '==', this.$.id)).get().toPromise();
      const tickets = querySnapshot.docs.map(doc => doc.data());
      console.log('Tickets found for event:', tickets);
      this.loading = false;
      return tickets;
    } catch (error) {
      console.error('Error loading tickets:', error);
      this.loading = false;
      return [];
    }
  }  */

  /**
   * Retrieve all events from Firestore
   * @returns Promise resolving to an array of all events
   */
  async getAllEvents(): Promise<Event[]> {
    try {
      const now = moment().unix();
      const eventsRef = this.afs.collection<Event>('events', ref => 
        this.showOldEvents 
          ? ref 
          : ref.where('unixEndTimestamp', '>=', now)
      );

      const snapshot = await eventsRef.get().toPromise();
      const events = snapshot.docs.map(doc => {
        const event = doc.data() as Event;
        event.id = doc.id;
        return event;
      });

      console.log('getAllEvents: Total events found:', events.length);
      return events;
    } catch (error) {
      console.error('Error fetching all events:', error);
      return [];
    }
  }
}
