import { Component, OnInit } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { TdctAskService } from '../tdct-ask.service';
import { Ask } from '../tdct-ask.model';
import { User } from '../../tdct-user/tdct-user.model';
import { ImpCityService } from '../../imp-city/imp-city.service';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { City } from '../../imp-city/imp-city.model';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-tdct-ask-option',
  templateUrl: './tdct-ask-option.component.html',
  styleUrls: ['./tdct-ask-option.component.scss'],
})
export class TdctAskOptionComponent implements OnInit {

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public promoter: TdctUserPromoterService,
    public ask: TdctAskService,
    public admin: TdctAdminService,
    public city: ImpCityService,
    private afs: AngularFirestore
  ) { }

  ngOnInit() { }

  async approveAsk() {
    const askApprovalPromises = [];
    const askingUser: User = await this.user.get(this.ask.$.userId);
    askApprovalPromises.push(this.confirmAsk());
    askApprovalPromises.push(this.updateUserPromoterAskApproval(askingUser));
    if (this.ask.$.cityId) {
      askApprovalPromises.push(this.updateCityPromoters());
    }
    askApprovalPromises.push(this.updateUserPromoterApprovalStatus());
    await Promise.all(askApprovalPromises);
    this.auth.viewingAskOptionPopover = false;
    this.admin.dismissOptionControl();
    await this.ask.load();
  }

  async confirmAsk(): Promise<void> {
    const newAskTags: Array<string> = [...this.ask.$.tags, 'confirmed'];
    const approvedAsk: Ask = {
      ...this.ask.$,
      tags: newAskTags,
      complete: true
    };
    await this.afs.doc(`asks/${this.ask.$.id}`).set(approvedAsk);
  }

  async updateUserPromoterAskApproval(user: User): Promise<void> {
    ++user.promoterProfileCount;
    --user.promoterRequestCount;
    user.pendingPromoterApproval = false;
    if (!user.roles.includes('promoter')) {
      user.roles.push('promoter');
    }
    await this.afs.doc(`users/${this.ask.$.userId}`).update(user);
  }

  async updateUserPromoterApprovalStatus(): Promise<void> {
    const promoterPath = `users/${this.ask.$.userId}/promoters/${this.ask.$.promoterId}`;
    await this.afs.doc(promoterPath).update({ approved: true });
  }

  async updateCityPromoters(): Promise<void> {
    const promoterPath = `users/${this.ask.$.userId}/promoters/${this.ask.$.promoterId}`;
    const promoterCity: City = await this.city.get(this.ask.$.cityId);
    ++promoterCity.promoterCount;
    await this.afs.doc(`cities/${this.ask.$.cityId}`).update(promoterCity);
  }

  async denyAsk() {
    const denyAskPromises = [];
    denyAskPromises.push(this.deleteAskDocument());
    const askingUser: User = await this.user.get(this.ask.$.userId);
    denyAskPromises.push(this.updateUserPromoterDeny(askingUser));
    await Promise.all(denyAskPromises);
    this.auth.viewingAskOptionPopover = false;
    this.admin.dismissOptionControl();
    await this.ask.load();
  }

  async deleteAskDocument(): Promise<void> {
    await this.afs.doc(`asks/${this.ask.$.id}`).delete();
  }

  async updateUserPromoterDeny(user: User): Promise<void> {
    --user.promoterRequestCount;
    user.pendingPromoterApproval = false;
    await this.afs.doc(`users/${this.ask.$.userId}`).update(user);
  }

  async demoteUserPromoter() {
    const userId = this.ask.$.userId; // Assuming userId is readily available in this.ask
    if (!userId) {
      console.error('User ID is missing.');
      return;
    }
    await this.demotePromoter(userId);
  }

  async demotePromoter(userId: string) {
    const demotePromoterPromises = [];
    const promoterPath = `users/${userId}/promoters/${this.ask.$.promoterId}`;
    demotePromoterPromises.push(this.deletePromoterDocument(promoterPath));
    const askingUser: User = await this.user.get(userId);
    this.removePromoterRole(askingUser);
    demotePromoterPromises.push(this.updateUserPromoterDemote(userId, askingUser));
    await Promise.all(demotePromoterPromises);
    this.auth.viewingAskOptionPopover = false;
    this.admin.dismissOptionControl();
    await this.ask.load();
  }

  async deletePromoterDocument(promoterPath: string): Promise<void> {
    await this.afs.doc(promoterPath).delete();
  }

  removePromoterRole(user: User): void {
    const index = user.roles.indexOf('promoter');
    if (index !== -1) {
      user.roles.splice(index, 1);
    }
  }

  async updateUserPromoterDemote(userId: string, user: User): Promise<void> {
    user.promoterProfileCount--;
    await this.afs.doc(`users/${userId}`).update(user);
  }
}
