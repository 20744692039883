import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import * as moment from 'moment';
import { Event } from './imp-event/imp-event.model';
import { environment } from '../../environments/environment';

const pollingIntervalMS = 1000;
const refreshThreshholdMS = 100000;

@Injectable({
  providedIn: 'root'
})
export class ImpTimingService {
  isInitialized: boolean = false;
  serverTime: moment.Moment;
  clientTime: moment.Moment;
  timeOffset: moment.Duration;
  interval: any;
  lastTime: number;

  constructor(
    public aff: AngularFireFunctions,
  ) {
    if (!this.isInitialized) {
      this.getTimes();
      this.isInitialized = true;
      // this.interval = setInterval(() => {
      //   const currentTime = performance.now();
      //   if (this.lastTime === undefined) {
      //     console.log(`Fetching Initial Server Time`);
      //     this.getTimes();
      //     this.isInitialized = true;
      //   } else if (currentTime - this.lastTime > refreshThreshholdMS) {
      //     this.isInitialized = false;
      //     console.log(`Drifted ${currentTime - this.lastTime}ms, refreshing Server Time`);
      //     this.getTimes();
      //     this.isInitialized = true;
      //   }
      //   this.lastTime = currentTime;
      // }, pollingIntervalMS);
    }
  }

  async getTimes() {
    const startTime = performance.now();
    const remoteFunction: any = this.aff.httpsCallable('getServerTime2');
    const result: any = JSON.parse(await remoteFunction(environment.version.appVersion).toPromise());
    const endTime = performance.now();
    const runTime: number = endTime - startTime;
    this.serverTime = moment(Date.parse(result.date));
    this.clientTime = moment(new Date());
    this.timeOffset = moment.duration(this.serverTime.diff(this.clientTime));
    console.log(`Server: ${this.serverTime}, Client: ${this.clientTime}, Offset: ${this.timeOffset.asSeconds()}s`);
    // console.log(`Client Time: ${this.clientTime}`);
    // // console.log(`Result: '${JSON.stringify(result)}' produced in ${runTime}ms`);
    // console.log(`Time Offset: ${this.timeOffset.asSeconds()}`);
  }


  public eventIsActive(event: Event, following: boolean): string {
    if (this.isInitialized) {
      this.clientTime = moment(new Date()).add(this.timeOffset);
      const expirationTime: moment.Moment = moment.unix(event.onlineSalesEndTimestamp);
      const startTime: moment.Moment = moment.unix(event.onlineSalesStartTimestamp);
      const followerStartTime: moment.Moment = moment.unix(event.onlineFollowerSalesStartTimestamp);
      // console.log('now:', moment(new Date()));
      // console.log('offset:', this.timeOffset);
      // console.log('this.clientTime:', this.clientTime);
      // console.log('expirationTime:', expirationTime);
      // console.log('startTime:', startTime);
      // console.log('clientTime before startTime:', this.clientTime.isBefore(startTime));
      if (this.clientTime.isAfter(expirationTime)) {
        return 'EXPIRED';
      } else if (this.clientTime.isAfter(following ? followerStartTime : startTime)) {
        // console.log(`Event ${event.id} expires in ${expirationTime.diff(this.clientTime)} seconds`);
        return 'ACTIVE';
      } else {
        // console.log(`Event ${event.id} starts in ${this.clientTime.diff(startTime)} seconds`);
        return 'PENDING';
      }
    } else {
      return 'UNINITIALIZED';
    }
  }
}
