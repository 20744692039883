import { Injectable } from '@angular/core';
import { AngularFirestoreCollectionGroup, AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { first } from 'rxjs/operators';
import { Option } from '../../imp-option/imp-option.model';
import { DefaultOption, MockOptions } from '../../imp-option/imp-option.mocks';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpEventService } from '../../imp-event/imp-event.service';
import { ImpOptionService } from '../../imp-option/imp-option.service';

@Injectable({
  providedIn: 'root'
})
export class ImpTableOptionBottleService {

  $: Option = DefaultOption;
  s$: Option[] = MockOptions;
  all$: Option[] = [];
  sHidden$: Option[] = [];
  search: string = '';
  count: number = 0;
  total: number = 0;
  bottlesIncludedCount: number = 0;
  viewing: boolean = false;
  loading: boolean = false;
  noneFound: boolean = false;
  editing: boolean = false;
  doc: AngularFirestoreDocument<Option>;
  optionGroup: AngularFirestoreCollectionGroup<Option>;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService,
    public promoter: TdctUserPromoterService,
    public event: ImpEventService,
    public option: ImpOptionService,
    public afs: AngularFirestore
  ) { }

  set(user: Option): void {
    this.$ = user;
    this.viewing = true;
    this.$ = this.auth.momentLastUpdatedFromNow(this.$);
    this.$ = this.auth.momentCreatedExactly(this.$);
  }

  async load(): Promise<Option[]> {
    this.loading = true;
    this.count = 0;
    this.total = 0;
    this.s$ = [];
    this.all$ = [];
    this.loading = true;
    this.optionGroup = await this.afs.collectionGroup<Option>('options', ref => ref
      .where('eventId', '==', this.event.$.id)
      .where('settings', 'array-contains', 'bottle')
      .orderBy('unixLastUpdateTimestamp', 'desc'));
    this.s$ = await this.optionGroup.valueChanges().pipe(first()).toPromise();
    if (this.s$.length > 0) {
      this.s$.map((option) => this.auth.momentLastUpdatedFromNow(option));
      this.set(this.s$[0]);
      this.total = this.s$.length;
    }
    this.loading = false;
    return this.s$;
  }

  slowCount() {
    this.all$ = this.s$;
    this.total = this.s$.length;
    if (this.s$.length === 1) {
      this.count = 1;
    } else {
      this.s$ = this.all$.splice(0, 3);
      const interval = setInterval(() => {
        this.count++;
        if (this.count >= this.total) {
          clearInterval(interval);
          this.count = this.total;
        }
      }, 100);
    }
  }

  showMore() {
    let loadinOptions: Option[] = [];
    if (this.all$.length === 1) {
      loadinOptions = this.all$.splice(0, 1);
    } else if (this.all$.length === 2) {
      loadinOptions = this.all$.splice(0, 2);
    } else { loadinOptions = this.all$.splice(0, 3); }
    this.s$.push(...loadinOptions);
  }

  sanitize(): void {
    this.s$ = [];
  }

  clear(): void {
    this.editing = false;
  }

  toggleEdit(): void {
    this.editing = !this.editing;
  }

  async loadIncluded() {
    await this.load();
    let bottlesIncluded = 0;
    this.s$.map((tableOptionBottle) => { bottlesIncluded = tableOptionBottle.bottlesIncluded + bottlesIncluded; });
    this.countBottlesIncluded(bottlesIncluded);
  }

  countBottlesIncluded(bottlesIncluded: number) {
    this.bottlesIncludedCount = 0;
    if (bottlesIncluded === 1) {
      this.bottlesIncludedCount = 1;
    } else {
      const interval = setInterval(() => {
        this.bottlesIncludedCount++;
        if (this.bottlesIncludedCount >= bottlesIncluded) {
          clearInterval(interval);
          this.bottlesIncludedCount = bottlesIncluded;
        }
      }, 100);
    }
  }
}
