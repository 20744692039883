export default {
    production: {
        apiKey: 'AIzaSyBAuggBG-rbsi08ds3cDAN0Ze07faH140M',
        authDomain: 'project-4002796994398242219.firebaseapp.com',
        databaseURL: 'https://project-4002796994398242219.fireabseio.com',
        projectId: 'project-4002796994398242219',
        storageBucket: 'project-4002796994398242219.appspot.com',
        messagingSenderId: '211810129519',

    },
      dev: {
        apiKey: 'AIzaSyBAuggBG-rbsi08ds3cDAN0Ze07faH140M',
        authDomain: 'project-4002796994398242219.firebaseapp.com',
        databaseURL: 'https://project-4002796994398242219.fireabseio.com',
        projectId: 'project-4002796994398242219',
        storageBucket: 'project-4002796994398242219.appspot.com',
        messagingSenderId: '211810129519',

    },
    /*
    dev: {
        apiKey: "AIzaSyAel-C2tHcyL3eWHxz8wQ0grDRprx0IBaE",
        authDomain: "imp-app-dev.firebaseapp.com",
        projectId: "imp-app-dev",
        databaseURL: 'https://imp-app-dev.fireabseio.com',
        storageBucket: "imp-app-dev.appspot.com",
        messagingSenderId: "255310736455",
        appId: "1:255310736455:web:e28a4427ae757fd717d562",
        measurementId: "G-7ENK10TM9Y"
    },
    */
    uat: {
        apiKey: 'AIzaSyDhIdMnlUSGtL2_fmadW0YuHj8kEsXPWck',
        authDomain: 'imp-app-uat.firebaseapp.com',
        projectId: 'imp-app-uat',
        databaseURL: 'https://imp-app-uat.fireabseio.com',
        storageBucket: 'imp-app-uat.appspot.com',
        messagingSenderId: '902682170564',
        appId: '1:902682170564:web:a4064d6360a9a8dc39a591',
        measurementId: 'G-EFXZ5WE4LK'
    }

};
