import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from '../../../environments/environment';
import { ImpTicketService } from '../imp-ticket/imp-ticket.service';
import { ImpEventService } from '../imp-event/imp-event.service';
import { ImpEventStripeElementsFormComponent } from '../imp-event/imp-event-stripe-elements-form/imp-event-stripe-elements-form.component';
import { ImpStripeCheckoutComponent } from '../imp-stripe-checkout/imp-stripe-checkout.component';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import { CheckoutReturnService } from './checkout-return-page.service';
import { Ticket } from '../imp-ticket/imp-ticket.model';
declare var Stripe;

@Component({
  selector: 'app-checkout-return-page',
  templateUrl: './checkout-return-page.component.html',
  styleUrls: ['./checkout-return-page.component.scss'],
})
export class CheckoutReturnPageComponent implements OnInit {
  sessionId: string = this.order.stripeSessionId;
  success: boolean = this.order.success;
  sessionObj: object = this.order.sessionObj;
  ticketObj: any = {...this.order.ticketObj};
  tableObj: any = {...this.order.tableObj};
  isTicket: boolean = this.order.isTicket;
  isFree: boolean = this.order.isFree;
  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    private route: ActivatedRoute,
    private afFun: AngularFireFunctions,
    public ticket: ImpTicketService,
    public order: CheckoutReturnService,
    private router: Router
  ) { }

  ngOnInit() {
    this.auth.loading = true;
    this.sessionObj = this.order.sessionObj;
    this.sessionId = this.order.stripeSessionId;
    this.success = this.order.success;
    if (this.isFree){
      this.order.setReturnPageLoaded(true);
    }
    if (this.sessionObj){
      this.order.setReturnPageLoaded(true);
    }
    console.log('tableobj.event: ', this.tableObj.eventImage);
  }



  routeToEvent() {
    // Check if the event ID is defined
    this.order.setReturnPageLoaded(false);
    if (this.ticketObj && this.ticketObj.eventId) {
      // Navigate to the event page with the ID from the ticket object
      this.router.navigate(['/event/redirectFromCheckout/', this.ticketObj.eventId]);
    } else if (this.tableObj && this.tableObj.eventId) {
      // Navigate to the event page with the ID from the table object
      this.router.navigate(['/event/redirectFromCheckout/', this.tableObj.eventId]);
    }
     else {
      // Handle the case where the event ID is undefined
      console.error('Error: eventID is undefined');
    }
  }

}
