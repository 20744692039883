import { Injectable } from '@angular/core';
import { Device } from './tdct-device.model';
import { DefaultDevice, MockDevices } from './tdct-device.mocks';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class TdctDeviceService {

  $: Device = DefaultDevice;
  s$: Device[] = MockDevices;
  count: number = 0;
  browser: boolean = false;
  apple: boolean = false;
  google: boolean = false;

  constructor(
    public platform: Platform
  ) {
    this.sync();
  }

  async sync() {
    this.sanitize();
    if (!this.platform.is('cordova')) {
      await this.setBrowserPlatform();
    } else if (this.platform.is('android')) {
      await this.setGooglePlatform();
    } else {
      await this.setApplePlatform();
    }
  }

  sanitize() {
    this.browser = false;
    this.apple = false;
    this.google = false;
  }

  async setBrowserPlatform() {
    this.$.platforms = ['browser'];
    this.browser = true;
    this.apple = false;
    this.google = false;
  }

  async setApplePlatform() {
    this.$.platforms = ['apple'];
    this.apple = true;
    this.google = false;
    this.browser = false;
  }

  async setGooglePlatform() {
    this.$.platforms = ['google'];
    this.google = true;
    this.apple = false;
    this.browser = false;
  }
}
