import { Injectable } from '@angular/core';
import { ImpTicketService } from '../imp-ticket/imp-ticket.service';
import { ImpTableService } from '../imp-table/imp-table.service';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { environment } from '../../../environments/environment';
declare var Stripe;

@Injectable({
    providedIn: 'root'
})
export class CheckoutReturnService {
    serviceRunning: boolean = false;
    stripeSessionId: string;
    success: boolean = false;
    sessionObj: object;
    ticketObj: object;
    tableObj: object;
    returnPageLoaded: boolean = false;
    isTicket: boolean;
    isFree: boolean;
    constructor(
        public auth: TdctAuthService,
        public user: TdctUserService,
        private afFun: AngularFireFunctions,
        public ticket: ImpTicketService,
        public table: ImpTableService,
    ) { }

    set(sessionId: string): void {
        this.serviceRunning = true;
        this.stripeSessionId = sessionId;
        this.serviceRunning = false;
        console.log(' inside set session id:', this.stripeSessionId);
    }
    setReturnPageLoaded(isLoaded: boolean){
        this.returnPageLoaded = isLoaded;
        return this.returnPageLoaded;
    }
    async update(){
        if (this.stripeSessionId.startsWith('freeTicket')){
          const ticketId = this.stripeSessionId.split('freeTicket')[1];
          return await this.updateFreeTicket(ticketId);
        }
        const stripe = Stripe(environment.stripe.stripePublishableKey);
        // let resultBool: boolean = false
        // await this.afFun.httpsCallable("stripeCheckoutConfirmStatus")({"sessionId": this.stripeSessionId})
        //   .subscribe(result => {
        //     console.log("result: ",{ result });
        //     if (result.ticket && result.ticket.amountPaid > 0){
        //       this.sessionObj = result.session;
        //       this.ticketObj = result.ticket;
        //       this.success = true;
        //       resultBool = true;
        //       this.auth.loading = true;
        //       ++this.user.$.ticketCount;
        //       this.auth.loading = false;
        //     }
        //   });
        // return resultBool;
        this.serviceRunning = true;
        try {
            this.auth.loading = true;
            const result = await this.afFun.httpsCallable('stripeCheckoutConfirmStatus')({sessionId: this.stripeSessionId}).toPromise();
            if (result.ticket && result.ticket.amountPaid > 0) {
              this.sessionObj = result.session;
              this.ticketObj = {...result.ticket};
              this.success = true;
              this.user.$.ticketCount += result.ticket.amountPurchased;
              this.isTicket = true;
              return true;
            } else if (result.table && result.table.amountPaid > 0) {
              this.sessionObj = result.session;
              this.tableObj = {...result.table};
              this.success = true;
              this.user.$.tableCount += result.table.amountPurchased;
              this.isTicket = false;
              return true;
            }else {
                this.serviceRunning = false;
                return false;
            }
          } catch (error) {
            console.error(error);
            this.serviceRunning = false;
            return false;
          }
      }
    async updateFreeTicket(ticketId){
      this.serviceRunning = true;
      console.log('inside update free ticket ----');
      console.log('ticketId: ', ticketId);
      this.auth.loading = true;
      const result = await this.afFun.httpsCallable('freeTicketConfirmStatus')({ticketId}).toPromise();
      if (result.ticket) {
          this.ticketObj = {...result.ticket};
          this.success = true;
          this.user.$.ticketCount += result.ticket.amountPurchased;
          this.isTicket = true;
          this.isFree = true;
          return true;
        }
    }
      load(): void {
    // Implement loading logic here, if any
    console.log('Checkout Return Service is loading...');
  }
}
