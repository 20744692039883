import { Injectable } from '@angular/core';
import { Post } from './tdct-post.model';
import { DefaultPost, MockPosts } from './tdct-post.mocks';

@Injectable({
  providedIn: 'root'
})
export class TdctPostService {

  $: Post = DefaultPost;
  s$: Post[] = MockPosts;
  count: number = 0;

  constructor() { }
}
