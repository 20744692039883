import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms'; // Import NgForm
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { AlertController } from '@ionic/angular'; // Import AlertController

@Component({
  selector: 'app-tdct-user-editor-form',
  templateUrl: './tdct-user-editor-form.component.html',
  styleUrls: ['./tdct-user-editor-form.component.scss'],
})

export class TdctUserEditorFormComponent implements OnInit {
  @ViewChild('userForm', { static: false }) userForm: NgForm; // Access form

  submitted: boolean = false;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService,
    private alertController: AlertController // Inject AlertController
  ) { }

  ngOnInit(): void {
    this.setProfile();
  }

  ngOnDestroy(): void {
    this.resetProfile();
  }

  setProfile(): void {
    if (!this.user.$.twitter) { this.user.$.twitter = 'twitter.com/'; }
    if (!this.user.$.snapchat) { this.user.$.snapchat = 'snapchat.com/add/'; }
    if (!this.user.$.instagram) { this.user.$.instagram = 'instagram.com/'; }
  }

  resetProfile(): void {
    if (this.user.$.twitter === 'twitter.com/') { this.user.$.twitter = ''; }
    if (this.user.$.snapchat === 'snapchat.com/add/') { this.user.$.snapchat = ''; }
    if (this.user.$.instagram === 'instagram.com/') { this.user.$.instagram = ''; }
  }

  submitFromBio(): void {
    this.user.$.bio = this.user.$.bio.slice(0, -1);
    this.submit();
  }

  async close(){
    if (this.userForm.invalid) {
      this.submitted = true; // Flag that the form has been submitted
      await this.showAlert(); // Show alert for missing fields
      return; // Prevent submission if form is invalid
    }

    this.resetProfile();

    await this.editUser();

    this.user.loading = false;
    this.user.editing = false;
  }

  async submit() {
    if (this.userForm.invalid) {
      this.submitted = true; // Flag that the form has been submitted
      await this.showAlert(); // Show alert for missing fields
      return; // Prevent submission if form is invalid
    }

    this.submitted = true;
    this.user.loading = true;
    this.resetProfile();

    await this.editUser();

    this.user.loading = false;
    this.user.editing = false;
  }

  async editUser() {
    try {
      await this.user.get(this.user.$.id);
      await this.user.doc.update({
        name: this.user.$.name || 'Unnamed User',
        bio: this.user.$.bio || '',
        twitter: this.user.$.twitter || '',
        instagram: this.user.$.instagram || '',
        snapchat: this.user.$.snapchat || '',
        dob: this.user.$.dob || '',      // Date of Birth field
        gender: this.user.$.gender || '' // Gender field
      });

      const currentUser = await this.auth.afa.currentUser;
      await this.user.get(currentUser.uid);
      await currentUser.updateProfile({
        displayName: this.user.$.name,
        photoURL: this.user.$.photo
      });
    } catch (error) {
      this.auth.presentErrorAlert(error);
    }
  }

  // Alert logic to notify user of missing fields
  async showAlert() {
    const missingFields = [];

    if (!this.user.$.dob) {
      missingFields.push('Date of Birth');
    }
    if (!this.user.$.gender) {
      missingFields.push('Gender');
    }

    if (missingFields.length > 0) {
      const alert = await this.alertController.create({
        header: 'Hold on...',
        message: `Please update the following fields: ${missingFields.join(', ')}`,
        buttons: ['OK, GOT IT'],
      });

      await alert.present();
    }
  }
}
