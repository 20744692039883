import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { AlertController } from '@ionic/angular';


@Component({
  selector: 'app-tdct-user-option',
  templateUrl: './tdct-user-option.component.html',
  styleUrls: ['./tdct-user-option.component.scss'],
})
export class TdctUserOptionComponent implements OnInit {

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService,
    public alert: AlertController
  ) { }

  ngOnInit() { }

  editUser(): void {
    this.user.editing = true;
    this.admin.dismissOptionControl();
    this.auth.viewingUserOptionPopover = false;
  }

  async deleteUser() {
    this.user.loading = true;
    await this.user.afs.doc('users/' + this.user.$.id).delete();
    --this.admin.digitalPoints;
    --this.admin.coreDigitalPoints;
    --this.admin.$.userCount;
    this.admin.dismissOptionControl();
    const alert = await this.alert.create({
      header: 'User Deleted',
      message: 'This user has been deleted.',
      buttons: ['OK']
    });
    await alert.present();
    this.auth.viewingUserOptionPopover = false;
    await this.user.load();
  }
}
