import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController, AlertController, LoadingController } from '@ionic/angular';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { ImpEventOptionComponent } from './../imp-event-option/imp-event-option.component';
import { ClipboardService } from 'ngx-clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImpEventService } from '../imp-event.service';
import { ImpOptionService } from '../../imp-option/imp-option.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import { ImpCityService } from '../../imp-city/imp-city.service';
import { IMPExternalAnalyticsManagerService } from '../../../services/impexternal-analytics-manager.service';

@Component({
  selector: 'app-imp-event-detail',
  templateUrl: './imp-event-detail.component.html',
  styleUrls: ['./imp-event-detail.component.scss'],
})

@NgModule({
  declarations: [
    ImpEventDetailComponent,
    // other components...
  ],
  imports: [
    CommonModule,
    // other modules...
  ],
  // other configurations...
})

export class ImpEventDetailComponent implements OnInit {
  @Input() event$: any; // The event data is passed as an input property
  get currentEvent() { return this.eventService.currentEvent || this.event$; }
  tables: any[] = []; // To store tables
  tickets: any[] = []; // To store tickets
  bottles: any[] = []; // To store bottles
  eventCity: string;
  eventState: string;
  currentUser: any;
  isAdminOrOwner: boolean = false;
  cityName: string | null = null;
  processingFee = 0.05;
  serviceFee = 0.0360;
  statusQuoFee = 1.20;     // $1.20
  constructor(
      private modalCtrl: ModalController,
      public auth: TdctAuthService,
      public popover: PopoverController,
      private clipboardService: ClipboardService,
      private eventService: ImpEventService, // Inject the event service
      private optionService: ImpOptionService, // Inject the option service
      private functions: AngularFireFunctions, // Inject AngularFireFunctions
      private alertCtrl: AlertController, // Inject AlertController
      private loadingCtrl: LoadingController,
      public afa: AngularFireAuth,
      private city: ImpCityService,
      private analytics: IMPExternalAnalyticsManagerService
  ) {}

  ngOnInit() {
    // Make sure we're using the event service's currentEvent
    if (this.event$ && this.event$.id) {
      this.eventService.set(this.event$);
    }
    this.logEventView();
    this.loadEventOptions(); // Load options on initialization
    this.getCurrentUser();
    this.getCityName();
  }

  private logEventView() {
    const event = this.eventService.currentEvent;
    if (event) {
      // Get user roles and info
      const userRoles = this.auth.user.$.roles || [];
      const isAdmin = userRoles.includes('admin');
      const isPromoter = userRoles.includes('promoter');
      const isSubscriber = userRoles.includes('subscriber');
      const isOwner = userRoles.includes('owner');

      this.analytics.logEvent('event_view', {
        event_id: event.id,
        event_name: event.name,
        event_type: event.type,
        event_venue: event.venue?.name || event.venue,
        event_city: this.cityName,
        event_state: event.state,
        event_start_time: event.displayStartTimestamp,
        has_tickets: this.tickets.length > 0,
        has_tables: this.tables.length > 0,
        has_bottles: this.bottles.length > 0,
        // User info
        user_id: this.auth.$.uid,
        user_email: this.auth.$.email,
        user_roles: userRoles.join(','),
        is_admin: isAdmin,
        is_promoter: isPromoter,
        is_subscriber: isSubscriber,
        is_owner: isOwner,
        is_premium: userRoles.includes('premium')
      });
    }
  }

  async getCityName(){
    const event = this.eventService.currentEvent;
    if (event?.city && event.city.length > 0) {
        const city = await this.city.get(event.city);
        if (city) {
            this.cityName = city.name;
        }
    }
}

  async getCurrentUser() {
    this.currentUser = await this.afa.currentUser;
    if (this.currentUser) {
      this.checkAdminOrOwner();
    }
  }

  checkAdminOrOwner() {
    const event = this.eventService.currentEvent;
    if (!event || !this.currentUser) { return; }

    const isOwner = event.userId === this.currentUser.uid;
    const isAdmin = this.currentUser.roles && this.currentUser.roles.includes('admin');

    this.isAdminOrOwner = isOwner || isAdmin;
  }

  // Method to close the modal
  close() {
    this.modalCtrl.dismiss();
  }

  // Method to log object properties to the console
  logObjectProperties(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        console.log(`${key}: ${obj[key]}`);
      }
    }
  }

  // Example usage: call this method to log event$ properties
  showEventDetails() {
    this.logObjectProperties(this.event$);
  }

  // This is loading all currently sold items. NOT event tickets available.
  async loadEventTablesAndTickets() {
    try {
      this.tables = await this.eventService.loadEventTables();
      this.tickets = await this.eventService.loadEventTickets();
    } catch (error) {
      console.error('Error loading tables and tickets:', error);
    }
  }

  // Method to load options and categorize them
  async loadEventOptions() {
    try {
      const options = await this.optionService.getOptionsForEvent(this.event$?.id);
      this.tickets = options.filter(option => option.settings.includes('ticket'));
      this.tables = options.filter(option => option.settings.includes('table'));
      this.bottles = options.filter(option => option.settings.includes('bottle'));
    } catch (error) {
      console.error('Error loading event options:', error);
    }
  }

  toggleEventOption(action: string) {
    this.auth.viewingEventOptionPopover = !this.auth.viewingEventOptionPopover;

    if (this.auth.viewingEventOptionPopover) {
        switch (action) {
            case 'manage': {
                this.event$.managing = true;
                this.event$.sharing = false;
                this.presentPopover();
                break;
            }
            case 'share': {
               this.event$.managing = false;
               this.event$.sharing = true;
               // Get user roles and info
               const userRoles = this.auth.user.$.roles || [];
               const isAdmin = userRoles.includes('admin');
               const isPromoter = userRoles.includes('promoter');
               const isSubscriber = userRoles.includes('subscriber');
               const isOwner = userRoles.includes('owner');

               // Log share event before showing share options
               this.analytics.logEvent('event_share', {
                event_id: this.event$.id,
                event_name: this.event$.name,
                event_type: this.event$.type,
                event_venue: this.event$.venue?.name || this.event$.venue,
                event_city: this.cityName,
                event_state: this.event$.state,
                // User info
                user_id: this.auth.$.uid,
                user_email: this.auth.$.email,
                user_roles: userRoles.join(','),
                is_admin: isAdmin,
                is_promoter: isPromoter,
                is_subscriber: isSubscriber,
                is_owner: isOwner,
                is_premium: userRoles.includes('premium')
               });
               this.copyToClipboard();
               break;
             }
            default: {
                this.event$.managing = false;
                this.event$.sharing = false;
            }
        }
    }
  }

  copyToClipboard() {
    const shareLink = `https://imp-events.com/event/${this.event$.id}`;
    this.clipboardService.copy(shareLink);
    console.log(this.event$.name);
    alert('Event copied to clipboard! Let�s make this night unforgettable! Share the excitement and rally your crew!');
  }

  async presentPopover() {
    const shareLink = this.event$.name;
    this.eventService.currentEvent = this.event$;
    console.log('popover for ' + shareLink);
    // Assuming `this.event$` holds the current event data
    this.auth.optionControl = await this.popover.create({
      component: ImpEventOptionComponent,  // The component to load in the popover
      showBackdrop: false,
      animated: true,
      componentProps: {
        eventData: this.event$  // Pass the current event data to the popover component
      }
    });

    this.auth.optionControl.onDidDismiss().then(() => {
      this.auth.viewingEventOptionPopover = false;
    });

    return await this.auth.optionControl.present();
  }

  // Method to handle image click
  viewImage(imageUrl: string) {
    console.log('Image clicked:', imageUrl);
    // Implement logic to open image in modal or navigate to detailed view
  }

  // Method to start the ticket purchase process
  startTicketPurchase(option: any) {
    this.openTicketQuantityPopup(option); // Call the method to open the quantity selection popup
  }

  startTablePurchase(option: any) {
    this.buyOption(option, 2, 1); // Call buyOption with the selected quantity

  }

// Method to open a quantity selection popup for tickets
async openTicketQuantityPopup(option: any) {
    // Validate the option object
    if (!option || typeof option.price === 'undefined') {
      console.error('Invalid ticket option passed to openTicketQuantityPopup:', option);
      window.alert('There was an issue with the selected ticket option. Please try again.');
      return;
    }

    const quantityAlert = await this.alertCtrl.create({
      header: 'Select Ticket Quantity',
      inputs: [
        {
          name: 'quantity',
          type: 'number',
          placeholder: 'Enter quantity',
          min: 1,
          max: 10,
          value: 1 // Default value
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'set-price-btn custom-btn secondary', // Reuse custom button style with additional 'secondary' class
          handler: () => {
            console.log('Purchase cancelled');
          }
        },
        {
          text: 'Buy',
          cssClass: 'set-price-btn custom-btn', // Reuse custom button style
          handler: (data) => {
            if (!data.quantity || isNaN(data.quantity) || data.quantity <= 0) {
              console.error('Invalid quantity selected:', data.quantity);
              window.alert('Please select a valid quantity.');
              return;
            }

            const quantity = data.quantity;
            const totalPrice = option.price * quantity;
            this.confirmTicketPurchase(option, quantity, totalPrice);
          }
        }
      ],
      cssClass: 'confirmation-alert door-price-alert' // Reuse custom alert class for consistency
    });

    await quantityAlert.present();
}


  // Method to confirm ticket purchase after quantity selection
  async confirmTicketPurchase(option: any, quantity: number, totalPrice: number) {
      // Validate the option object
      if (!option || typeof option.price === 'undefined') {
          console.error('Invalid ticket option passed to confirmTicketPurchase:', option);
          window.alert('There was an issue with the selected ticket option. Please try again.');
          return;
      }

      const confirmAlert = await this.alertCtrl.create({
          header: 'Confirm Purchase',
          message: `You are purchasing ${quantity} tickets for a total of $${totalPrice.toFixed(2)}. Do you want to proceed?`,
          buttons: [
              {
                  text: 'Cancel',
                  role: 'cancel',
                  cssClass: 'set-price-btn custom-btn secondary', // Apply custom button styling with secondary class for cancel button
                  handler: () => {
                      console.log('Purchase cancelled');
                  }
              },
              {
                  text: 'Buy',
                  cssClass: 'set-price-btn custom-btn', // Apply custom button styling for the Buy button
                  handler: () => {
                      this.buyOption(option, 1, quantity); // Call buyOption with the selected quantity
                  }
              }
          ],
          cssClass: 'confirmation-alert door-price-alert' // Apply custom styling for the entire alert
      });

      await confirmAlert.present();
  }


async buyOption(option: any, optionType: number, quantity: number = 1) {
    console.log('-------');
    console.log('Buying option:', option.optionId, 'Option Type:', optionType, 'Quantity:', quantity, 'Price:', option.price);
    console.log('-------');

    if (!option || typeof option.price === 'undefined') {
        console.error('Invalid option passed to buyOption:', option);
        window.alert('There was an issue with the selected option. Please try again.');
        return;
    }

    const loading = await this.loadingCtrl.create({
        message: 'Processing your purchase...',
        spinner: 'crescent',
        duration: 10000,
    });

    await loading.present();

    try {
        let response;

        if (optionType === 2) { // Tables
            const tablePrice = option.price || 0;
            option.optionId = option.id;
            const tableTotalPrice = tablePrice + this.calculateServiceFee(tablePrice) + this.calculateProcessingFee(tablePrice);
            const serviceFee = this.calculateServiceFee(tablePrice);
            const processingFee = this.calculateProcessingFee(tablePrice);
            const name = this.event$.name || 'Unknown Event';
            const additionalBottleCount = 0; // Default as bottles are removed
            const bottlePrice = 0; // Default since no bottle option
            const bottleName = 'N/A'; // Default as empty string
            const table = JSON.stringify(option); // Assuming `option` contains table details

            const cart = {
                tablePrice,
                totalPrice: tableTotalPrice,
                additionalBottleCount,
                name,
                serviceFee,
                processingFee,
                bottlePrice,
                bottleName,
                table,
            };

            console.log('Cart object for table checkout:', cart);

            response = await this.functions.httpsCallable('stripeCheckoutTableWithoutDbQueries')({ cart }).toPromise();

            console.log('Stripe session created:', response);
            window.location.href = response.session.url;
        } else if (optionType === 1) { // Tickets
            // Keep the existing ticket logic
            if (option.price === 0) {
                response = await this.functions.httpsCallable('checkoutFreeTicket')({
                    ticket: JSON.stringify(option),
                }).toPromise();

                console.log('Free ticket processed successfully:', response);
                this.showPurchaseSuccessAlert('Free Ticket');
            } else {
                response = await this.functions.httpsCallable('stripeCheckoutWithoutDbQueries')({
                    cart: {
                        quantity,
                        name: this.event$.name,
                        price: option.price,
                        serviceFee: this.calculateServiceFee(option.price * quantity),
                        processingFee: this.calculateProcessingFee(option.price * quantity),
                        ticket: JSON.stringify(option),
                    },
                }).toPromise();

                console.log('Stripe session created:', response);
                window.location.href = response.session.url;
            }
        }
    } catch (error) {
        console.error('Error during purchase:', error);
        window.alert('There was an issue processing your purchase. Please try again later.');
    } finally {
        await loading.dismiss();
    }
}

  // Utility methods to calculate fees
    calculateServiceFee(price: number): number {
        return Math.ceil((price * this.serviceFee + this.statusQuoFee) * 100) / 100;
  }

    calculateProcessingFee(price: number): number {
        return Math.ceil(price * this.processingFee * 100) / 100;
  }

 async showPurchaseSuccessAlert(decodedText: string) {
     const alert = await this.alertCtrl.create({
         header: 'QR Code Scanned Successfully',
         message: `Successfully scanned: ${decodedText}`,
         buttons: [
             {
                 text: 'CLOSE',
                 cssClass: 'set-price-btn custom-btn', // Reuse the custom class for button
                 role: 'cancel',
                 handler: () => {
                     console.log('QR Code scan confirmation closed');
                 }
             }
         ],
         cssClass: 'confirmation-alert door-price-alert' // Reuse the custom class for alert
     });

     await alert.present();

     // Dismiss the alert after 5 seconds if no interaction
     setTimeout(() => {
         alert.dismiss();
     }, 5000);
 }

}
