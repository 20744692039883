import { Component, OnInit, Input } from '@angular/core';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import { TdctUserService } from './tdct-user.service';
import { TdctAdminService } from '../tdct-admin/tdct-admin.service';
import { AlertController, PopoverController, ModalController } from '@ionic/angular';
import { TdctUserOptionComponent } from './tdct-user-option/tdct-user-option.component';
import { User } from './tdct-user.model';
import { DefaultUser } from './tdct-user.mocks';
import { TdctUserPromoterService } from './tdct-user-promoter/tdct-user-promoter.service';
import { Router } from '@angular/router';
import { ImpTicketService } from '../imp-ticket/imp-ticket.service';
import { ImpTableService } from '../imp-table/imp-table.service';
import { TdctDeviceService } from '../tdct-device/tdct-device.service';
import { ImpBottleService } from '../imp-bottle/imp-bottle.service';
import { ModalService } from '../imp-modal/imp-modal.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { ImpThemeService } from '../imp-theme/imp-theme.service';
import firebase from 'firebase';
import { InvestorCelebrationComponent } from '../modals/investor-celebration/investor-celebration.component';
import { AppComponent } from '../../app.component';

@Component({
  selector: 'app-tdct-user',
  templateUrl: './tdct-user.component.html',
  styleUrls: ['./tdct-user.component.scss'],
})
export class TdctUserComponent implements OnInit {

  @Input() user$: User = DefaultUser;

  premiumSubscriptionDetails = {
    plan: 'Premium Plan',
    monthlyRate: 9.99,
    status: 'Active',
    nextBillingDate: new Date()
  };

  authorizedUserSubscriber = false;
  authorizedUserAdmin = false;
  authorizedUserPromoter = false;
  authorizedUserCustomer = false;
  authorizedUserOwner = false;
  pendingUserPromoterApproval = false;
  adminPromoterProfilesLink = '';
  promoterProfilesLink = '';
  eventBriteConnectRegistrationLink = '';
  stripeConnectRegistrationLink = '';
  userDeleteRunning = false;
  passwordUpdateRunning = false;
  selectedTab = 'tickets';
  isStripeLoading = false;

  constructor(
    public aff: AngularFireFunctions,
    public auth: TdctAuthService,
    public user: TdctUserService,
    public device: TdctDeviceService,
    public admin: TdctAdminService,
    public ticket: ImpTicketService,
    public table: ImpTableService,
    public bottle: ImpBottleService,
    public promoter: TdctUserPromoterService,
    public popover: PopoverController,
    public router: Router,
    private modalService: ModalService,
    private alertCtrl: AlertController,
    public theme: ImpThemeService,
    private modalCtrl: ModalController,
    private appComponent: AppComponent,
  ) { }

  async ngOnInit() {
    this.loadUserTickets();
    // Load premium subscription details if user is premium
    if (this.user$.isPremium) {
      try {
        const details = await this.user.getPremiumSubscriptionDetails();
        if (details) {
          this.premiumSubscriptionDetails = details;
        }
      } catch (error) {
        console.error('Error loading premium details:', error);
      }
    }
  }

  ngOnDestroy(): void {
    this.authorizedUserSubscriber = false;
    this.authorizedUserAdmin = false;
    this.authorizedUserPromoter = false;
    this.authorizedUserOwner = false;
    console.log(this.user$.userPromoterId);
  }

  ngOnChanges(): void {
    this.authorizedUserCustomer = this.user$.roles.includes('customer');
    this.authorizedUserSubscriber = this.user$.roles.includes('subscriber');
    this.authorizedUserAdmin = this.user$.roles.includes('admin');
    this.authorizedUserPromoter = this.user$.roles.includes('promoter');
    this.authorizedUserOwner = this.user$.roles.includes('owner');
    this.adminPromoterProfilesLink = `/admin/manage/user/${this.user$.id}/promoter/profiles`;
    this.promoterProfilesLink = `/user/${this.user$.id}/promoter/profiles`;
    this.pendingUserPromoterApproval = this.user$.pendingPromoterApproval;

  }

  connectToStripe() {
    this.stripeConnectRegistrationLink = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_9mOVsH7UKKBQJFClkcTUF5C85bCxSLCy&scope=read_write&stripe_user[email]=${this.user$.email}&suggested_capabilities[]=transfers`;
    this.auth.open(this.stripeConnectRegistrationLink);
    }

    connectToEventbrite() {
        // Properly formatted redirect URI
        const redirectUri = encodeURIComponent('https://imp-events.com/account');

        // Eventbrite client ID
        const clientId = '7KBMAC6IFURU3OQ3YXJH';

        // Construct the authorization link
        this.eventBriteConnectRegistrationLink = `https://www.eventbrite.com/oauth/authorize?response_type=token&client_id=${clientId}&redirect_uri=${redirectUri}`;

        // Open the authorization URL
        this.auth.open(this.eventBriteConnectRegistrationLink);
    }


  toggleUserTickets(): void {
    this.auth.viewingUserTickets = !this.auth.viewingUserTickets;
    if (this.auth.viewingUserTickets) {
      this.user.set(this.user$);
      this.ticket.getTicketsByUserId(this.user$.id);
    }
  }

  loadUserTickets(): void {
      this.auth.viewingUserTickets = true;
      this.user.set(this.user$);
      this.ticket.getTicketsByUserId(this.user$.id);
  }

  async toggleUserTables() {
    this.auth.viewingUserTables = !this.auth.viewingUserTables;
    if (this.auth.viewingUserTables) {
      this.user.set(this.user$);
      await this.table.getTablesByUserId(this.user$.id);
      this.auth.viewingTableBottles = true;
      await this.bottle.load();
    }
  }

  toggleTheme() {
    this.theme.toggle();
  }

  toggleUserPromoterRegistrationForm(): void {
    this.auth.viewingUserPromoterCreatorForm = !this.auth.viewingUserPromoterCreatorForm;
  }

    toggleUserOption() {
        if (!this.auth.viewingUserOptionPopover) {
            // Open the popover only if not already open
            this.presentPopover();
        } else if (this.auth.optionControl) {
            // If already open, dismiss the popover
            this.dismissPopover();
        }
    }

    async presentPopover() {
        if (!this.auth.optionControl) {
            this.auth.optionControl = await this.popover.create({
                component: TdctUserOptionComponent,
                showBackdrop: false, // Disable the backdrop if it's not necessary
                animated: true,
                backdropDismiss: true // Allow dismissal by clicking the backdrop
            });

            // Present the popover and manage the state
            await this.auth.optionControl.present();
            this.auth.viewingUserOptionPopover = true;

            // Handle dismissal and clean up
            this.auth.optionControl.onDidDismiss().then(() => {
                this.auth.viewingUserOptionPopover = false;
                this.auth.optionControl = null; // Reset the control
                this.cleanupBackdrop(); // Manually ensure backdrop cleanup
            });
        }
    }
    togglePromoterCreatorForm(): void {
        this.auth.viewingUserPromoterCreatorForm = !this.auth.viewingUserPromoterCreatorForm;
    }
    async dismissPopover() {
        if (this.auth.optionControl) {
            await this.auth.optionControl.dismiss();
            this.cleanupBackdrop(); // Ensure backdrop class is removed
        }
    }

    // Manually clean up any remaining 'backdrop-noscroll' classes
    cleanupBackdrop() {
        const body = document.querySelector('body');
        if (body.classList.contains('backdrop-noscroll')) {
            body.classList.remove('backdrop-noscroll');
        }
    }


  toggleEdit(): void {
        this.user.editing = !this.user.editing;
  }

  toggleEmailForm(): void {
    this.auth.viewingUserEmailForm = !this.auth.viewingUserEmailForm;
  }

  openSupportLink() {
  window.open('https://imp-app.com/get-support/', '_blank');
}

  togglePasswordForm(): void {
    this.auth.viewingUserPasswordForm = !this.auth.viewingUserPasswordForm;
  }

  toggleCreatorForm(): void {
    this.auth.viewingUserCreatorForm = !this.auth.viewingUserCreatorForm;
  }

  async toggleUserPromoterView() {
    if (this.auth.uploadingUserPromoterImage) {
      this.auth.uploadingUserImage = false;
    }
    if (this.auth.viewingEventAnalytics) {
      this.auth.viewingEventAnalytics = false;
    } else {
      this.auth.viewingUserPromoter = !this.auth.viewingUserPromoter;
      if (this.auth.viewingUserPromoter) {
        this.promoter.load();
      } else {
        this.router.navigateByUrl('/user');
      }
    }
  }

  toggleUserImageChange() {
    this.auth.uploadingUserImage = !this.auth.uploadingUserImage;
  }

  openSocial(link: string) {
    console.log('Opening Social', link);
    const fullLink = `https://${link}`;
    this.auth.open(fullLink);
  }

  openModal(id: string) {
    this.modalService.open(id);
  }


 async updateEmail() {
  const result = await this.updateEmailAlert();

  if (result === 'success') {
    await this.successAlert('Email updated successfully!', 'Update Email');
  } else if (result === 'invalid') {
    await this.errorAlert('Please enter a valid email address.', 'Update Email');
  } else if (result === 'error') {
    await this.errorAlert('An unexpected error occurred while trying to update your email.', 'Update Email');
  }
}

private async updateEmailAlert(): Promise<string> {
  return new Promise<string>(async (resolve) => {
    // Retrieve the current user
    const user = firebase.auth().currentUser;

    // Handle cases where user might be null
    if (!user) {
      resolve('error');
      return;
    }

    // Retrieve the current email
    const currentEmail = user.email || 'unknown email';

    const alert = await this.alertCtrl.create({
      header: 'Update Email',
      message: `Your current email is ${currentEmail}. Please enter your new email address.`,
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: 'New Email',
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            resolve('cancel');
          }
        },
        {
          text: 'Confirm',
          handler: async (data) => {
            const email = data.email;
            if (!this.validEmail(email)) {
              resolve('invalid');
              return;
            }
            try {
              await user.updateEmail(email);
              resolve('success');
            } catch (error) {
              console.log('firebase error: ', error);
              resolve('error');
            }
          }
        }
      ]
    });

    await alert.present();
  });
}


private validEmail(email: string): boolean {
  // Basic email validation logic (can be enhanced as needed)
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

async initiatePremiumMembership() {
    await this.appComponent.initiatePremiumMembership();
}

  async deleteUserPrompt() {
      console.log('[tdct-user.component].deleteUserPrompt', this.user.$.id);
      const confirm = await this.deleteConfirmationAlert(`If you are sure you want to delete your account,\
      enter the word "DELETE" into the field below, then press "DELETE ACCOUNT".`);
      if (confirm) {
        console.log('user account delete confirmed!');
        const deleteFunction: any = this.aff.httpsCallable('deleteUser');
        this.userDeleteRunning = true;
        const result: any = await deleteFunction(this.user.$).toPromise();
        console.log(`Result: '${JSON.stringify(result)}`);
        this.userDeleteRunning = false;
        if (result === true) {
                this.router.navigateByUrl('/login');
        } else {
          await this.errorAlert('Something went wrong while attempting to delete\ ' +
              'your account.  Please contact customer service.', 'Delete User Account');
        }
      } else if (confirm === null) {
        console.log('user account delete aborted.');
        await this.errorAlert('Please try again if you wish to delete your account and\ ' +
            'make sure you follow the instructions provided.', 'Delete User Account');
      } else if (confirm === false) {
        await this.errorAlert('Please try again if you wish to delete your account.', 'Delete User Account');
      }
    }


    private async deleteConfirmationAlert(message: string): Promise<boolean> {
      let resolveFunction: (confirm: boolean) => void;
      const promise = new Promise<boolean>(resolve => {
        resolveFunction = resolve;
      });
      const alert = await this.alertCtrl.create({
        header: 'Delete User Account',
        message,
        backdropDismiss: true,
        inputs: [
          {
            name: 'deleteConfirmText',
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            handler: () => resolveFunction(false)
          },
          {
            text: 'Delete Account',
            handler: (data: any) => {
              if (String(data.deleteConfirmText).toUpperCase() === 'DELETE') {
                console.log('data:', data.deleteConfirmText);
                resolveFunction(true);
              } else {
                console.log('data:', data.deleteConfirmText);
                resolveFunction(null);
              }
            }
          }
        ]
      });
      await alert.present();
      return promise;
    }

  private async errorAlert(message: string, header: string) {
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: ['OK']
    });
    await alert.present();
  }

  private async successAlert(message: string, header: string) {
    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons: ['OK']
    });
    await alert.present();
  }

  async updatePassword() {
    const confirm = await this.updatePasswordAlert();
    if (confirm === 4) {
      await this.successAlert('Password updated successfully!', 'Update Password');
    } else if (confirm === 0) {
      await this.errorAlert('Please try again if you wish to update your password.', 'Update Password');
    } else if (confirm === 1) {
      await this.errorAlert('The new password did not meet the requirements. Passwords must be at least 6 characters long.', 'Update Password');
    } else if (confirm === 2) {
      await this.errorAlert('The new password did not match the confirmation. Please try again.', 'Update Password');
    } else if (confirm === 3) {
      await this.errorAlert('The new password is the same as your current password. Please try again.', 'Update Password');
    } else if (confirm === 5) {
      await this.errorAlert('An unexpected error occurred while trying to update your password.', 'Update Password');
    }
  }

  private async updatePasswordAlert(): Promise<number> {
    return new Promise<number>(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: 'Update Password',
        message: 'Please enter your new password and confirm it.',
        inputs: [
          {
            name: 'newPassword',
            type: 'password',
            placeholder: 'New Password'
          },
          {
            name: 'confirmPassword',
            type: 'password',
            placeholder: 'Confirm New Password'
          }
        ],
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              resolve(0);
            }
          },
          {
            text: 'Confirm',
            handler: async (data) => {
              if (data.newPassword.length < 6) {
                resolve(1);
                return;
              }
              if (data.newPassword !== data.confirmPassword) {
                resolve(2);
                return;
              }
              const user = firebase.auth().currentUser;
              if (!user) {
                resolve(5);
                return;
              }
              if (data.newPassword === 'CURRENT_PASSWORD') {
                resolve(3);
                return;
              }
              try {
                await user.updatePassword(data.newPassword);
                resolve(4);
              } catch (error) {
                console.log('firebase error: ', error);
                resolve(5);
              }
            }
          }
        ]
      });

      await alert.present();
    });
  }

  getSubscriptionStatusColor(status: string): string {
    switch (status) {
      case 'active':
        return 'success';
      case 'past_due':
        return 'warning';
      case 'canceled':
      case 'unpaid':
        return 'danger';
      default:
        return 'medium';
    }
  }

  getSubscriptionStatusIcon(status: string): string {
    switch (status) {
      case 'active':
        return 'star';
      case 'past_due':
        return 'alert-circle';
      case 'canceled':
        return 'close-circle';
      case 'unpaid':
        return 'warning';
      default:
        return 'help-circle';
    }
  }

  getSubscriptionStatusText(status: string): string {
    switch (status) {
      case 'active':
        return 'Premium Active';
      case 'past_due':
        return 'Payment Past Due';
      case 'canceled':
        return 'Subscription Canceled';
      case 'unpaid':
        return 'Payment Required';
      default:
        return 'Status Unknown';
    }
  }

  goToInvestorPage() {
    window.open('https://imp-app.com', '_blank');
  }

  openInvestorPage() {
    window.open('https://imp-app.com', '_blank');
  }

  async showInvestorCelebration() {
    const modal = await this.modalCtrl.create({
      component: InvestorCelebrationComponent,
      cssClass: 'investor-celebration-modal',
      backdropDismiss: true
    });
    await modal.present();
  }

  // Add methods for user deletion, profile editing, etc., if needed
}
