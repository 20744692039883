import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { ImpOptionService } from '../../imp-option/imp-option.service';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpEventService } from '../../imp-event/imp-event.service';
import { ImpTicketService } from '../imp-ticket.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { Option } from '../../imp-option/imp-option.model';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';


@Component({
  selector: 'app-imp-ticket-option-editor-form',
  templateUrl: './imp-ticket-option-editor-form.component.html',
  styleUrls: ['./imp-ticket-option-editor-form.component.scss'],
})
export class ImpTicketOptionEditorFormComponent implements OnInit {

  submitted: boolean = false;

  constructor(
    public auth: TdctAuthService,
    public promotor: TdctUserPromoterService,
    public event: ImpEventService,
    public ticket: ImpTicketService,
    public option: ImpOptionService,
    public admin: TdctAdminService,
    public afs: AngularFirestore
  ) { }

  ngOnInit() { }

  async submit(ticketOptionForm) {
    this.submitted = true;
    this.auth.loading = true;
    this.stamp(ticketOptionForm);
    await this.editOption();
    this.auth.viewingTicketOptionEditorForm = false;
    this.auth.loading = false;
  }

  stamp(ticketOptionForm: Option) {
    this.option.$.price = ticketOptionForm.price;
    this.option.$.name = ticketOptionForm.name || this.option.$.name;
    this.option.$.description = ticketOptionForm.description || this.option.$.description;
    this.option.$.description = this.option.$.description.replace(/(\r\n\t|\n|\r\t)/gm, '');
  }

  async editOption() {
    try {
      await this.afs.doc(`events/${this.option.$.eventId}/options/${this.option.$.id}`).update({
        price: this.option.$.price,
        name: this.option.$.name,
        image: this.option.$.image,
        description: this.option.$.description,
        unixLastUpdateTimestamp: moment().unix()
      });
    } catch (error) {
      this.auth.presentErrorAlert(error);
    }
  }

}
