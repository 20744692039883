import { Message } from './tdct-message.model';
export const ContactMessage: Message = {
    id: '1',
    name: 'Contact Message',
    description: 'I want to be your mentee',
    senderName: 'Jane',
    senderEmail: 'test-jane@tdct.io',
    senderPhoto: 'assets/img/default-profile.png',
    receiverId: '3',
    receiverName: 'Jim',
    receiverEmail: 'test-jim@tdct.io',
    receiverPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['contact']
};
export const SupportMessage: Message = {
    id: '2',
    name: 'Support Message',
    description: 'I need help as your mentee',
    senderName: 'Jane',
    senderEmail: 'test-jane@tdct.io',
    senderPhoto: 'assets/img/default-profile.png',
    receiverId: '3',
    receiverName: 'Jim',
    receiverEmail: 'test-jim@tdct.io',
    receiverPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['support']
};
export const AnnouncementMessage: Message = {
    id: '3',
    name: 'Announcement Message',
    description: 'I released another app!',
    senderName: 'Jane',
    senderEmail: 'test-jane@tdct.io',
    senderPhoto: 'assets/img/default-profile.png',
    receiverId: '3',
    receiverName: 'Jim',
    receiverEmail: 'test-jim@tdct.io',
    receiverPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['announcement']
};
export const MockMessages: Message[] = [ContactMessage, SupportMessage, AnnouncementMessage];
export const DefaultMessage: Message = {
    id: '',
    name: '',
    description: '',
    senderName: '',
    senderEmail: '',
    receiverName: '',
    receiverEmail: '',
    receiverPhoto: '',
    displayTimestamp: '',
    displayLastUpdateTimestamp: '',
    unixLastUpdateTimestamp: 0,
    unixTimestamp: 0,
    tags: []
};
