import { Component, OnInit } from '@angular/core';
import { Auth } from '../tdct-auth.model';
import { DefaultAuth } from '../tdct-auth.mocks';
import { TdctAuthService } from '../tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { NgForm } from '@angular/forms';
import { ModalService } from '../../imp-modal/imp-modal.service';
import { ImpEmitterService } from '../../imp-emitter.service';
import { User } from '../../tdct-user/tdct-user.model';

import moment from 'moment';

@Component({
  selector: 'app-tdct-auth-signup-form',
  templateUrl: './tdct-auth-signup-form.component.html',
  styleUrls: ['./tdct-auth-signup-form.component.scss'],
})
export class TdctAuthSignupFormComponent implements OnInit {

  signupData: Auth = DefaultAuth;
  submitted: boolean = false;
  response: boolean;
  subscription: any;
  isPopupVisible: boolean = true;

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    private modalService: ModalService,
    private eventService: ImpEmitterService
  ) { }

  ngOnInit() {
    this.auth.$ = DefaultAuth;
    this.subscription = this.eventService.ServiceTermsReviewed.subscribe(response => this.termsResponse(response, null));
  }

  async submit(signupForm: NgForm) {
    this.submitted = true;
    if (signupForm.valid) {
      this.auth.loading = true;
      await this.signup(signupForm.value);
      this.clearForm(signupForm);
    }
  }

  // async signup(signupFormData: Auth) {
  //   this.auth.viewingSignupForm = true;
  //   try {
  //     this.user.$.name = signupFormData.displayName;
  //     this.user.$.photo = 'https://firebasestorage.googleapis.com/v0/b/tdct-production.appspot.com/o/default-profile.png?alt=media&token=556ff22b-26bf-465d-b50b-73457f2c073d';
  //     await this.auth.afa.createUserWithEmailAndPassword(signupFormData.email, signupFormData.password);
  //     this.auth.successMessage = 'Signup successful!';
  //     this.auth.errorMessage = '';
  //   } catch (error) {
  //     this.auth.presentErrorAlert(error);
  //     this.auth.errorMessage = error.message;
  //     this.auth.successMessage = '';
  //   } finally {
  //     this.auth.loading = false;
  //   }
  // }
  
  async signup(signupFormData: Auth) {
    this.auth.viewingSignupForm = true;
    try {
      // Set user properties
      this.user.$.name = signupFormData.displayName;
      this.user.$.photo = 'https://firebasestorage.googleapis.com/v0/b/tdct-production.appspot.com/o/default-profile.png?alt=media&token=556ff22b-26bf-465d-b50b-73457f2c073d';
  
      // Create the user
      const userCredential = await this.auth.afa.createUserWithEmailAndPassword(signupFormData.email, signupFormData.password);
      const user = userCredential.user;
  
      // Sign in the user immediately after creation
      if (user) {
        await this.auth.afa.signInWithEmailAndPassword(signupFormData.email, signupFormData.password);
  
        // Create user object to save in Firestore
        const newUser: User = {
          id: user.uid,
          email: user.email,
          name: signupFormData.displayName,
          photo: this.user.$.photo,
          displayTimestamp: moment().format('YYYY-MM-DD'),
          displayLastUpdateTimestamp: moment().format('YYYY-MM-DD'),
          unixLastUpdateTimestamp: moment().unix(),
          unixTimestamp: moment().unix(),
          roles: ['subscriber'], // Default role
          dob: '', // Date of birth if applicable
          isPremium: false,
          uid: user.uid // Store the uid for reference
        };
  
        // Save user data to Firestore
        this.user.doc = this.auth.afs.doc<User>(`users/${newUser.id}`);
        await this.user.doc.set(newUser);
      }
  
      this.auth.successMessage = 'Signup successful!';
      this.auth.errorMessage = '';
    } catch (error) {
      this.auth.presentErrorAlert(error);
      this.auth.errorMessage = error.message;
      this.auth.successMessage = '';
    } finally {
      this.auth.loading = false;
    }
  }

  clearForm(signupForm: NgForm) {
    signupForm.resetForm();
    this.submitted = false;
  }

  public openModal(id: string) {
    this.modalService.open(id);
  }

  private termsResponse(response: boolean, signupForm: NgForm) {
    this.response = response;
    if (response === true && signupForm) {
      this.submit(signupForm);
    } else {
      window.alert('The terms must be accepted to continue with registration.');
    }
  }
}
