import { Promoter } from './tdct-user-promoter.model';
export const ChicagoPromoter: Promoter = {
    id: '1',
    name: 'Chicago Promoter',
    bio: 'I promote the best events in the Chicago area',
    email: 'chicago-promoter@tdct.io',
    photo: 'assets/img/default-profile.png',
    link: 'https://imp-events.com/promoter/1',
    route: 'promoter/1',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: 'a few hours ago',
    displayLastUpdateTimestamp: 'a few hours ago',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    paidMaleWristbandsScanned: 0,
    paidFemaleWristbandsScanned: 0,
    totalWristbandsScanned: 0,
    ticketDoorRevenue: 0,
    ticketOrderRevenue: 0,
    tableOrderRevenue: 0,
    bottleOrderRevenue: 0,
    totalOrderRevenue: 0,
    totalRevenue: 0,
    eventCount: 1,
    followerCount: 2,
    followers: [],
    tags: ['active'],
    cities: ['2'],
    approved: false
};
export const DetroitPromoter: Promoter = {
    id: '2',
    name: 'Detroit Promoter',
    bio: 'I promote the best events in the Detroit area',
    email: 'detroit-promoter@tdct.io',
    photo: 'assets/img/default-profile.png',
    link: 'https://imp-events.com/promoter/1',
    route: 'promoter/2',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: 'a few hours ago',
    displayLastUpdateTimestamp: 'a few hours ago',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    paidMaleWristbandsScanned: 0,
    paidFemaleWristbandsScanned: 0,
    totalWristbandsScanned: 0,
    ticketDoorRevenue: 0,
    ticketOrderRevenue: 0,
    tableOrderRevenue: 0,
    bottleOrderRevenue: 0,
    totalOrderRevenue: 0,
    totalRevenue: 0,
    eventCount: 1,
    followerCount: 2,
    followers: [],
    tags: ['active'],
    cities: ['1'],
    approved: false
};
export const MockPromoters: Promoter[] = [ChicagoPromoter, DetroitPromoter];
export const DefaultPromoter: Promoter = {
    id: '',
    name: '',
    bio: '',
    email: '',
    photo: '',
    link: '',
    route: '',
    userId: '',
    userName: '',
    userEmail: '',
    userPhoto: '',
    displayTimestamp: '',
    displayLastUpdateTimestamp: '',
    unixLastUpdateTimestamp: 0,
    unixTimestamp: 0,
    paidMaleWristbandsScanned: 0,
    paidFemaleWristbandsScanned: 0,
    totalWristbandsScanned: 0,
    ticketDoorRevenue: 0,
    ticketOrderRevenue: 0,
    tableOrderRevenue: 0,
    bottleOrderRevenue: 0,
    totalOrderRevenue: 0,
    totalRevenue: 0,
    eventCount: 0,
    followerCount: 0,
    followers: [],
    tags: [],
    cities: [],
    approved: false
};
