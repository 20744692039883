import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { ImpOptionService } from '../../imp-option/imp-option.service';


@Component({
  selector: 'app-imp-ticket-option-option',
  templateUrl: './imp-ticket-option-option.component.html',
  styleUrls: ['./imp-ticket-option-option.component.scss'],
})
export class ImpTicketOptionOptionComponent implements OnInit {

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public option: ImpOptionService,
    public admin: TdctAdminService
  ) { }

  ngOnInit() { }

  editOption(): void {
    this.auth.viewingTicketOptionEditorForm = !this.auth.viewingTicketOptionEditorForm;
    if (this.auth.viewingTicketOptionEditorForm) {
      this.admin.dismissOptionControl();
      this.auth.viewingEventTicketOptionPopover = false;
    }
  }

}
