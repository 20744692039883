import { Component, OnInit, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { TdctAdminService } from '../tdct-admin/tdct-admin.service';
import { ImpEventService } from './imp-event.service';
import { DefaultEvent } from './imp-event.mocks';
import { Event } from './imp-event.model';
import { ModalController, PopoverController } from '@ionic/angular';
import { ImpEventOptionComponent } from './imp-event-option/imp-event-option.component';
import { ImpOptionService } from '../imp-option/imp-option.service';
import { TdctUserPromoterService } from '../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { AngularFirestore, AngularFirestoreCollectionGroup } from '@angular/fire/firestore';
import { environment } from '../../../environments/environment';
import { AngularFireFunctions } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/auth';
import moment from 'moment';
import { ImpEventDetailComponent } from './imp-event-detail/imp-event-detail.component';
import { ImpCityService } from '../imp-city/imp-city.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'app-imp-event',
    templateUrl: './imp-event.component.html',
    styleUrls: ['./imp-event.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpEventComponent implements OnInit, OnChanges {
    eventID = '';
    @Input() event$: Event = DefaultEvent;
    cityName: string | null = null;
    cityState: string | null = null;

    isOwner: boolean = false;
    eventHidden: boolean = false;
    scannerRoute: string = '';
    elementType = 'img';
    maleQRCode: string = '';
    femaleQRCode: string = '';
    viewingMaleQRCode: boolean = false;
    viewingFemaleQRCode: boolean = false;
    eventCol: AngularFirestoreCollectionGroup<Event>;
    isTable: boolean = false;
    @Input() loggedOutTableOptions: any[] = [];
    @Input() loggedOutTicketOptions: any[] = [];
    @Input() isLoggedOut: boolean = false;

    private optionsSubject = new Subject<void>();
    private cityCache = new Map<string, { name: string; state: string }>();

    constructor(
        public promoter: TdctUserPromoterService,
        public event: ImpEventService,
        public auth: TdctAuthService,
        public user: TdctUserService,
        public admin: TdctAdminService,
        public option: ImpOptionService,
        public popover: PopoverController,
        public afs: AngularFirestore,
        private afFun: AngularFireFunctions,
        private afAuth: AngularFireAuth,
        public modalController: ModalController,
        private city: ImpCityService
    ) { }

    ngOnInit() {
        this.optionsSubject.pipe(debounceTime(300)).subscribe(() => {
            this.getOptionsCount();
        });
        this.eventID = this.event$.id;
        this.isTable = this.auth.viewingEventTableOptions;
        this.auth.viewingEventTicketOptions = false;
        this.getCityName();
    }

    async ngOnChanges(): Promise<any> {
        if (this.eventID !== this.event$.id) {
            this.eventID = this.event$.id;
            this.markHiddenEvent();
            this.scannerRoute = `/scanner/promoter/${this.event$.promoterId}/event/${this.event$.id}`;
            this.maleQRCode = `https://imp-events.com/promoter/${this.event$.promoterId}/event/${this.event$.id}/m`;
            this.femaleQRCode = `https://imp-events.com/promoter/${this.event$.promoterId}/event/${this.event$.id}/f`;
            this.optionsSubject.next(); // Debounced API call
        }
    }

    async getCityName() {
        if (this.event$.city && this.event$.city.length > 0) {
            if (this.cityCache.has(this.event$.city)) {
                const cachedCity = this.cityCache.get(this.event$.city);
                this.cityName = cachedCity?.name || null;
                this.cityState = cachedCity?.state || null;
            } else {
                const city = await this.city.get(this.event$.city);
                if (city) {
                    this.cityCache.set(this.event$.city, { name: city.name, state: city.state });
                    this.cityName = city.name;
                    this.cityState = city.state;
                }
            }
        }
    }

    async getOptionsCount() {
        console.log('===== in getOptionsCount =====');
        console.log(this.event$.id);
        this.afFun
            .httpsCallable('getEventOptions')({ eventId: this.event$.id })
            .subscribe(
                async (result) => {
                    if (result) {
                        this.event$.ticketOptionCount = result.ticketCount;
                        this.event$.tableOptionCount = result.tableCount;
                        this.afAuth.authState.subscribe((user) => {
                            if (user) {
                                console.log('User is logged in:', user.uid);
                            } else {
                                this.isLoggedOut = true;
                                this.loggedOutTableOptions = result.tableOptions;
                                this.loggedOutTicketOptions = result.ticketOptions;
                            }
                        });
                    }
                },
                (error) => {
                    console.error('Error in getOptionsCount:', error);
                }
            );
    }

    markHiddenEvent(): void {
        this.eventHidden = this.event.sHidden$.includes(this.event$);
    }

    toggleUserPromoterEventEditorForm() {
        this.auth.editingEvent = true;
        this.auth.viewingUserPromoterEventCreatorForm = !this.auth.viewingUserPromoterEventCreatorForm;
    }

    async toggleEventHide() {
        if (!this.event.sHidden$.includes(this.event$)) {
            this.event.sHidden$.push(this.event$);
        } else {
            this.event.sHidden$ = this.event.sHidden$.filter(event => event !== this.event$);
        }
        this.markHiddenEvent();
        if (!this.eventHidden) {
            await this.reloadEvent();
        }
    }

    async reloadEvent() {
        this.event.loading = true;
        this.event$ = await this.event.get(this.event$.id);
        this.event$ = this.auth.momentLastUpdatedFromNow(this.event$);
        this.event.loading = false;
    }

    toggleEventTicketOptions(): void {
        this.auth.viewingEventTicketOptions = !this.auth.viewingEventTicketOptions;
        if (this.auth.viewingEventTicketOptions) {
            this.option.load();
        } else {
            this.auth.viewingEventOptionTicketForm = false;
            this.auth.viewingTicketOptionEditorForm = false;
        }
    }

    toggleEventTableOptions(): void {
        this.auth.viewingEventTableOptions = !this.auth.viewingEventTableOptions;
        if (this.auth.viewingEventTableOptions) {
            this.option.load();
        } else {
            this.auth.viewingEventOptionTableForm = false;
            this.auth.viewingTableOptionEditorForm = false;
        }
    }

    toggleMaleQRCode() {
        this.viewingMaleQRCode = !this.viewingMaleQRCode;
    }

    toggleFemaleQRCode() {
        this.viewingFemaleQRCode = !this.viewingFemaleQRCode;
    }

    formatDate(date: string): string {
        const inputFormat = 'dddd, MMMM D, YYYY [at] h:mm A';
        const parsedDate = moment(date, inputFormat);
        return parsedDate.format('MMM D, YYYY, h:mm a');
    }

    async openEventDetailsModal(event$) {
        const modal = await this.modalController.create({
            component: ImpEventDetailComponent,
            componentProps: {
                event$,
            },
        });
        return await modal.present();
    }
}
