import { Upload } from './tdct-upload.model';
export const ImageUpload: Upload = {
    id: '1',
    name: 'Image Upload',
    description: 'This is an image upload',
    link: 'https://firebasestorage.googleapis.com/v0/b/troydcthompson-production.appspot.com/o/uploads%2Fimages%2FunXvtp17onULGat2Hkq7vOzW2Zs1%2F1540011788165_facebook-photo.jpg?alt=media&token=510419b8-da60-4c60-a234-a6f8067bc1e2',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['image'],
    path: 'uploads/images/1/1540011788165_facebook-photo.jpg',
    ext: 'jpg',
};
export const AudioUpload: Upload = {
    id: '2',
    name: 'Audio Upload',
    description: 'This is an audio upload',
    link: 'https://firebasestorage.googleapis.com/v0/b/troydcthompson-production.appspot.com/o/uploads%2Faudio%2Fdistributor.mp3?alt=media&token=bee8724e-a35d-49d0-a349-749b7b5e1a8c',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['audio'],
    path: 'uploads/audio/1/distributor.mp3',
    ext: 'mp3',
};
export const VideoUpload: Upload = {
    id: '3',
    name: 'Video Upload',
    description: 'This is a youtube video upload',
    link: 'https://youtu.be/xnFXUUCde6Q',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['video'],
};
export const PDFUpload: Upload = {
    id: '4',
    name: 'PDF Upload',
    description: 'This is a portable document file upload',
    link: 'https://firebasestorage.googleapis.com/v0/b/troydcthompson-production.appspot.com/o/uploads%2Fdocuments%2Ftroydcthompson-resume.pdf?alt=media&token=6bc2fbc9-59b5-46e7-8423-730f82c063ca',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['pdf'],
    path: 'uploads/documents/1/troydcthompson-resume.pdf',
    ext: 'pdf'
};
export const MockUploads: Upload[] = [ImageUpload, AudioUpload, VideoUpload, PDFUpload];
export const DefaultUpload: Upload = {
    id: '',
    name: '',
    description: '',
    link: '',
    userId: '',
    userName: '',
    userEmail: '',
    userPhoto: '',
    displayTimestamp: '',
    displayLastUpdateTimestamp: '',
    unixLastUpdateTimestamp: 0,
    unixTimestamp: 0,
    tags: [],
};
