interface IAPConfig {
    subscriptionIds: {
        premium: string;
    };
}

interface Environment {
    production: boolean;
    appVersion: string;
    buildNumber: string;
    iap: IAPConfig;
}

export default {
    production: {
        appVersion: '2.1.5 (9)',
        buildNumber: '9',
        production: true,
        iap: {
            subscriptionIds: {
                premium: 'IMP_IOS_PREMIUM_PROMOTER'
            }
        }
    } as Environment,
    dev: {
        appVersion: '2.1.5 (9)',
        buildNumber: '9',
        production: false,
        iap: {
            subscriptionIds: {
                premium: 'IMP_IOS_PREMIUM_PROMOTER'
            }
        }
    } as Environment,
    uat: {
        appVersion: '2.1.5 (9)',
        buildNumber: '9',
        production: false,
        iap: {
            subscriptionIds: {
                premium: 'IMP_IOS_PREMIUM_PROMOTER'
            }
        }
    } as Environment
};