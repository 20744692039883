import { Injectable } from '@angular/core';
import { Ticket } from './imp-ticket.model';
import { DefaultTicket, MockTickets } from './imp-ticket.mocks';
import { TdctAuthService } from '../tdct-auth/tdct-auth.service';
import * as moment from 'moment';
import { AngularFirestoreDocument, AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { TdctUserService } from '../tdct-user/tdct-user.service';
import { first } from 'rxjs/operators';
import { ImpOptionService } from '../imp-option/imp-option.service';
import { ImpEventService } from '../imp-event/imp-event.service';

@Injectable({
  providedIn: 'root'
})
export class ImpTicketService {

  $: Ticket = DefaultTicket;
  s$: Ticket[] = MockTickets;
  all$: Ticket[] = [];
  sHidden$: Ticket[] = [];
  search: string = '';
  count: number = 0;
  total: number = 0;
  viewing: boolean = false;
  loading: boolean = false;
  noneFound: boolean = false;
  editing: boolean = false;
  managing: boolean = false;
  sharing: boolean = false;
  doc: AngularFirestoreDocument<Ticket>;
  col: AngularFirestoreCollection<Ticket>;
    processingFee: number = 0.05;
    serviceFee: number = 0.0360;
    statusQuoFee: number = 1.20;     // $1.20
  constructor(
      public auth: TdctAuthService,
      public user: TdctUserService,
      public option: ImpOptionService,
      public event: ImpEventService,
      public afs: AngularFirestore
  ) { }

  async get(id: string): Promise<Ticket> {
    const ticketPath = 'tickets/' + id;
    this.doc = this.afs.doc<Ticket>(ticketPath);
    const res = await this.doc.valueChanges().pipe(first()).toPromise();
    return res;
  }

  set(ticket: Ticket): void {
    this.$ = ticket;
    this.viewing = true;
    this.$ = this.auth.momentCreatedExactly(this.$);
    this.$ = this.auth.momentLastUpdatedFromNow(this.$);
  }

    async load(): Promise<Ticket[]> {
        this.loading = true;
        this.count = 0;
        this.total = 0;
        this.s$ = [];
        this.all$ = [];

        // Ensure filter was successful before proceeding
        await this.filter();
        if (!this.col) {
            console.error('Collection reference (this.col) is not set, cannot load tickets.');
            this.loading = false;
            return [];
        }

        try {
            // Ensure that the collection reference is valid before calling valueChanges
            this.s$ = await this.col.valueChanges().pipe(first()).toPromise();
            console.log('Tickets loaded:', this.s$);
        } catch (error) {
            console.error('Error loading tickets:', error);
            this.loading = false;
            return [];
        }

        this.loading = false;
        return this.s$;
    }


    async filter() {
        try {
            // Check if viewingUserTickets or viewingEventAnalytics conditions apply
            if (this.auth.viewingUserTickets) {
                console.log('Viewing user tickets');
                this.col = this.afs.collection<Ticket>('tickets', ref =>
                    ref.where('userId', '==', this.user.$.id)
                        .orderBy('unixLastUpdateTimestamp', 'desc')
                );
            } else if (this.auth.viewingEventAnalytics) {
                console.log('Viewing event analytics');
                this.col = this.afs.collection<Ticket>('tickets', ref =>
                    ref.where('eventId', '==', this.event.$.id)
                        .where('door', '==', true)
                        .orderBy('unixLastUpdateTimestamp', 'desc')
                );
            } else {
                // Ensure the event is set correctly for the new logic
                if (!this.event.$ || !this.event.$.id) {
                    console.error('Event is not properly set before filtering tickets:', this.event.$);
                    return;
                }

                const eventId = this.event.$.id;  // Get the current event ID
                console.log('Filtering tickets for event:', eventId);

                // Apply your new filter logic for eventId
                this.col = this.afs.collection<Ticket>('tickets', ref =>
                    ref.where('eventId', '==', eventId)
                        .orderBy('unixLastUpdateTimestamp', 'desc')
                );
            }

            if (!this.col) {
                console.error('Collection reference (this.col) is not set, cannot load tickets.');
                return;
            }

            // Load the tickets for the current scenario
            this.s$ = await this.col.valueChanges().pipe(first()).toPromise();
            console.log('Tickets loaded:', this.s$);
        } catch (error) {
            console.error('Error loading tickets for event:', error);
        }
    }

      async getTicketsByUserId(userId: string): Promise<Ticket[]> {
        this.loading = true;
        this.noneFound = false;
        this.s$ = [];

        try {
          // Firestore query to get tickets by userId
          const userTickets = this.afs.collection<Ticket>('tickets', ref =>
            ref.where('userId', '==', userId)
               .orderBy('unixLastUpdateTimestamp', 'desc')
          );

          // Fetch the tickets and assign them to s$
          this.s$ = await userTickets.valueChanges().pipe(first()).toPromise();

          // Check if no tickets are found
          if (this.s$.length === 0) {
            this.noneFound = true;
          }

        } catch (error) {
          console.error('Error loading tickets for user:', error);
        } finally {
          this.loading = false;  // Ensure loading is set to false regardless of success or failure
        }

        return this.s$;  // Return the loaded tickets (or empty array if none found)
      }

  slowCount() {
    this.all$ = this.s$;
    this.total = this.s$.length;
    if (this.s$.length === 1) {
      this.count = 1;
    } else {
      this.s$ = this.all$.splice(0, 3);
      const interval = setInterval(() => {
        this.count++;
        if (this.count >= this.total) {
          clearInterval(interval);
          this.count = this.total;
        }
      }, 100);
    }
  }

  showMore() {
    let loadinTickets: Ticket[] = [];
    if (this.all$.length === 1) {
      loadinTickets = this.all$.splice(0, 1);
    } else if (this.all$.length === 2) {
      loadinTickets = this.all$.splice(0, 2);
    } else { loadinTickets = this.all$.splice(0, 3); }
    this.s$.push(...loadinTickets);
  }

  sanitize(): void {
    this.s$ = [];
  }

  clear(): void {
    this.editing = false;
  }

  toggleView(): void {
    this.clear();
    this.viewing = !this.viewing;
    if (this.viewing) {
      this.loading = true;
      this.set(this.$);
      this.loading = false;
    } else {
      this.loading = false;
    }
  }

  toggleEdit(): void {
    this.editing = !this.editing;
    }

    async create(source: any) {
        console.log('[imp-ticket.service].create: ' + JSON.stringify(source));

        // Create ticket ID
        this.$.id = this.afs.createId();

        if (source.id === 'door') {
            this.$.door = true;
            this.$.price = source.price;  // Ensure price comes from source
            this.$.gender = source.gender;
            console.log('Gender after assignment in door block:', this.$.gender);
            this.$.name = source.name;
            this.$.amountPurchased = source.amountPurchased;
            this.$.amountPaid = source.amountPaid;
            this.$.description = source.description;
            this.$.eventId = this.event.$.id;
            this.$.birthDate = source.birthDate;
            this.$.zipcode = ['', undefined].includes(source.zipcode) ? undefined : source.zipcode.toString().padStart(5, '0');
        } else {
            this.$.door = false;
            this.$.price = source.price;  // Use source.price instead of this.option.$.price
            this.$.gender = source.gender === 'm' ? 'male' : 'female';
            console.log('Gender after assignment in else block:', this.$.gender);
            this.$.name = `${this.option.$.name}`;
            this.$.optionId = this.option.$.id;
            if (this.option.$.ticketsAvailable !== undefined) {
                this.option.$.ticketsAvailable -= this.$.amountPurchased;
            }
        }

        // Log gender and price before stamping event options
        console.log('Final gender before stamping event options:', this.$.gender);
        console.log('Final price before stamping event options:', this.$.price);

        // Add final check for price
        if (typeof this.$.price !== 'number' || isNaN(this.$.price)) {
            console.error('Invalid price before saving to Firestore:', this.$.price);
            throw new Error(`Invalid price detected: ${this.$.price}`);
        }

        this.stampUser();
        this.stampPromoter();
        this.stampTime();
        this.stampEventOption(); // Gender should be passed and available here

        // Log ticket data before saving
        console.log('Creating ticket with the following details:', JSON.stringify(this.$));

        return await this.afs.doc(`tickets/${this.$.id}`).set(this.$);
    }

  async createFromCheckout() {
    console.log('[imp-ticket.service].createfromCheckout session: ');
    this.$.id = this.afs.createId();
    {
      this.$.price = this.option.$.price;
      this.$.name = `${this.option.$.name}`;
      this.$.optionId = this.option.$.id;
      // decrement tickets from local event ticket option variable
      if (this.option.$.ticketsAvailable !== undefined) {
        this.option.$.ticketsAvailable -= this.$.amountPurchased;
      }
    }
    if (this.$.amountPurchased === 1 && !this.$.door === true) {
      this.$.description = `Ticket to ${this.event.$.name}`;
    } else {
      this.$.description = `${this.$.amountPurchased} Tickets to ${this.event.$.name}.`;
    }
    this.$.link = `https://imp-events.com/ticket/${this.$.id}`;
    this.$.route = `ticket/${this.$.id}`;
    this.stampUser();
    this.stampPromoter();
    this.stampTime();
    this.stampEventOption();
    console.log('creating ticket');
    console.log('ticket', JSON.stringify(this.$));
    return JSON.stringify(this.$);
  }

  stampTime() {
    this.$.displayTimestamp = moment().format('YYYY-MM-DD');
    this.$.displayLastUpdateTimestamp = this.$.displayTimestamp;
    this.$.unixTimestamp = moment().unix();
    this.$.unixLastUpdateTimestamp = this.$.unixTimestamp;
  }

  stampUser() {
    if (this.$.door === true) {
      this.$.userEmail = '';
      this.$.userName = '';
      this.$.userPhoto = '';
    } else {
      this.$.userEmail = this.user.$.email;
      this.$.userName = this.user.$.name;
      this.$.userPhoto = this.user.$.photo;
    }
    this.$.userId = this.user.$.id;
    }
    stampEventOption() {
        console.log('stampEventOption called');

        // Log gender before handling it
        console.log('Gender value before adding to tags:', this.$.gender);

        // Ensure the gender is assigned and valid
        if (this.$.gender && (this.$.gender === 'm' || this.$.gender === 'f')) {
            const genderTag = this.$.gender === 'm' ? 'male' : 'female';

            // Initialize the tags array if it's undefined
            if (!Array.isArray(this.$.tags)) {
                this.$.tags = [];
            }

            // Remove any existing 'male' or 'female' tags
            this.$.tags = this.$.tags.filter(tag => tag !== 'male' && tag !== 'female');

            // Add the new gender tag
            this.$.tags.push(genderTag);
            console.log(`Gender tag added: ${genderTag}`);
        } else {
            console.log('Gender is not defined or invalid');
        }

        // Continue with other event-related stamps
        this.$.eventId = this.event.$.id;
        this.$.eventName = this.event.$.name;
        this.$.eventImage = this.event.$.image;
        this.$.eventDescription = this.event.$.description;
        this.$.displayStartTimestamp = this.event.$.displayStartTimestamp;
        this.$.displayEndTimestamp = this.event.$.displayEndTimestamp;
        this.$.unixStartTimestamp = this.event.$.unixStartTimestamp;
        this.$.unixEndTimestamp = this.event.$.unixEndTimestamp;
        this.$.street = this.event.$.street;
        this.$.city = this.event.$.city;
        this.$.state = this.event.$.state;
        this.$.country = this.event.$.country;

        console.log('Final tags array:', this.$.tags);
    }


  stampPromoter() {
    if (!this.$.door === true) {
      this.$.promoterId = this.event.$.promoterId;
      this.$.promoterEmail = this.event.$.promoterEmail;
      this.$.promoterName = this.event.$.promoterName;
      this.$.promoterPhoto = this.event.$.promoterPhoto;
      this.$.promoterBio = this.event.$.promoterBio;
    }
  }
}
