import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { ImpThemeService } from '../../imp-theme/imp-theme.service';
import { ImpBottleService } from '../imp-bottle.service';


@Component({
  selector: 'app-imp-bottle-controller',
  templateUrl: './imp-bottle-controller.component.html',
  styleUrls: ['./imp-bottle-controller.component.scss'],
})
export class ImpBottleControllerComponent implements OnInit {

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService,
    public theme: ImpThemeService,
    public bottle: ImpBottleService
  ) { }

  ngOnInit() { }

  toggleBottleView() {
    this.bottle.viewing = !this.bottle.viewing;
  }

}
