import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AngularFireFunctions } from '@angular/fire/functions';
declare let store: any;

@Injectable({
  providedIn: 'root'
})
export class InAppPurchaseService {
  private _isReady = new BehaviorSubject<boolean>(false);
  private _products = new BehaviorSubject<any[]>([]);
  private _purchases = new BehaviorSubject<any[]>([]);
  private _hasActiveSubscription = new BehaviorSubject<boolean>(false);

  constructor(
    private platform: Platform,
    private afFun: AngularFireFunctions
  ) {
    console.log('[IAP] Service constructor');
    this.platform.ready().then(() => {
      console.log('[IAP] Platform ready, initializing store...');
      this.initializeStore();
    });
  }

  get isReady(): Observable<boolean> {
    return this._isReady.asObservable();
  }

  get products(): Observable<any[]> {
    return this._products.asObservable();
  }

  get purchases(): Observable<any[]> {
    return this._purchases.asObservable();
  }

  get hasActiveSubscription(): Observable<boolean> {
    return this._hasActiveSubscription.asObservable();
  }

  private initializeStore() {
    console.error(' 🚨 IAP SERVICE: initializeStore CALLED');
    
    // Extremely verbose platform and store initialization logging
    console.error(' 🚨 STORE INITIALIZATION DIAGNOSTICS');
    console.error(' 🚨 Platform Platforms:', this.platform.platforms());
    console.error(' 🚨 Is iOS:', this.platform.is('ios'));
    
    // Comprehensive device and environment logging
    try {
      console.error(' 🚨 DEVICE ENVIRONMENT DETAILS:');
      console.error(' 🚨 User Agent:', navigator.userAgent);
      console.error(' 🚨 Is Simulator:', this.isSimulator());
    } catch (e) {
      console.error(' 🚨 Error getting device details:', e);
    }
    
    if (!this.platform.is('ios')) {
      console.error(' 🚨 NOT iOS PLATFORM - SKIPPING STORE INITIALIZATION');
      this._isReady.next(true);
      return;
    }

    if (!store) {
      console.error(' 🚨 STORE OBJECT NOT FOUND');
      this._isReady.next(false);
      return;
    }

    try {
      // Maximum verbosity for debugging
      store.verbosity = store.DEBUG;
      
      // Comprehensive error handling with detailed logging
      store.error((error: any) => {
        console.error(' 🚨 STORE ERROR DETAILS:');
        console.error(' 🚨 Full Error Object:', JSON.stringify(error));
        console.error(' 🚨 Error Code:', error.code);
        console.error(' 🚨 Error Domain:', error.domain);
        console.error(' 🚨 Error Message:', error.message);
        console.error(' 🚨 Error Localized Description:', error.localizedDescription);
        
        // Additional diagnostic information
        this.logStoreKitDiagnostics();
      });

      // Register products
      const subscriptionId = environment.iap.subscriptionIds.premium;
      console.error(` 🚨 Registering Product: ${subscriptionId}`);
      
      store.register({
        id: subscriptionId,
        type: store.PAID_SUBSCRIPTION,
        alias: 'Premium Subscription'
      });

      // Comprehensive product and store state tracking
      store.when("product").updated((product: any) => {
        console.error(' 🚨 PRODUCT UPDATED:');
        console.error(' 🚨 Product ID:', product.id);
        console.error(' 🚨 Product State:', product.state);
        console.error(' 🚨 Product Valid:', product.valid);
        console.error(' 🚨 Product Price:', product.price);
      });

      // Initialize the store with additional error handling
      console.error(' 🚨 Calling store.refresh()');
      store.refresh();

    } catch (error) {
      console.error(' 🚨 STORE INITIALIZATION CRITICAL ERROR:');
      console.error(' 🚨 Error Name:', error.name);
      console.error(' 🚨 Error Message:', error.message);
      console.error(' 🚨 Error Stack:', error.stack);
      
      this.logStoreKitDiagnostics();
      this._isReady.next(false);
    }
  }

  // New method to log comprehensive StoreKit diagnostics
  private logStoreKitDiagnostics() {
    try {
      console.error(' 🚨 STOREKIT COMPREHENSIVE DIAGNOSTICS:');
      
      // Check if running in simulator
      const isSimulator = this.isSimulator();
      console.error(` 🚨 Running in Simulator: ${isSimulator}`);

      // Validate product registration
      const subscriptionId = environment.iap.subscriptionIds.premium;
      const product = store.get(subscriptionId);
      
      console.error(' 🚨 Product Registration Check:');
      console.error(` 🚨 Product Registered: ${!!product}`);
      
      if (product) {
        console.error(' 🚨 Product Details:');
        console.error(' 🚨 ID:', product.id);
        console.error(' 🚨 State:', product.state);
        console.error(' 🚨 Price:', product.price);
      }

      // Additional StoreKit configuration validation
      console.error(' 🚨 Store Configuration:');
      console.error(' 🚨 Store Initialized:', !!store);
      console.error(' 🚨 Store Verbosity:', store?.verbosity);
      
    } catch (e) {
      console.error(' 🚨 Error in StoreKit Diagnostics:', e);
    }
  }

  // Helper method to detect simulator
  private isSimulator(): boolean {
    try {
      return navigator.userAgent.toLowerCase().includes('simulator') || 
             navigator.platform.toLowerCase().includes('simulator');
    } catch (e) {
      console.error(' 🚨 Error detecting simulator:', e);
      return false;
    }
  }

  private async handleApprovedPurchase(purchase: any) {
    try {
      await this.verifySubscription(purchase);
      purchase.finish();
      this._hasActiveSubscription.next(true);
      this._purchases.next([...this._purchases.value, purchase]);
    } catch (error) {
      console.error('Error handling approved purchase:', error);
      purchase.finish();
      this._hasActiveSubscription.next(false);
    }
  }

  private handleExpiredSubscription(purchase: any) {
    this._hasActiveSubscription.next(false);
    this._purchases.next(this._purchases.value.filter(p => p.id !== purchase.id));
  }

  private handleCancelledSubscription(purchase: any) {
    this._hasActiveSubscription.next(false);
    this._purchases.next(this._purchases.value.filter(p => p.id !== purchase.id));
  }

  private handleStoreError(error: any) {
    // Notify any error handlers
    this._isReady.next(true); // Set ready to true even on error to prevent hanging
  }

  async purchaseSubscription(productId: string): Promise<boolean> {
    console.error(' 🚨 IAP SERVICE: purchaseSubscription CALLED');
    console.error(' 🚨 Product ID:', productId);

    // Detailed platform logging
    console.error(' 🚨 IAP Platform Diagnostics:');
    try {
      console.error(' 🚨 Platform Platforms:', this.platform.platforms());
      console.error(' 🚨 Platform Is iOS:', this.platform.is('ios'));
      console.error(' 🚨 Platform Is iPhone:', this.platform.is('iphone'));
      console.error(' 🚨 Platform Is iPad:', this.platform.is('ipad'));
    } catch (e) {
      console.error(' 🚨 Platform Check Error:', e);
    }

    if (!this.platform.is('ios')) {
      console.error(' 🚨 NOT iOS PLATFORM - ABORTING IAP');
      return Promise.resolve(false);
    }

    if (!store) {
      console.error(' 🚨 STORE NOT INITIALIZED');
      return Promise.resolve(false);
    }

    return new Promise((resolve, reject) => {
      try {
        const product = store.get(productId);
        
        console.error(' 🚨 Product Lookup:');
        console.error(' 🚨 Product Found:', !!product);
        
        if (!product) {
          console.error(` 🚨 PRODUCT NOT FOUND: ${productId}`);
          return resolve(false);
        }

        console.error(' 🚨 Product Details:', {
          id: product.id,
          title: product.title,
          description: product.description,
          price: product.price,
          currency: product.currency
        });

        // Use store.when instead of store.on
        store.when('subscription').approved((purchase: any) => {
          console.error(' 🚨 PURCHASE APPROVED HANDLER TRIGGERED');
          console.error(' 🚨 Purchase Details:', purchase);
          
          if (purchase.id === productId) {
            console.error(' 🚨 PURCHASE SUCCESSFUL');
            resolve(true);
          }
        });

        store.when('subscription').cancelled((purchase: any) => {
          console.error(' 🚨 PURCHASE CANCELLED');
          resolve(false);
        });

        store.when('subscription').error((error: any) => {
          console.error(' 🚨 PURCHASE ERROR:');
          console.error(' 🚨 Error Details:', error);
          resolve(false);
        });

        // Attempt the purchase
        console.error(' 🚨 CALLING store.order()');
        store.order(productId);

      } catch (error) {
        console.error(' 🚨 CRITICAL IAP ERROR:');
        console.error(' 🚨 Error Name:', error.name);
        console.error(' 🚨 Error Message:', error.message);
        console.error(' 🚨 Error Stack:', error.stack);
        
        resolve(false);
      }
    });
  }

  async restorePurchases(): Promise<boolean> {
    if (!this.platform.is('ios')) {
      console.log('Not iOS platform, skipping restore');
      return false;
    }

    try {
      console.log('Restoring purchases...');
      await store.refresh();
      return true;
    } catch (error) {
      console.error('Error restoring purchases:', error);
      return false;
    }
  }

  private async verifySubscription(subscription: any): Promise<void> {
    const data = {
      receipt: subscription.transaction.appStoreReceipt,
      productId: subscription.id,
      transactionId: subscription.transaction.id,
      subscription: {
        price: subscription.price,
        name: subscription.alias,
        serviceFee: 0,
        processingFee: 0,
        paymentMethod: 'iap',
        subscriptionType: subscription.id.includes('monthly') ? 'monthly' : 'yearly',
        originalTransactionId: subscription.transaction.originalTransactionIdentifier || subscription.transaction.id
      }
    };

    try {
      const result = await this.afFun.httpsCallable('processIAPPurchase')(data).toPromise();
      console.log('Subscription verified:', result);
    } catch (error) {
      console.error('Subscription verification failed:', error);
      throw error;
    }
  }
}
