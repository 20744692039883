import { Component, OnInit,  } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../tdct-user.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import * as moment from 'moment';
import { Ask } from '../../tdct-ask/tdct-ask.model';
import { Promoter } from '../tdct-user-promoter/tdct-user-promoter.model';
import { TdctUserPromoterService } from '../tdct-user-promoter/tdct-user-promoter.service';
import { ImpCityService } from '../../imp-city/imp-city.service';
import { DefaultCity } from '../../imp-city/imp-city.mocks';
import { City } from '../../imp-city/imp-city.model';
import { DefaultPromoter } from '../tdct-user-promoter/tdct-user-promoter.mocks';


@Component({
  selector: 'app-tdct-user-promoter-creator-form',
  templateUrl: './tdct-user-promoter-creator-form.component.html',
  styleUrls: ['./tdct-user-promoter-creator-form.component.scss'],
})
export class TdctUserPromoterCreatorFormComponent implements OnInit {

  promoterCreatorForm: Promoter;
  submitted: boolean = false;
  citySelected: boolean = false;
  selectedCity: string = '';
  otherCity: string = '';

  constructor(
    public auth: TdctAuthService,
    public user: TdctUserService,
    public admin: TdctAdminService,
    public promoter: TdctUserPromoterService,
    public city: ImpCityService
  ) { }

  ngOnInit() {
    this.promoter.$ = DefaultPromoter;
    this.loadCitiesAvailable();
  }

  ngOnDestroy(): void {
    this.city.s$.pop();
  }

  async loadCitiesAvailable() {
    await this.city.load();
    // const OtherCity: City = DefaultCity;
    // OtherCity.id = 'otherCity';
    // OtherCity.name = 'Other City';
    // this.city.s$.push(OtherCity);
  }

  citySelectionChanged(event) {
    this.selectedCity = event.detail.value;
    if (this.selectedCity !== 'otherCity') {
      this.promoter.$.cities = [this.selectedCity];
      this.citySelected = true;
    } else { this.citySelected = false; }
  }

  otherCityChanged(event) {
    if (event.detail.value !== '') {
      this.otherCity = event.detail.value;
      this.citySelected = true;
    } else {
      this.selectedCity = '';
      this.citySelected = false;
    }
  }

  async submit(userPromoterCreatorForm) {
    this.submitted = true;
    if (userPromoterCreatorForm.valid) {
      this.user.loading = true;
      try {
        await this.registerPromoter(userPromoterCreatorForm);
        this.auth.viewingUserPromoterCreatorForm = false;
        this.user.loading = false;
      } catch (error) {
        error = {
          code: 'Error',
          message: error.message
        };
        this.auth.presentErrorAlert(error.message);
        this.auth.viewingUserPromoterCreatorForm = false;
        this.user.loading = false;
      }
    }
  }

  async registerPromoter(userPromoterCreatorForm) {
    this.promoter.$ = await this.createPromoter(userPromoterCreatorForm);
    const registerPromoterPromises = [];
    registerPromoterPromises.push(this.updatePromoterRequestCount());
    // TODO:  this is highly suspect and makes no sense.
    // console.log('[tdct-user-promoter-creator-form.component].registerPromoter', this.user.$.pendingPromoterApproval);
    // console.log('this.user.$.promoterProfileCount', this.user.$.promoterProfileCount);
    if (this.user.$.promoterProfileCount === 0) {
      // console.log('setting this.user.$.pendingPromoterApproval = true');
      this.user.$.pendingPromoterApproval = true;
    } else {
      // console.log('calling registerPromoterPromises.push(this.setUserPromoterPendingApproval())');
      registerPromoterPromises.push(this.setUserPromoterPendingApproval());
    }
    registerPromoterPromises.push(this.requestPromoterApproval());
    if (this.otherCity) { registerPromoterPromises.push(this.requestNewCity()); }
    registerPromoterPromises.push(this.promoter.load());
    // console.log('this.user.$.promoterProfileCount', this.user.$.promoterProfileCount);
    if (this.user.$.promoterProfileCount === 0) { this.user.$.pendingPromoterApproval = true; }
    // console.log('this.user.$.pendingPromoterApproval', this.user.$.pendingPromoterApproval);
    await Promise.all(registerPromoterPromises);
  }

  async updatePromoterRequestCount() {
    return await this.user.afs.doc(`users/${this.user.$.id}`).update({promoterRequestCount: ++this.user.$.promoterRequestCount});
  }

  async setUserPromoterPendingApproval() {
    return await this.user.afs.doc(`users/${this.user.$.id}`).update({ pendingPromoterApproval: true });
  }

  async createPromoter(userPromoterCreatorForm: Promoter): Promise<Promoter> {
    const userPromoterId: string = this.user.afs.createId();
    const userPromoterPath = `users/${this.user.$.id}/promoters/${userPromoterId}`;
    const userPromoter: Promoter = {
      id: userPromoterId,
      name: userPromoterCreatorForm.name,
      email: userPromoterCreatorForm.email,
      facebook: '',
      snapchat: '',
      instagram: '',
      bio: userPromoterCreatorForm.bio,
      photo: 'assets/img/default-profile.png',
      link: `https://imp-events.com/promoter/${userPromoterId}`,
      route: `promoter/${userPromoterId}`,
      userId: this.user.$.id,
      userName: this.user.$.name,
      userEmail: this.user.$.email,
      userPhoto: this.user.$.photo,
      displayTimestamp: moment().format('YYYY-MM-DD'),
      displayLastUpdateTimestamp: moment().format('YYYY-MM-DD'),
      unixLastUpdateTimestamp: moment().unix(),
      unixTimestamp: moment().unix(),
      paidMaleWristbandsScanned: 0,
      paidFemaleWristbandsScanned: 0,
      totalWristbandsScanned: 0,
      ticketDoorRevenue: 0,
      ticketOrderRevenue: 0,
      tableOrderRevenue: 0,
      bottleOrderRevenue: 0,
      totalOrderRevenue: 0,
      totalRevenue: 0,
      eventCount: 0,
      followerCount: 0,
      followers: [],
      tags: [],
      cities: [this.selectedCity || ''],
      approved: false
    };
    await this.user.afs.doc(userPromoterPath).set(userPromoter);
    return userPromoter;
  }

  async requestPromoterApproval(): Promise<any[]> {
    const requestPromoterApprovalPromises = [];
    requestPromoterApprovalPromises.push(this.updateUserPromoterApprovalFlag());
    requestPromoterApprovalPromises.push(this.submitPromoterApprovalAsk());
    return await Promise.all(requestPromoterApprovalPromises);
  }

  async updateUserPromoterApprovalFlag(): Promise<void> {
    const userPath = `users/${this.user.$.id}`;
    return await this.user.afs.doc(userPath).update({ pendingPromoterApproval: true });
  }

  async submitPromoterApprovalAsk(): Promise<void> {
    const promoterApprovalAsk: Ask = {
      id: this.user.afs.createId(),
      name: this.promoter.$.name + ' Approval Request',
      description: this.user.$.name + ' requested approval to promote events as ' + this.promoter.$.name,
      receiverEmail: 'impresspro@yahoo.com',
      userId: this.user.$.id,
      userName: this.user.$.name,
      userEmail: this.user.$.email,
      userPhoto: this.user.$.photo,
      promoterId: this.promoter.$.id,
      cityId: this.selectedCity || '',
      displayTimestamp: moment().format('YYYY-MM-DD'),
      displayDueTimestamp: moment().format('YYYY-MM-DD'),
      displayLastUpdateTimestamp: moment().format('YYYY-MM-DD'),
      unixLastUpdateTimestamp: moment().unix(),
      unixDueTimestamp: moment().unix(),
      unixTimestamp: moment().unix(),
      tags: ['promoter', 'approval'],
      complete: false
    };
    return await this.user.afs.doc(`asks/${promoterApprovalAsk.id}`).set(promoterApprovalAsk);
  }

  async requestNewCity() {
    const cityApprovalAsk: Ask = {
      id: this.user.afs.createId(),
      name: `${this.otherCity} City Request`,
      description: `${this.promoter.$.name} Promoter's ${this.otherCity} City Creation Request`,
      receiverEmail: 'impresspro@yahoo.com',
      userId: this.user.$.id,
      userName: this.user.$.name,
      userEmail: this.user.$.email,
      userPhoto: this.user.$.photo,
      displayTimestamp: moment().format('YYYY-MM-DD'),
      displayDueTimestamp: moment().format('YYYY-MM-DD'),
      displayLastUpdateTimestamp: moment().format('YYYY-MM-DD'),
      unixLastUpdateTimestamp: moment().unix(),
      unixDueTimestamp: moment().unix(),
      unixTimestamp: moment().unix(),
      tags: ['promoter', 'city', 'approval'],
      complete: false
    };
    return await this.user.afs.doc(`asks/${cityApprovalAsk.id}`).set(cityApprovalAsk);
  }

}
