
import { Post } from './tdct-post.model';
export const EmailPost: Post = {
    id: '1',
    name: 'Email Post',
    description: 'This is a email post',
    link: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['email']
};
export const GSuitePost: Post = {
    id: '2',
    name: 'GSuite Post',
    description: 'This is a gsuite post',
    link: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['gsuite']
};
export const CodePost: Post = {
    id: '3',
    name: 'Code Post',
    description: 'This is a code post',
    link: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['code']
};
export const EvernotePost: Post = {
    id: '4',
    name: 'Evernote Post',
    description: 'This is an evernote post',
    link: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['evernote'],
};
export const MeetupPost: Post = {
    id: '5',
    name: 'Meetup Post',
    description: 'This is a meetup post',
    link: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['meetup'],
};
export const InstagramPost: Post = {
    id: '6',
    name: 'Instagram Post',
    description: 'This is a instagram post',
    link: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['instagram'],
};
export const TwitterPost: Post = {
    id: '7',
    name: 'Twitter Post',
    description: 'This is a twitter post',
    link: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['twitter'],
};
export const SnapchatPost: Post = {
    id: '8',
    name: 'Snapchat Post',
    description: 'This is a snapchat post',
    link: '',
    userId: '2',
    userName: 'Jane',
    userEmail: 'test-jane@tdct.io',
    userPhoto: 'assets/img/default-profile.png',
    displayTimestamp: '2019-04-26',
    displayLastUpdateTimestamp: '2019-04-26',
    unixLastUpdateTimestamp: 1556325898,
    unixTimestamp: 1556325898,
    tags: ['snapchat'],
};
export const MockPosts: Post[] = [EmailPost, GSuitePost, CodePost, EvernotePost, MeetupPost, InstagramPost, TwitterPost, SnapchatPost];
export const DefaultPost: Post = {
    id: '',
    name: '',
    description: '',
    link: '',
    userId: '',
    userName: '',
    userEmail: '',
    userPhoto: '',
    displayTimestamp: '',
    displayLastUpdateTimestamp: '',
    unixLastUpdateTimestamp: 0,
    unixTimestamp: 0,
    tags: [],
};
