import { Admin } from './tdct-admin.model';
export const DefaultAdmin: Admin = {
    eventCount: 2,
    cityCount: 4,
    ticketCount: 3,
    tableCount: 8,
    bottleCount: 1,
    venueCount: 0,
    userCount: 0,
    deviceCount: 0,
    uploadCount: 0,
    postCount: 0,
    askCount: 0,
    taskCount: 0,
    pingCount: 0,
    messageCount: 0,
    controllingEvents: false,
    controllingCities: false,
    controllingTickets: false,
    controllingTables: false,
    controllingBottles: false,
    controllingVenues: false,
    controllingUsers: false,
    controllingDevices: false,
    controllingUploads: false,
    controllingPosts: false,
    controllingAsks: false,
    controllingTasks: false,
    controllingPings: false,
    controllingMessages: false
};
