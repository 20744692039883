import { Component, OnInit, OnDestroy } from '@angular/core';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { TdctUploadService } from '../tdct-upload.service';
import { TdctAdminService } from '../../tdct-admin/tdct-admin.service';
import { ImpCityService } from '../../imp-city/imp-city.service';
import { ImageCroppedEvent, base64ToFile } from 'ngx-image-cropper';
import { TdctUserPromoterService } from '../../tdct-user/tdct-user-promoter/tdct-user-promoter.service';
import { ImpEventService } from '../../imp-event/imp-event.service';
import { ImpOptionService } from '../../imp-option/imp-option.service';
import { ImpVenueService } from '../../imp-venue/imp-venue.service';
import * as moment from 'moment';
import imageCompression from 'browser-image-compression';

@Component({
    selector: 'app-tdct-upload-form',
    templateUrl: './tdct-upload-form.component.html',
    styleUrls: ['./tdct-upload-form.component.scss'],
})
export class TdctUploadFormComponent implements OnInit, OnDestroy {
    objectChangedEvent: any = '';
    error = { code: '', message: '' };
    private ext: string = ''; // Added as a local property
    public skipCropping: boolean = true; // Start with skip cropping enabled
    public uploadObjectUrl: string | null = null; // Added to store object URL for preview
    readonly MAX_FILE_SIZE: number = 2 * 1024 * 1024; // 2 MB

    constructor(
        public auth: TdctAuthService,
        public user: TdctUserService,
        public promoter: TdctUserPromoterService,
        public upload: TdctUploadService,
        public event: ImpEventService,
        public city: ImpCityService,
        public venue: ImpVenueService,
        public admin: TdctAdminService,
        public option: ImpOptionService
    ) { }

    ngOnInit() { }

    ngOnDestroy(): void {
        this.resetImageUploader();
    }

    getFileExtension(mimeType: string): string {
        const supportedTypes: { [key: string]: string } = {
            'image/png': 'png',
            'image/jpeg': 'jpg',
            'image/webp': 'webp',
        };
        return supportedTypes[mimeType] || '';
    }

    async gotImageFile(event: any) {
        const file = (document.getElementById('files') as HTMLInputElement).files?.[0];
        if (!file) { return; }

        console.log(`Original file size: ${file.size / (1024 * 1024)} MB`);

        try {
            let compressedFile = await this.compressImage(file);
            console.log(`Compressed file size: ${compressedFile.size / (1024 * 1024)} MB`);

            // Compress further if necessary
            while (compressedFile.size > this.MAX_FILE_SIZE) {
                console.log(`Further compressing...`);
                compressedFile = await this.compressImage(compressedFile);
            }

            // Update the upload object
            this.upload.object = compressedFile;
            this.ext = this.getFileExtension(compressedFile.type);

            // Generate preview URL for the compressed file
            if (this.uploadObjectUrl) {
                URL.revokeObjectURL(this.uploadObjectUrl); // Revoke old URL to prevent memory leaks
            }
            this.uploadObjectUrl = URL.createObjectURL(compressedFile);

            if (this.skipCropping) {
                await this.uploadImage();
            } else {
                this.upload.cropping = true;
                this.objectChangedEvent = event;
            }
        } catch (error) {
            console.error('Image processing failed:', error);
            this.throwUnsupportedFileTypeError();
        }
    }

    async compressImage(file: File): Promise<File> {
        const options = {
            maxSizeMB: 5, // Max file size in MB (adjust this if you want to set different limits)
            maxWidthOrHeight: 800, // Max width or height in pixels
            useWebWorker: true, // Use a web worker for performance
        };
        try {
            return await imageCompression(file, options);
        } catch (error) {
            throw new Error('Image compression failed');
        }
    }

    throwUnsupportedFileTypeError() {
        this.error = {
            code: 'Unsupported Image Type',
            message: 'Please upload png, jpg, or webp image',
        };
        this.displayError();
    }

    loadImageFailed() {
        this.error = {
            code: 'Image Load Error',
            message: 'Image Upload Failed',
        };
        this.displayError();
    }

    resetImageUploader(): void {
        if (this.uploadObjectUrl) {
            URL.revokeObjectURL(this.uploadObjectUrl); // Revoke URL to prevent memory leaks
            this.uploadObjectUrl = null;
        }

        this.upload.sanitize();
        this.resetUploadFlags();

        this.upload.loading = false;
        this.upload.creating = false;
        this.upload.cropping = false;
        this.upload.object = null;
    }

    displayError(): void {
        if (this.error.code && this.error.message) {
            this.auth.presentErrorAlert(this.error);
            this.resetImageUploader();
        }
    }

    async objectCropped(event: ImageCroppedEvent) {
        this.upload.object = base64ToFile(event.base64);
    }

    async uploadImage() {
        try {
            this.upload.loading = true;

            const uploadTasks = [
                this.auth.uploadingEventImage && this.uploadToService(this.event),
                this.auth.uploadingCityImage && this.uploadToService(this.city),
                this.auth.uploadingVenueImage && this.uploadToService(this.venue),
                this.auth.uploadingUserImage && this.uploadToService(this.user),
                this.auth.uploadingTableOptionImage && this.uploadToService(this.option),
                this.auth.uploadingBottleOptionImage && this.uploadToService(this.option),
                this.auth.uploadingUserPromoterImage && this.uploadToService(this.promoter),
            ].filter(Boolean);

            await Promise.all(uploadTasks);
            await this.createImageUpload();

            console.log('All uploads successful!');
        } catch (error) {
            console.error('Upload failed:', error);
            this.error = { code: 'Upload Failed', message: 'An error occurred during the upload.' };
            this.displayError();
        } finally {
            this.upload.loading = false;
            this.upload.creating = false;
            this.auth.imageUploaded = true;
            this.resetUploadFlags();
        }
    }

    async uploadToService(service: any) {
        try {
            const imageUrl = await this.upload.imageToStorage();
            if (imageUrl) {
                service.$.image = imageUrl; // Assign the link to the respective service image

                if (service === this.user) {
                    this.user.$.photo = imageUrl;
                    this.auth.user.$.photo = imageUrl;

                    await this.user.updateUserPhoto(this.user.$.id, imageUrl);

                }

                console.log('Image uploaded successfully. Image URL:', imageUrl);
            } else {
                console.warn('Image uploaded successfully, but no URL was returned.');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    }

    resetUploadFlags() {
        this.auth.uploadingEventImage = false;
        this.auth.uploadingCityImage = false;
        this.auth.uploadingVenueImage = false;
        this.auth.uploadingTableOptionImage = false;
        this.auth.uploadingBottleOptionImage = false;
        this.auth.uploadingUserImage = false;
        this.auth.uploadingUserPromoterImage = false;
    }

    async createImageUpload() {
        this.upload.$ = {
            id: '', // Default values or unique identifier
            name: '',
            description: '',
            link: '',
            path: `uploads/images/${this.user.$.id}/png_${moment().unix()}${this.ext}`, // Use local ext property
            userId: this.user.$.id,
            userEmail: this.user.$.email,
            userName: this.user.$.name,
            userPhoto: this.user.$.photo,
            displayTimestamp: moment().format('MMMM Do YYYY, h:mm:ss a'), // Example format
            displayLastUpdateTimestamp: moment().format('MMMM Do YYYY, h:mm:ss a'), // Example format
            unixTimestamp: moment().unix(), // Unix timestamp for current time
            unixLastUpdateTimestamp: moment().unix(), // Unix timestamp for last update
            tags: [], // Initialize with an empty array or any relevant tags
        };
        this.stampImageUpload();
        return await this.upload.create();
    }

    stampImageUpload() {
        const imageNameMap = {
            uploadingEventImage: 'Event Image',
            uploadingCityImage: 'City Image',
            uploadingVenueImage: 'Venue Image',
            uploadingTableOptionImage: 'Table Option Image',
            uploadingBottleOptionImage: 'Bottle Option Image',
            uploadingUserImage: 'User Photo Image',
            uploadingUserPromoterImage: 'User Promoter Photo Image',
        };

        for (const [flag, name] of Object.entries(imageNameMap)) {
            if (this.auth[flag]) {
                const service = this.getService(flag);
                if (service && service.$ && service.$.image) {
                    this.upload.$.name = name;
                    this.upload.$.description = `${this.user.$.name}'s ${name} Upload`;
                    this.upload.$.link = service.$.image;
                    console.log(`Stamped image upload for: ${name} with link: ${service.$.image}`);
                } else {
                    console.warn(`No link available for ${name}. Ensure the upload completed successfully.`);
                }
            }
        }
    }

    getService(flag: string) {
        const serviceMap = {
            uploadingEventImage: this.event,
            uploadingCityImage: this.city,
            uploadingVenueImage: this.venue,
            uploadingUserImage: this.user,
            uploadingTableOptionImage: this.option,
            uploadingBottleOptionImage: this.option,
            uploadingUserPromoterImage: this.promoter,
        };
        return serviceMap[flag];
    }

    shouldCrop(): boolean {
        return !this.skipCropping; // Return false if skipCropping is true
    }

    toggleCropping() {
        this.upload.cropping = !this.skipCropping;
    }

    async objectLoaded($event: any) {
        // Handle object load event
    }
}
