import { Component, OnInit,  } from '@angular/core';
import { TdctAdminService } from '../tdct-admin.service';
import { ImpEventService } from '../../imp-event/imp-event.service';
import { ImpCityService } from '../../imp-city/imp-city.service';
import { TdctAuthService } from '../../tdct-auth/tdct-auth.service';
import { TdctUserService } from '../../tdct-user/tdct-user.service';
import { ImpTicketService } from '../../imp-ticket/imp-ticket.service';
import { ImpTableService } from '../../imp-table/imp-table.service';
import { ImpBottleService } from '../../imp-bottle/imp-bottle.service';
import { ImpVenueService } from '../../imp-venue/imp-venue.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';


@Component({
    selector: 'app-tdct-admin-brand-engine',
    templateUrl: './tdct-admin-brand-engine.component.html',
    styleUrls: ['./tdct-admin-brand-engine.component.scss'],
})
export class TdctAdminBrandEngineComponent implements OnInit {
    public userId: string;

    constructor(
        public event: ImpEventService,
        public city: ImpCityService,
        public ticket: ImpTicketService,
        public table: ImpTableService,
        public bottle: ImpBottleService,
        public venue: ImpVenueService,
        public auth: TdctAuthService,
        public user: TdctUserService,
        public admin: TdctAdminService,
        public router: Router,
        public afa: AngularFireAuth,
    ) { }

    ngOnInit() {
        this.afa.authState.subscribe((user) => {
            if (user) {
                this.userId = user.uid;
              }
          });
    }

    toggleEvents(): void {
        this.admin.$.controllingEvents = !this.admin.$.controllingEvents;
        if (this.admin.$.controllingEvents) {
            this.auth.viewingController = true;
            this.event.load(this.userId);
        }
        else {
            this.auth.viewingController = false;
            this.event.sanitize();
        }
    }

    toggleCities(): void {
        this.admin.$.controllingCities = !this.admin.$.controllingCities;
        if (this.admin.$.controllingCities) {
            this.auth.viewingController = true;
            this.city.load();
        } else {
            this.auth.viewingController = false;
            this.city.sanitize();
        }
    }

    toggleTickets(): void {
        this.admin.$.controllingTickets = !this.admin.$.controllingTickets;
        if (this.admin.$.controllingTickets) {
            this.auth.viewingController = true;
            this.ticket.load();
        } else {
            this.auth.viewingController = false;
        }
    }

    toggleTables(): void {
        this.admin.$.controllingTables = !this.admin.$.controllingTables;
        if (this.admin.$.controllingTables) {
            this.auth.viewingController = true;
            this.table.load();
        } else {
            this.auth.viewingController = false;
        }
    }

    toggleBottles(): void {
        this.admin.$.controllingBottles = !this.admin.$.controllingBottles;
        if (this.admin.$.controllingBottles) {
            this.auth.viewingController = true;
            this.bottle.load();
        } else {
            this.auth.viewingController = false;
        }
    }

    toggleVenues(): void {
        this.admin.$.controllingVenues = !this.admin.$.controllingVenues;
        if (this.admin.$.controllingVenues) {
            this.auth.viewingController = true;
            this.venue.load();
        } else {
            this.auth.viewingController = false;
        }
    }

}
